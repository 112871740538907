import { Button, Checkbox, Col, Drawer, Form, Input, notification, Popconfirm, Row, Space, Spin, Table, Typography } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import ContentLayout from '../ContentLayout'

function DateTypePage() {
    const [storeId, setStoreId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModalMode, setEditModalMode] = useState('store');
    const [editModalId, setEditModalId] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);
    const editForm = useRef();

    const refresh = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.dateType.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            setDetail(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [storeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleShowAddModal = () => {
        setEditModalMode('store');
        setEditModalId(null);
        setEditModalVisible(true);
    }
    const handleShowUpdateModal = (id) => {
        setEditModalMode('update');
        setEditModalVisible(true);
        setEditModalLoading(true);
        setEditModalId(id);
        TourniiAPI.store.stores.dateType.show(storeId, id).then((response) => {
            const result = response.data;
            if (editForm) {
                editForm.current.setFieldsValue(result);
            }
        }).finally(() => {
            setEditModalLoading(false);
        });
    }
    const handleHideModal = () => {
        setEditModalVisible(false);
        setEditModalMode('store');
        setEditModalId(null);
    };
    const handleFinish = (values) => {
        console.log(values);
        if (editModalMode === 'store') {
            setEditModalLoading(true);
            TourniiAPI.store.stores.dateType.store(storeId, values).then((response) => {
                notification.info({ message: '新增成功' });
            }).finally(() => {
                setEditModalLoading(false);
                handleHideModal();
                refresh();
            });
        } else {
            setEditModalLoading(true);
            TourniiAPI.store.stores.dateType.update(storeId, editModalId, values).then((response) => {
                notification.info({ message: '修改成功' });
            }).finally(() => {
                setEditModalLoading(false);
                handleHideModal();
                refresh();
            });
        }
    };
    const handleDelete = (id) => {
        TourniiAPI.store.stores.dateType.destroy(storeId, id).then((response) => {
            notification.info({ message: '刪除成功' });
        }).finally(() => {
            refresh();
        });
    }
    const columns = [
        {
            title: '日期類型',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '預設?',
            dataIndex: 'default',
            key: 'default',
            render: (value) => value ? '是' : ''
        },
        {
            title: '動作',
            // fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowUpdateModal(record.id)}>修改</Typography.Link>
                <Popconfirm title="您確定要刪除此類型嗎?" onConfirm={() => handleDelete(record.id)}>
                    <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
            </Space>
        }
    ]
    return (
        <ContentLayout title="日期管理">
            <Spin spinning={loading}>
                <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                <Form style={{ marginBottom: 20 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto"></Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={handleShowAddModal}>新增</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table columns={columns} dataSource={detail} rowKey="id" scroll={{ x: 'max-content' }} />
                <Drawer title={`${editModalMode === 'store' ? '新增' : '修改'}日期類型`} open={editModalVisible} onClose={handleHideModal}>
                    <Spin spinning={editModalLoading}>
                        <Form labelCol={{ span: 6 }} ref={editForm} onFinish={handleFinish} initialValues={{ default: false }}>
                            <Form.Item label="名稱" name="name">
                                <Input />
                            </Form.Item>
                            <Form.Item label="預設?" name="default" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                <Button type="primary" htmlType="submit">送出</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default DateTypePage