import { Select } from 'antd';
import moment from 'moment';
import React from 'react'

function TimeSelect({
    format,
    value: inputValue,
    minuteStep,
    disabledDate,
    disabledTime,
    onChange,
    ...props
}) {
    const startTime = moment().set('hour', 0).set('minute', 0).set('second', 0);
    const endTime = startTime.clone().add(1, 'day');
    const formatValue = inputValue?.format(format);
    const options = [];

    // console.log("disabledTime", disabledTime());
    while (endTime.diff(startTime, 'second') > 0) {
        const optionValue = startTime.format(format);
        let disabled = false;
        if (disabledTime) {
            const inputHour = Number.parseInt(inputValue?.format("HH"));
            const inputMinute = Number.parseInt(inputValue?.format("mm"));
            if (disabledTime().disabledHours) {
                const optionHour = Number.parseInt(startTime.format("HH"));
                // console.log("disabledHours", disabledTime().disabledHours(), optionHour);
                disabled = disabled || disabledTime().disabledHours().includes(optionHour);
            }
            if (disabledTime().disabledMinutes) {

                const optionMinute = Number.parseInt(startTime.format("mm"));
                // console.log("disabledMinutes", disabledTime().disabledMinutes(inputHour), optionMinute);
                disabled = disabled || disabledTime().disabledMinutes(inputHour).includes(optionMinute);
            }
            if (disabledTime().disabledSeconds) {
                const optionSecond = Number.parseInt(startTime.format("ss"));
                // console.log("disabledSeconds", disabledTime().disabledSeconds(inputHour, inputMinute), optionSecond);
                disabled = disabled || disabledTime().disabledSeconds(inputHour, inputMinute).includes(optionSecond);
            }
        }
        if (!disabled) {
            options.push(<Select.Option key={optionValue} value={optionValue} disabled={disabled}>{optionValue}</Select.Option>)
        }
        startTime.add(minuteStep, 'minute');
    }
    const handleChange = (value) => {
        const mmtTime = moment(value, format);
        const newDateTime = (inputValue ?? moment()).clone().set("hour", mmtTime.hour()).set("minute", mmtTime.minute()).set("second", mmtTime.second());
        // console.log("handleChange", value, newDateTime.format("lll"));
        if (onChange) {
            onChange(newDateTime);
        }
    }
    // console.log("formatValue", formatValue);
    return <Select
        style={{ minWidth: 100 }}
        showSearch={true}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={formatValue}
        onChange={handleChange}
        placeholder="請選擇時間"
        {...props}>{options}
    </Select>;
}

export default TimeSelect