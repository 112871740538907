import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../../api/TourniiAPI';

function DateTypeSelect({ storeId, onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [dateTypes, setDateTypes] = useState([]);
    const refresh = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.dateType.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            setDateTypes(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [storeId]);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select {...props} loading={loading} allowClear={true} onSelect={onSelect} onClear={onSelect}>
            {dateTypes.map((dateType) => <Select.Option key={dateType.id} value={dateType.id}>{dateType.name}</Select.Option>)}
        </Select>
    )
}

export default DateTypeSelect