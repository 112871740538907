import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function VehicleTypeSelect({ vehicleBrandId, onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const refresh = useCallback(() => {
        if (!vehicleBrandId) return;
        setLoading(true);
        TourniiAPI.vehicleBrands.types.index(vehicleBrandId, {}, 1, 999).then((response) => {
            const result = response.data;
            setVehicleTypes(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [vehicleBrandId]);

    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select {...props} loading={loading} allowClear={true} onSelect={onSelect} onClear={onSelect}>
            {vehicleTypes.map((vehicleType) => <Select.Option key={vehicleType.id} value={vehicleType.id}>{vehicleType.name}</Select.Option>)}
        </Select>
    )
}

export default VehicleTypeSelect