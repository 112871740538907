import { Button, Drawer, Form, notification, Spin } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../../api/TourniiAPI';

const TPDirect = window.TPDirect;

function CreditCardIcon({ type }) {
    let url = null;
    switch (type) {
        default:
        case 'unknown':
            url = 'https://js.tappaysdk.com/tpdirect/image/card.svg';
            break;
        case 'visa':
            url = 'https://js.tappaysdk.com/tpdirect/image/visa.svg';
            break;
        case 'mastercard':
            url = 'https://js.tappaysdk.com/tpdirect/image/mastercard.svg';
            break;
        case 'jcb':
            url = 'https://js.tappaysdk.com/tpdirect/image/jcb.svg';
            break;
        case 'amex':
            url = 'https://js.tappaysdk.com/tpdirect/image/amex.svg';
            break;
        case 'unionpay':
            url = 'https://www.tappaysdk.com/_nuxt/img/union_pay.8271208.png';
            break;

    }
    return <img style={{ height: 20, margin: '1px 10px -3px 0' }} alt={type} src={url} />
}
function PaymentDrawer({ orderId, renterId, open, onClose, onOk }) {
    const refTapPayCardNumber = useRef();
    const refTapPayCCV = useRef();
    const refTapPayExpirationDate = useRef();
    const [paymentDrawerLoading, setPaymentDrawerLoading] = useState(false);
    const [disablePayButton, setDisablePayButton] = useState(true);
    const [cardType, setCardType] = useState();

    const initTapPay = useCallback(() => {
        console.log(refTapPayExpirationDate, refTapPayCCV, refTapPayCardNumber);
        if (!open || !refTapPayCardNumber.current || !refTapPayExpirationDate.current || !refTapPayCCV.current) {
            return;
        }
        if (refTapPayCardNumber.current.innerHTML) { // Ignore initialize if already initialized
            return;
        }
        console.log("TPDirect.card.setup");
        setTimeout(() => {
            TPDirect.card.setup({
                fields: {
                    number: {
                        element: refTapPayCardNumber.current,
                        placeholder: '**** **** **** ****'
                    },
                    expirationDate: {
                        element: refTapPayExpirationDate.current,
                        placeholder: 'MM / YY'
                    },
                    ccv: {
                        element: refTapPayCCV.current,
                        placeholder: '後三碼'
                    }
                },
                styles: {
                    'input': {
                        'color': '#808080'
                    },
                    'input.ccv': {
                        'font-size': '16px'
                    },
                    ':focus': {
                        'color': 'black'
                    },
                    '.valid': {
                        'color': 'green'
                    },
                    '.invalid': {
                        'color': 'red'
                    }
                },
                // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
                isMaskCreditCardNumber: true,
                maskCreditCardNumberRange: {
                    beginIndex: 6,
                    endIndex: 11
                }
            })
        }, 0);
        TPDirect.card.onUpdate((update) => {
            console.log("TPDirect.card.onUpdate");
            //console.log(update);
            setDisablePayButton(!update.canGetPrime)

            // cardTypes = ['visa', 'mastercard', ...]
            var newType = update.cardType === 'unknown' ? '' : update.cardType
            setCardType(newType);

            if (update.status.number === 2) {
                console.log("Card Number is Error");
            } else if (update.status.number === 0) {
                console.log("Card Number is Success");
            } else {
                console.log("Card Number is Normal");
            }

            if (update.status.expiry === 2) {
                console.log("Expiration Date is Error");
            } else if (update.status.expiry === 0) {
                console.log("Expiration Date is Success");
            } else {
                console.log("Expiration Date is Normal");
            }

            if (update.status.ccv === 2) {
                console.log("CCV is Error");
            } else if (update.status.ccv === 0) {
                console.log("CCV is Success");
            } else {
                console.log("CCV is Normal");
            }
        });
    }, [refTapPayCardNumber, refTapPayExpirationDate, refTapPayCCV, open]);

    useEffect(() => {
        initTapPay();
    }, [initTapPay]);


    const resetForm = () => {
        if (refTapPayCardNumber.current && refTapPayExpirationDate.current && refTapPayCCV.current) {
            refTapPayCardNumber.current.innerHTML = '';
            refTapPayExpirationDate.current.innerHTML = '';
            refTapPayCCV.current.innerHTML = '';
        }
    }

    useEffect(() => {
        resetForm();
    }, [open]);

    const handleOk = () => {
        //resetForm();
        if (onOk) {
            onOk();
        }
    }
    const handleClose = () => {
        //resetForm();
        if (onClose) {
            onClose();
        }
    }
    const handleGetPrime = () => {
        // if (order.state !== ORDERSTATE_NEW) {
        //     notification.warn({ message: '只有新預訂能進行付款' });
        //     return;
        // }
        TPDirect.card.getPrime(function (result) {
            if (result.status !== 0) {
                notification.error({ message: '付款錯誤: ' + result.msg });
                handleClose();
                return;
            }
            // console.log('get prime 成功，prime: ' + result.card.prime, result);
            if (!renterId || !orderId || !result.card.prime) {
                notification.error({ message: '付款錯誤: 缺少必要資料' });
                return;
            }
            setPaymentDrawerLoading(true);
            TourniiAPI.renter.renters.orders.pay.tapPay(renterId, orderId, {
                prime: result.card.prime
            }).then((response) => {
                // const result = response.data;
                // console.log(result);
                notification.info({ message: '完成付款' });
                handleClose();
                handleOk();
            }).finally(() => {
                setPaymentDrawerLoading(false);
            });
        });
    }
    return (
        <Drawer title="付款" open={open} onClose={handleClose}>
            <Spin spinning={paymentDrawerLoading}>
                <Form>
                    <Form.Item label="卡號">
                        <div className="form-control card-number">
                            <div style={{ display: 'flex' }}>
                                <CreditCardIcon type={cardType} />
                                <div style={{ height: 20 }} ref={refTapPayCardNumber}></div>
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item label="卡片到期日" validateStatus="warning">
                        <div className="form-control expiration-date">
                            <div style={{ height: 20 }} ref={refTapPayExpirationDate}></div>
                        </div>
                    </Form.Item>
                    <Form.Item label="卡片後三碼">
                        <div className="form-control ccv">
                            <div style={{ height: 20 }} ref={refTapPayCCV}></div>
                        </div>
                    </Form.Item>
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <Button disabled={disablePayButton} onClick={handleGetPrime}>付款</Button>
                </div>
            </Spin>
        </Drawer>
    )
}

export default PaymentDrawer