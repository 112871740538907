import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import TourniiAPI from '../../../api/TourniiAPI';
import OrderItemVehicleDrawer from '../../../components/OrderItemVehicleDrawer';

function StoreOrderItemVehicleDrawer({
    storeId,
    orderId,
    itemId,
    ...props
}, ref) {
    const drawerRef = useRef();
    useImperativeHandle(ref, () => ({
        refresh: drawerRef.current.refresh
    }));

    const handleItemVehicleShow = (editId) => TourniiAPI.store.stores.orders.items.vehicles.show(storeId, orderId, itemId, editId);
    const handleItemVehicleStore = (values) => TourniiAPI.store.stores.orders.items.vehicles.store(storeId, orderId, itemId, values);
    const handleItemVehicleUpdate = (editId, values) => TourniiAPI.store.stores.orders.items.vehicles.update(storeId, orderId, itemId, editId, values);
    const handleItemVehiclePickup = (editId) => TourniiAPI.store.stores.orders.items.vehicles.pickup(storeId, orderId, itemId, editId);
    const handleItemVehicleReturn = (editId) => TourniiAPI.store.stores.orders.items.vehicles.return(storeId, orderId, itemId, editId);
    const handleItemVehicleUndoReturn = (editId) => TourniiAPI.store.stores.orders.items.vehicles.undoReturn(storeId, orderId, itemId, editId);
    const handleItemVehiclePhotoShow = (vehicleId, type) => TourniiAPI.store.stores.orders.items.vehicles.photos.show(storeId, orderId, itemId, vehicleId, type);

    return (
        <OrderItemVehicleDrawer
            ref={drawerRef}
            onItemVehicleShow={handleItemVehicleShow}
            onItemVehicleStore={handleItemVehicleStore}
            onItemVehicleUpdate={handleItemVehicleUpdate}
            onItemVehiclePickup={handleItemVehiclePickup}
            onItemVehicleReturn={handleItemVehicleReturn}
            onItemVehicleUndoReturn={handleItemVehicleUndoReturn}
            onItemVehiclePhotoShow={handleItemVehiclePhotoShow}
            {...props}
        />
    )
}

export default forwardRef(StoreOrderItemVehicleDrawer)