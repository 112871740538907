import { Button, Col, Image, Row, Select, Space } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam';

function TakePhotoPanel({ title, onChange, visible, preview, showDeviceSelect, ...props }) {
    const [mode, setMode] = useState(null);
    const [deviceId, setDeviceId] = useState(null);
    const [devices, setDevices] = useState([]);
    const [base64ImageSrc, setBase64ImageSrc] = useState();
    const webcamRef = useRef(null);
    const uploadRef = useRef();

    const handleDevices = useCallback(
        mediaDevices =>
            setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    useEffect(() => {
        if (navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        }
    }, [handleDevices, visible]);

    useEffect(() => {
        if (preview) {
            setBase64ImageSrc(preview);
            setMode('preview');
        } else {
            setBase64ImageSrc();
            setMode(null);
        }
    }, [preview]);

    const triggerChange = useCallback((dataUrl) => {
        setBase64ImageSrc(dataUrl);
        setMode('preview');
        if (onChange) {
            onChange(dataUrl);
        }
    }, [onChange]);

    const handleShowCamera = () => {
        setMode('camera');
    }
    const handleSelectCamera = (value) => {
        setDeviceId(value);
    }
    const handleCaptureFromCamera = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        console.log('capture', imageSrc);
        triggerChange(imageSrc);

    }, [webcamRef, triggerChange]);

    const handleShowUpload = () => {
        uploadRef.current.click();
    }
    const handleUploadChange = async () => {
        if (!uploadRef.current.files.length === 0) return;
        const file = uploadRef.current.files[0];
        const dataUrl = await new Promise((resolve, reject) => {
            let reader;
            reader = new FileReader();
            reader.onload = (e) => {
                const { result } = e.target;
                resolve(result);
            }
            reader.readAsDataURL(file);
        });
        triggerChange(dataUrl);
    }
    const handleClear = () => {
        setBase64ImageSrc(null);
        setMode(null);
        if (onChange) {
            onChange(null);
        }

    }
    const getVideoConstraints = () => {
        if (deviceId) return { deviceId: deviceId };
        return { facingMode: { exact: "environment" } };
    }
    return (
        visible && <div>
            <Row gutter={[10, 10]} style={{ border: '1px solid lightgray', borderRadius: 10, padding: 10 }}>
                {
                    mode === null && <>
                        <Col span={24} style={{ display: 'flex', minHeight: 340, justifyContent: 'center' }}>
                            <Space>
                                <Button onClick={handleShowCamera} disabled={devices.length === 0}>拍照</Button>
                                <Button onClick={handleShowUpload}>上傳</Button>
                                <input ref={uploadRef} type="file" accept="image/*;capture=camera" onChange={handleUploadChange} style={{ display: 'none' }} />
                            </Space>
                        </Col>
                    </>
                }
                {
                    mode === 'camera' && <>
                        {showDeviceSelect && <Col span={24}>
                            <Space>
                                選擇相機
                                <Select style={{ width: 200 }} onChange={handleSelectCamera} allowClear>
                                    {devices.map((device, key) => (
                                        <Select.Option key={device.deviceId} value={device.deviceId}>
                                            {device.label || `Device ${key + 1}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </Col>}
                        <Col span={24}>
                            <Webcam
                                ref={webcamRef}
                                audio={false}
                                screenshotFormat="image/jpeg"
                                style={{ width: '100%' }}
                                forceScreenshotSourceSize={true}
                                videoConstraints={getVideoConstraints()}
                            />
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button onClick={() => setMode(null)}>返回</Button>
                                <Button onClick={handleCaptureFromCamera}>拍照</Button>
                            </Space>
                        </Col>
                    </>
                }
                {
                    mode === 'preview' && <>
                        <Col span={24} style={{ display: 'flex', minHeight: 340, justifyContent: 'center', alignItems: 'center' }}>
                            <Image
                                style={{ width: '100%' }}
                                src={base64ImageSrc}
                                alt="里程照片"
                            />
                            <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                                <Button onClick={handleClear}>重新上傳</Button>
                            </div>
                        </Col>
                    </>
                }
            </Row>
        </div>
    )
}

export default TakePhotoPanel