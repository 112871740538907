import { Alert, Button, Col, Descriptions, notification, Result, Row, Skeleton, Space, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TourniiAPI from '../../api/TourniiAPI';
import { useOrderCart } from '../../OrderCartContext';
import ContentLayout from '../ContentLayout';
import moment from 'moment';
import RwdInputNumber from '../../components/RwdInputNumber';
import { DATETYPE_LIMIT_AMOUNTS } from '../../utils/dateType';
import { weekNameToLocaleName } from '../../utils/openTime';

function AddToCartPage() {
  const [orderCart, cartActions] = useOrderCart();
  const params = useParams();
  const vehicleSpecInvId = params.vehicleSpecInvId;
  const [searchParams,] = useSearchParams();
  const starttime = searchParams.get("starttime");
  const endtime = searchParams.get("endtime");
  const paramQuantity = searchParams.get("qty");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [quantity, setQuantity] = useState(paramQuantity);
  const [error, setError] = useState();

  const vehicleName = detail ? `${detail.vehiclespec.vehicletype.brand.name} ${detail.vehiclespec.name}` : '';
  const refresh = useCallback(() => {
    setLoading(true);
    TourniiAPI.public.listingVehicles.show(vehicleSpecInvId, {
      expectpickuptime: starttime,
      expectreturntime: endtime,
    }).then((response) => {
      const result = response.data;
      setDetail(result);
    }).catch((error) => {
      console.log(error);
      setError(error?.response?.data?.message);
    }).finally(() => {
      setLoading(false);
    });
  }, [vehicleSpecInvId, starttime, endtime]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleAddToCart = () => {
    if (orderCart.storeId && detail.store_id !== orderCart.storeId) {
      notification.warn({ message: '購物車的車輛必須同一店家' });
      return;
    }
    cartActions.setStore(detail.store_id);
    cartActions.setPeriod([detail.expectpickuptime, detail.expectreturntime]);
    cartActions.addItem(detail, quantity);
    navigate("/cart");
  }
  return (
    <ContentLayout title={vehicleName}>
      {error && <Result status="error" title={error} extra={
        <Button type="primary" key="console" onClick={() => window.history.back(-1)}>
          返回
        </Button>
      } />}
      {!error && (!detail ? <Skeleton /> :
        <Spin spinning={loading}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={24} lg={12} style={{ textAlign: 'center' }}>
              <img
                style={{ maxWidth: '100%' }}
                alt={detail.vehiclespec.name}
                src={TourniiAPI.getImageHostUrl(detail.vehiclespec.photos[0].thumbnailfileurl)}
              />
            </Col>
            <Col xs={24} md={24} lg={12}>
              <h2>{vehicleName}</h2>
              <h3>最低租賃時數</h3>
              <ul>
                <li>{detail.store.rentminhours ? `${detail.store.rentminhours} 時` : '無'}</li>
              </ul>
              <h3>承租時間</h3>
              <ul>
                <li><span style={{ fontWeight: 'bold' }}>取車時間:</span> {detail.expectpickuptime}</li>
                <li><span style={{ fontWeight: 'bold' }}>還車時間:</span> {detail.expectreturntime}</li>
              </ul>
              <div>
                {detail.errors?.map((message, index) => <Alert key={`alert-${index}`} style={{ marginBottom: 10 }} message={Array.isArray(message) ? message[0] : message} type="error" />)}
              </div>
              <h3>價格</h3>
              <ul>
                {detail.datetypes.map((datetype) =>
                  <li key={datetype.id}><span style={{ fontWeight: 'bold' }}>{datetype.name}: </span><ul>
                    {
                      DATETYPE_LIMIT_AMOUNTS.map((priceCol) => {
                        return datetype[priceCol.col] ? <li key={priceCol.col}>
                          {datetype[priceCol.col]} 元 / {priceCol.unit}
                        </li> : null;
                      })
                    }
                    <li>超時費 {datetype.hourprice} 元 / 時</li>
                  </ul>
                  </li>
                )}
              </ul>
              <Row gutter={[10, 10]}>
                <Col xs={12} md={8}>
                  <Space>
                    <h3>小計</h3>
                    ${detail.price}
                  </Space>
                </Col>
                <Col xs={12} md={12}>
                  <Space>
                    <h3>數量</h3>
                    <RwdInputNumber style={{ width: 60 }} value={quantity} onChange={(value) => setQuantity(value)} />
                  </Space>
                </Col>
                <Col xs={24} md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button onClick={handleAddToCart} type="primary">預訂</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <h3>店家資訊</h3>
              <Descriptions column={1} labelStyle={{ width: 100, fontWeight: 500, fontSize: '1rem' }}>
                <Descriptions.Item label="車行">
                  {detail.store.company.name}
                </Descriptions.Item>
                <Descriptions.Item label="店名">
                  {detail.store.name}
                </Descriptions.Item>
                <Descriptions.Item label="營業時間">
                  <ul>
                    {detail.store.opentimes.map((opentime) =>
                      <li key={opentime.id}>{weekNameToLocaleName(opentime.dayofweek)} {moment(opentime.starttime, "HH:mm:ss").format("HH:mm")} ~ {moment(opentime.endtime, "HH:mm:ss").format("HH:mm")}</li>
                    )}
                  </ul>
                </Descriptions.Item>
                <Descriptions.Item label="地址">
                  <Space>
                    {detail.store.address}
                    <a href={`https://www.google.com/maps/search/?api=1&query=${detail.store.address}`}>
                      <Button type="primary">導航</Button>
                    </a>
                  </Space>
                </Descriptions.Item>
                <Descriptions.Item label="聯絡方式">
                  {detail.store.contacttel ? <Space>
                    <span>{detail.store.contact}</span>
                    <a href={"tel:" + detail.store.contacttel}>{detail.store.contacttel}</a>
                  </Space> : '無'}
                </Descriptions.Item>
                <Descriptions.Item label="租借政策">
                  <div dangerouslySetInnerHTML={{ __html: detail.store.rentpolicy }}></div>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Spin>
      )}
    </ContentLayout>
  )
}

export default AddToCartPage