import { Button, Form, Input, notification } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TourniiAPI from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout'

function ResetPasswordPage() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    console.log("token", token);
    const handleFinish = (values) => {
        if (values.password !== values.password_confirmation) {
            notification.warn({ message: '兩次輸入的密碼不同，請重新輸入' });
            return;
        }
        setLoading(true);
        TourniiAPI.auth.resetPassword({
            token: token,
            ...values,
        }).then((response) => {
            notification.info({ message: '密碼重設成功，請使用新的密碼登入' });
            navigate('/login');
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <ContentLayout>
            <h3>重設密碼</h3>
            <p>輸入您的新密碼來重設密碼</p>
            <Form onFinish={handleFinish} labelCol={{ span: 4 }}>
                <Form.Item name="email" label="電子信箱" required>
                    <Input />
                </Form.Item>
                <Form.Item name="password" label="密碼" required>
                    <Input.Password />
                </Form.Item>
                <Form.Item name="password_confirmation" label="確認密碼" required>
                    <Input.Password />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={loading}>送出</Button>
                </Form.Item>
            </Form>
        </ContentLayout>
    )
}

export default ResetPasswordPage