import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function AdminStoreSelect({ companyId, onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const refresh = useCallback(() => {
        setLoading(true);
        TourniiAPI.admin.stores.index({ company_id: companyId }, 1, 999).then((response) => {
            const result = response.data;
            const filteredItems = result.data.filter((store) => !companyId || store.company_id === companyId);
            setStores(filteredItems);
        }).finally(() => {
            setLoading(false);
        })
    }, [companyId]);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select {...props} loading={loading} allowClear={true} onSelect={onSelect} onClear={onSelect}>
            {stores.map((store) => <Select.Option key={store.id} value={store.id}>{store.name}</Select.Option>)}
        </Select>
    )
}

export default AdminStoreSelect