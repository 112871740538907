import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import TourniiAPI from '../../../api/TourniiAPI'
import OrderItemDrawer from '../../../components/OrderItemDrawer';

function StoreOrderItemDrawer({
    storeId,
    orderId,
    itemId,
    ...props
}, ref) {
    const drawerRef = useRef();
    useImperativeHandle(ref, () => ({
        refresh: drawerRef.current.refresh()
    }));
    const handleItemShow = () => TourniiAPI.store.stores.orders.items.show(storeId, orderId, itemId);
    const handleItemVehicleIndex = () => TourniiAPI.store.stores.orders.items.vehicles.index(storeId, orderId, itemId, {}, 1, 999);

    return (
        <OrderItemDrawer
            ref={drawerRef}
            onItemShow={handleItemShow}
            onItemVehicleIndex={handleItemVehicleIndex}
            {...props}
        />
    )
}

export default forwardRef(StoreOrderItemDrawer)