import { Button, Col, Drawer, Form, Input, notification, Popconfirm, Row, Space, Spin, Table, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout'

function CompaniesPage() {
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);

    const refresh = () => {
        setLoading(true);
        TourniiAPI.admin.companies.index({}, 1, 999).then((response) => {
            const result = response.data;
            setDetail(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }
    useEffect(() => {
        refresh();
    }, []);

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModalMode, setEditModalMode] = useState('store');
    const [editModalId, setEditModalId] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);
    const editForm = useRef();
    const showAddModal = () => {
        setEditModalMode('store');
        setEditModalId(null);
        setEditModalVisible(true);
        if (editForm.current) {
            editForm.current.resetFields();
        }
    };
    const showUpdateModal = (id) => {
        if (!id) return;
        setEditModalMode('update');
        setEditModalLoading(true);
        setEditModalId(id);
        setEditModalVisible(true);
        TourniiAPI.admin.companies.show(id).then((response) => {
            const result = response.data;
            console.log('show company', result);
            editForm.current.setFieldsValue(result);
        }).finally(() => {
            setEditModalLoading(false);
        });
    };
    const hideModal = () => {
        setEditModalVisible(false);
        setEditModalMode('store');
        setEditModalLoading(false);
        setEditModalId(null);
    }
    const handleSubmit = () => {
        editForm.current.submit();
    }
    const handleFinish = (values) => {
        if (editModalMode === 'store') {
            setEditModalLoading(true);
            TourniiAPI.admin.companies.store(values).then((response) => {
                notification.info({ message: '新增車行成功' });
            }).finally(() => {
                setEditModalLoading(false);
                refresh();
            });
        } else {
            setEditModalLoading(true);
            TourniiAPI.admin.companies.update(editModalId, values).then((response) => {
                notification.info({ message: '修改車行成功' });
            }).finally(() => {
                setEditModalLoading(false);
                refresh();
            });
        }
    };
    const handleDelete = (id) => {
        setLoading(true);
        TourniiAPI.admin.companies.destroy(id).then((response) => {
            notification.info({ message: '刪除車行成功' });
        }).finally(() => {
            setLoading(false);
            refresh();
        });
    }

    const columns = [
        {
            title: '車行名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '動作',
            fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => showUpdateModal(record.id)}>修改</Typography.Link>
                <Popconfirm title="您是否確定要刪除此車行?" onConfirm={() => handleDelete(record.id)}>
                    <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
            </Space>
        }
    ];

    return (
        <ContentLayout>
            <Spin spinning={loading}>
                <Form style={{ marginBottom: 20 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={showAddModal}>新增</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table columns={columns} dataSource={detail} rowKey="id" scroll={{ x: 'max-content' }} />
                <Drawer title={`${editModalMode === 'store' ? '新增' : '修改'}車行`} open={editModalVisible} onClose={hideModal}>
                    <Spin spinning={editModalLoading}>
                        <Form ref={editForm} onFinish={handleFinish} labelCol={{ span: 6 }}>
                            <Form.Item label="車行名稱" name="name">
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                <Button type="primary" onClick={handleSubmit}>送出</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default CompaniesPage