import { Empty, Image, List, Space, Spin } from 'antd'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI'
import Drawer from './Drawer';
import VehicleStateTag from './VehicleStateTag';

function OrderItemDrawer({
    quantity,
    onClickShowItemVehicle,
    visible, onClose,
    onItemShow, onItemVehicleIndex
}, ref) {
    const [selectedOrderItem, setSelectedOrderItem] = useState();
    const [loading, setLoading] = useState(false);

    const refresh = useCallback(() => {
        if (!visible) {
            return;
        }
        setLoading(true);
        const p1 = onItemShow().then((response) => {
            const result = response.data;
            return result;
        });
        const p2 = onItemVehicleIndex().then((response) => {
            const result = response.data;
            return result.data;
        });
        Promise.all([p1, p2]).then(([item, vehicles]) => {
            // console.log("item", item, "vehicles", vehicles);
            const vehicleSpec = item.vehiclespec;
            const filledVehicles = Array(quantity).fill().map((row, index) => vehicles[index] ? vehicles[index] : {});
            // console.log("filledVehicles", quantity, filledVehicles);
            setSelectedOrderItem({
                vehicleSpec: vehicleSpec,
                item: item,
                vehicles: filledVehicles,
                quantity: quantity
            });
        }).finally(() => {
            setLoading(false);
        })
    }, [onItemShow, onItemVehicleIndex, visible, quantity]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        refresh: refresh
    }));
    const title = selectedOrderItem ? `${selectedOrderItem.vehicleSpec?.vehicletype.brand.name} ${selectedOrderItem.vehicleSpec?.name}` : '';
    return (
        <Drawer title={title} size="large" open={visible} onClose={onClose}>
            <Spin spinning={loading}>
                <Space direction="vertical" style={{ display: 'flex' }}>
                    {selectedOrderItem ? <>
                        <div style={{ textAlign: 'center' }}>
                            <Image src={TourniiAPI.getImageHostUrl(selectedOrderItem.vehicleSpec?.photos[0].thumbnailfileurl)} />
                        </div>
                        <List dataSource={selectedOrderItem.vehicles} bordered renderItem={(row, index) => {
                            return <List.Item style={{ cursor: 'pointer' }} onClick={onClickShowItemVehicle.bind(this, row.id)}>
                                <VehicleStateTag state={row.state} /> {row.vehicleplatenumber ? `車牌 ${row.vehicleplatenumber}` : `填寫車輛 ${index + 1} 資訊`}
                            </List.Item>
                        }}>
                        </List>
                    </> : <Empty />}
                </Space>
            </Spin>
        </Drawer>
    )
}

export default forwardRef(OrderItemDrawer)