import { Button, Col, Form, Input, notification, Row, Space } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TourniiAPI from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout'

function SignUpPage() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleFinish = (values) => {
        if (values.password !== values.password_confirmation) {
            notification.warn({ message: '兩次輸入的密碼不同，請重新輸入' });
            return;
        }
        setLoading(true);
        TourniiAPI.auth.signup(values).then((response) => {
            notification.info({ message: '註冊成功' });
            navigate('/login');
        }).finally(() => {
            setLoading(false);
        });
    };
    return (
        <ContentLayout title="註冊">
            <Row>
                <Col xs={0} md={4} xl={6} xxl={8}></Col>
                <Col xs={24} md={16} xl={12} xxl={8}>
                    <div style={{ marginBottom: 20 }}>
                        請填寫以下註冊資訊以完成註冊
                    </div>
                    <Form onFinish={handleFinish} labelCol={{ span: 4 }} initialValues={{}}>
                        <Form.Item label="E-mail" name="email" rules={[
                            { required: true, message: 'E-mail 為必填' },
                            { type: 'email', message: 'E-mail 格式需為 example@domain.com' }
                        ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="手機號碼" name="cellphone" rules={[
                            { required: true, message: '手機號碼為必填' },
                            { type: 'string', min: 10, max: 10, message: '手機號碼必須為 10 位數' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="使用者姓名" name="name" rules={[{ required: true, message: '使用者姓名為必填' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="密碼" name="password" rules={[
                            { required: true, message: '密碼確認為必填' },
                            { type: 'string', min: 6 },
                        ]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="密碼確認" name="password_confirmation" rules={[
                            { required: true, message: '密碼確認為必填' },
                            { type: 'string', min: 6 },
                        ]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Space>
                                <Button htmlType="reset">清空</Button>
                                <Button type="primary" htmlType="submit" loading={loading}>註冊</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={0} md={4} xl={6} xxl={8}></Col>
            </Row>
        </ContentLayout>
    )
}

export default SignUpPage