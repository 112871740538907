import { Button, Card, Checkbox, Col, DatePicker, Empty, Form, Image, Input, InputNumber, List, Modal, notification, Popconfirm, Result, Row, Select, Space, Spin, Table, Tag } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI, { ORDERSETTLEDSTATE_SETTLED, ORDERSTATE_COMPLETED, ORDERSTATE_DEPOSIT_PAID, ORDERSTATE_NEW, ORDERSTATE_PICKEDUP } from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import ContentLayout from '../ContentLayout'
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SignatureDrawer from '../../components/SignatureDrawer';
import CredentialUploadDrawer from '../../components/CredentialUploadDrawer';
import OdoMeterUploadDrawer from '../../components/OdoMeterUploadDrawer';
import VehiclePhotoUploadDrawer from '../../components/VehiclePhotoUploadDrawer';
import VehicleSelectDrawer from './components/VehicleSelectDrawer';
import StoreOrderItemVehicleDrawer from './components/StoreOrderItemVehicleDrawer';
import StoreOrderItemDrawer from './components/StoreOrderItemDrawer';
import OrderStateBadge from '../../components/OrderStateBadge';
import OrderSettledStateBadge from '../../components/OrderSettledStateBadge';
import StoreUserSelect from './components/StoreUserSelect';

function RentPage() {
    const orderForm = useRef();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [storeId, setStoreId] = useState();
    const [loading, setLoading] = useState(false);
    const [vehicleSpecInvs, setVehicleSpecInvs] = useState([]);
    const [vehicleSpecInvLoading, setVehicleSpecInvLoading] = useState(false);
    const [editMode, setEditMode] = useState('store');
    const [editOrderId, setEditOrderId] = useState();
    const [orderData, setOrderData] = useState();
    const [selectedOrderItemVehicle, setSelectedOrderItemVehicle] = useState();
    const [showPayModal, setShowPayModal] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [paymentLogs, setPaymentLogs] = useState([]);
    const [paymentLogsLoading, setPaymentLogsLoading] = useState(false);
    const [startTime, setStartTime] = useState();
    const [endtime, setEndTime] = useState();
    const payForm = useRef();
    const refundForm = useRef();

    // VehicleSelectDrawer
    const [showVehicleSelectDrawer, setShowVehicleSelectDrawer] = useState(false);

    // OrderitemDrawer
    const orderItemDrawer = useRef();
    const [showOrderItemDrawer, setShowOrderItemDrawer] = useState(false);
    const [selectedOrderItem, setSelectedOrderItem] = useState({});

    // ItemVehicleDrawer
    const orderItemVehicleDrawer = useRef();
    const [showItemVehicleDrawer, setShowItemVehicleDrawer] = useState({ visible: false, vehicleId: null });

    // SignatureDrawer
    const [showSignatureDrawer, setShowSignatureDrawer] = useState({ visible: false, contract: '', dataUrl: null });

    // CredentialUploadDrawer
    const [showCredentialUpload, setShowCredentialUpload] = useState(false);
    const [credentialUploadType, setCredentialUploadType] = useState();
    const [credentialUploadSide, setCredentialUploadSide] = useState();
    const [credentialSrc, setCredentialSrc] = useState();

    // OdoMeterUploadDrawer
    const [showOdoMeterUpload, setShowOdoMeterUpload] = useState(false);
    const [odoMeterUploadType, setOdoMeterUploadType] = useState();
    const [odoMeterValue, setOdoMeterValue] = useState();
    const [odoMeterSrc, setOdoMeterSrc] = useState();

    // VehiclePhotoUploadDrawer
    const [showVehiclePhotoUpload, setShowVehiclePhotoUpload] = useState(false);
    const [vehiclePhotoUploadType, setVehiclePhotoUploadType] = useState();
    const [vehiclePhotoSrc, setVehiclePhotoSrc] = useState();
    const [isError, setIsError] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const paramStoreId = queryParams.get('storeId');
    const paramOrderId = params.orderId;
    const defaultStartTime = moment().set('second', 0).set('minute', Math.ceil(moment().get('minute') / 10) * 10);

    const refresh = useCallback(() => {
        if (!paramOrderId) return;
        setIsError(false);
        setLoading(true);
        TourniiAPI.store.orders.show(paramOrderId).then((response) => {
            setEditMode('update');
            setEditOrderId(paramOrderId);
            const result = response.data;
            setStoreId(result.store_id);
            if (orderForm.current) {
                const newResult = {
                    ...result,
                    expecttime: [
                        moment(result.expectpickuptime, "YYYY-MM-DD HH:mm:ss"),
                        moment(result.expectreturntime, "YYYY-MM-DD HH:mm:ss")
                    ]
                };
                delete newResult.expectpickuptime;
                delete newResult.expectreturntime;
                setOrderData(result);
                orderForm.current.setFieldsValue(newResult);
            }
            // console.log(result);
        }).catch((error) => {
            setIsError(true);
        }).finally(() => {
            setLoading(false);
        });
    }, [paramOrderId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const refreshVehicleInvs = useCallback(() => {
        if (!storeId) return;
        setVehicleSpecInvLoading(true);
        TourniiAPI.store.stores.orders.vehicleSpecInv.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            // console.log(result.data);
            setVehicleSpecInvs(result);
        }).finally(() => {
            setVehicleSpecInvLoading(false);
        });
    }, [storeId]);

    useEffect(() => {
        refreshVehicleInvs();
    }, [refreshVehicleInvs]);

    const refreshPaymentLogs = useCallback(() => {
        if (!storeId || !editOrderId) return;
        setPaymentLogsLoading(true);
        TourniiAPI.store.stores.orders.paymentlogs.index(storeId, editOrderId, {}, 1, 999).then((response) => {
            const result = response.data;
            setPaymentLogs(result.data);
        }).finally(() => {
            setPaymentLogsLoading(false);
        });
    }, [storeId, editOrderId]);

    useEffect(() => {
        refreshPaymentLogs();
    }, [refreshPaymentLogs]);

    const handleFinish = (values) => {
        console.log(values);
        if (!values.expecttime) {
            notification.warn({ message: '請選擇預計取還車時間' });
        }
        const [expectpickuptime, expectreturntime] = values.expecttime;

        const newValues = {
            ...values,
            expectpickuptime: expectpickuptime.format("YYYY-MM-DD HH:mm:ss"),
            expectreturntime: expectreturntime.format("YYYY-MM-DD HH:mm:ss")
        }
        delete newValues.expecttime;

        if (editMode === 'store') {
            setLoading(true);
            TourniiAPI.store.stores.orders.store(storeId, newValues).then((response) => {
                const result = response.data;
                console.log(result);
                notification.info({ message: '預訂成功' });
                navigate(`/store/orders/${result.id}`);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            setLoading(true);
            TourniiAPI.store.stores.orders.update(storeId, editOrderId, newValues).then((response) => {
                const result = response.data;
                console.log(result);
                notification.info({ message: '修改成功' });
                refresh();
                refreshPaymentLogs();
            }).finally(() => {
                setLoading(false);
            })
        }

    }

    // 選擇車輛 Drawer
    const handleShowVehicleSelect = () => {
        let startTime, endTime;
        const expecttime = orderForm?.current?.getFieldValue('expecttime');
        if (expecttime) {
            [startTime, endTime] = expecttime;
            setStartTime(startTime.format("YYYY-MM-DD HH:mm:ss"))
            setEndTime(endTime.format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setStartTime()
            setEndTime();
        }
        setShowVehicleSelectDrawer(true);
    }

    const handleHideVehicleSelect = () => {
        setShowVehicleSelectDrawer(false);
    }

    const handleSelectVehicle = (vehicleSpecId) => {
        console.log('handleSelectVehicle', vehicleSpecId);
        if (orderForm.current) {
            const items = orderForm.current.getFieldValue('items');
            const found = items.find((row) => row.vehiclespec_id === vehicleSpecId);
            if (found) {
                found.quantity += 1;
                orderForm.current.setFieldsValue({
                    items: [...items]
                });
            } else {
                orderForm.current.setFieldsValue({
                    items: [
                        ...items,
                        {
                            vehiclespec_id: vehicleSpecId,
                            quantity: 1,
                        }]
                });
            }
        }
        setShowVehicleSelectDrawer(false);
    }

    // 訂單項目 Drawer
    const handleShowOrderItem = (index) => {
        const items = orderForm.current.getFieldValue('items');
        const item = items[index];
        console.log("handleShowOrderItem", index, item);
        if (!storeId) {
            notification.warn({ message: '尚未選擇店家' });
            return;
        }
        if (!editOrderId) {
            notification.warn({ message: '尚未新增訂單無法查看訂單項目' });
            return;
        }
        if (!item.id) {
            notification.warn({ message: '尚未新增訂單項目無法查看車輛' });
            return;
        }
        setShowOrderItemDrawer(true);
        setSelectedOrderItem({
            ...item
        });
    }

    const handleHideOrderItem = () => {
        setShowOrderItemDrawer(false);
    }

    // 車輛 Drawer
    const handleShowItemVehicleDrawer = (vehicleId) => {
        const itemId = selectedOrderItem.id;
        if (!storeId) {
            notification.warn({ message: '尚未選擇店家' });
            return;
        }
        if (!editOrderId) {
            notification.warn({ message: '尚未新增訂單無法查看訂單項目' });
            return;
        }
        if (!itemId) {
            notification.warn({ message: '尚未新增訂單項目無法查看車輛' });
            return;
        }
        setShowItemVehicleDrawer({
            visible: true,
            vehicleId: vehicleId,
        });
        console.log("handleShowItemVehicleDrawer", vehicleId);
    }

    const handleHideItemVehicleDrawer = () => {
        setShowItemVehicleDrawer({
            visible: false,
        });
    }

    // 簽名上傳
    const handleShowSignature = async (itemVehicle) => {
        if (!itemVehicle) {
            return;
        }
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        // console.log("handleShowSignature", itemVehicle);
        setSelectedOrderItemVehicle(itemVehicle);
        const dataUrl = await TourniiAPI.store.stores.orders.items.vehicles.signature.show(storeId, editOrderId, itemId, vehicleId).then((response) => {
            //const result = response.data;
            // console.log("read signature size", result.filedata.length);
            return response?.data?.filedata;
        });
        const contractResult = await TourniiAPI.store.stores.contracts.latest(storeId).then((response) => {
            return response?.data
        });
        setShowSignatureDrawer({ visible: true, contract: contractResult, dataUrl: dataUrl });
    }

    const handleHideSignature = () => {
        setShowSignatureDrawer({ visible: false, contract: null, dataUrl: null });
    }

    const handleFinishSignature = (dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        // console.log("save signature size", dataUrl.length);
        // console.log('handleFinishSig', itemId, vehicleId, file, dataUrl);
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: 'test',
            imagefile: file,
            // storecontract_id: 1,
        };
        TourniiAPI.store.stores.orders.items.vehicles.signature.store(storeId, editOrderId, itemId, vehicleId, data).then((response) => {
            notification.info({ message: '簽名儲存成功' });
            // const result = response.data;
            // setSigDataUrl(result.filedata); // TODO 取不到資料
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowSignatureDrawer({ ...showSignatureDrawer, visible: false, contract: null });
        });
    }

    const handleDeleteSignature = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.store.stores.orders.items.vehicles.signature.destroy(storeId, editOrderId, itemId, vehicleId).then((response) => {
            notification.info({ message: '簽名刪除成功' });
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            handleHideSignature();
        });
    }

    // 證件上傳
    const handleShowCredentialUpload = (itemVehicle, type, side) => {
        if (!itemVehicle) {
            return;
        }
        console.log("handleshowCredentialUpload", itemVehicle, type);
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        setSelectedOrderItemVehicle(itemVehicle);
        setCredentialUploadType(type);
        setCredentialUploadSide(side);
        TourniiAPI.store.stores.orders.items.vehicles.credentials.show(storeId, editOrderId, itemId, vehicleId, type, side).then((response) => {
            const result = response.data;
            setCredentialSrc(result.filedata);
        }).catch((error) => {
            setCredentialSrc(null);
        }).finally(() => {
            setShowCredentialUpload(true);
        });
    }

    const handleHideCredentialUpload = () => {
        setShowCredentialUpload(false);
        setCredentialUploadType();
        setCredentialSrc(null);
    }

    const handleFinishCredentialUpload = (dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        // console.log("handleFinishCredentialUpload", itemId, vehicleId, dataUrl);
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        if (!dataUrl) {
            notification.warn({ message: '請上傳照片' })
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: 'test',
            imagefile: file,
        }
        TourniiAPI.store.stores.orders.items.vehicles.credentials.store(storeId, editOrderId, itemId, vehicleId, credentialUploadType, credentialUploadSide, data).then((response) => {
            notification.info({ message: '證件上傳成功' })
            const result = response.data;
            console.log(result);
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowCredentialUpload(false);
            setCredentialSrc(null);
        })
    }

    const handleDeleteCredentialUpload = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.store.stores.orders.items.vehicles.credentials.destroy(storeId, editOrderId, itemId, vehicleId, credentialUploadType, credentialUploadSide).then((response) => {
            notification.info({ message: '證件刪除成功' })
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowCredentialUpload(false);
            setCredentialSrc(null);
        });
    }

    // 里程數上傳
    const handleShowOdoMeterUpload = (itemVehicle, type) => {
        if (!itemVehicle) {
            return;
        }
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        console.log("handleShowOdoMeterUpload", itemVehicle, type);
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        setSelectedOrderItemVehicle(itemVehicle);
        TourniiAPI.store.stores.orders.items.vehicles.odometer.show(storeId, editOrderId, itemId, vehicleId, type).then((response) => {
            const result = response.data;
            setOdoMeterSrc(result.filedata);
            setOdoMeterValue(result.odometer);
        }).finally(() => {
            setShowOdoMeterUpload(true);
            setOdoMeterUploadType(type);
        });
    }

    const handleHideOdoMeterUpload = () => {
        setShowOdoMeterUpload(false);
        setOdoMeterSrc(null);
        setOdoMeterValue();
        setOdoMeterUploadType();
    }

    const handleFinishOdoMeterUpload = (odometer, dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        // console.log("handleFinishUploadOdoMeter", itemId, vehicleId, dataUrl);
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: '里程',
            imagefile: file,
            odometerunit: 'km',
            odometer: odometer,
        };
        TourniiAPI.store.stores.orders.items.vehicles.odometer.store(storeId, editOrderId, itemId, vehicleId, odoMeterUploadType, data).then((response) => {
            notification.info({ message: '記錄里程成功' })
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowOdoMeterUpload(false);
            setOdoMeterSrc(null);
            setOdoMeterValue();
            setOdoMeterUploadType();
        });
    }

    const handleDeleteOdoMeterUpload = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.store.stores.orders.items.vehicles.odometer.destroy(storeId, editOrderId, itemId, vehicleId, odoMeterUploadType).then((response) => {
            notification.info({ message: '車輛照片刪除成功' });
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowOdoMeterUpload(false);
            setOdoMeterSrc(null);
            setOdoMeterValue();
            setOdoMeterUploadType();
        });
    }

    // 車輛照片上傳
    const handleShowVehiclePhotoUpload = (itemVehicle, type) => {
        if (!itemVehicle) {
            return;
        }
        // console.log("handleShowVehiclePhotoUpload", itemVehicle, type);
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        setSelectedOrderItemVehicle(itemVehicle);
        setVehiclePhotoUploadType(type);
        setVehiclePhotoSrc(null);
        TourniiAPI.store.stores.orders.items.vehicles.photos.show(storeId, editOrderId, itemId, vehicleId, type).then((response) => {
            const result = response.data;
            // console.log("handleShowVehiclePhotoUpload", result.filedata, response.status);
            setVehiclePhotoSrc(result.filedata);
        }).finally(() => {
            setShowVehiclePhotoUpload(true);
        });
    }

    const handleHideVehiclePhotoUpload = () => {
        setVehiclePhotoSrc(null);
        setShowVehiclePhotoUpload(false);
    }

    const handleFinishVehiclePhotoUpload = (dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        // console.log("handleFinishVehiclePhotoUpload", itemId, vehicleId, dataUrl);
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: vehiclePhotoUploadType,
            imagefile: file,
        };
        TourniiAPI.store.stores.orders.items.vehicles.photos.store(storeId, editOrderId, itemId, vehicleId, vehiclePhotoUploadType, data).then((response) => {
            notification.info({ message: '車輛照片上傳成功' })
            const result = response.data;
            console.log(result);
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setVehiclePhotoSrc(null);
            setShowVehiclePhotoUpload(false);
        });
    }

    const handleDeleteVehiclePhotoUpload = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!storeId || !editOrderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 storeId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.store.stores.orders.items.vehicles.photos.destroy(storeId, editOrderId, itemId, vehicleId, vehiclePhotoUploadType).then((response) => {
            notification.info({ message: '車輛照片刪除成功' })
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setVehiclePhotoSrc(null);
            setShowVehiclePhotoUpload(false);
        });
    }

    const defaultPayFieldsValue = { paytype: 'cash', allowanceamount: 0, amount: 0, issettled: false };
    const handleShowPayModal = () => {
        setShowPayModal(true);
        if (payForm.current) {
            payForm.current.setFieldsValue(defaultPayFieldsValue);
        }
    }
    const handleHidePayModal = () => {
        setShowPayModal(false);
    }
    const handlePay = (values) => {
        if (!storeId || !editOrderId) return;
        if (orderData.settledstate === ORDERSETTLEDSTATE_SETTLED) {
            notification.warn({ message: '訂單已結清、無法進行付款' });
            return;
        }
        setLoading(true);
        TourniiAPI.store.stores.orders.pay(storeId, editOrderId, values).then((response) => {
            notification.info({ message: '付款成功' });
            handleHidePayModal();
            refresh();
        }).finally(() => {
            setLoading(false);
        });
    }
    const defaultRefundFieldsValue = { paytype: 'cash', amount: 0 };
    const handleShowRefundModal = () => {
        setShowRefundModal(true);
        if (refundForm.current) {
            refundForm.current.setFieldsValue(defaultPayFieldsValue);
        }
    }
    const handleHideRefundModal = () => {
        setShowRefundModal(false);
    }
    const handleRefund = (values) => {
        if (!storeId || !editOrderId) return;
        setLoading(true);
        TourniiAPI.store.stores.orders.refund(storeId, editOrderId, values).then((response) => {
            notification.info({ message: '退款成功' });
            handleHideRefundModal();
            refresh();
        }).finally(() => {
            setLoading(false);

        });
    }
    const handleUndo = () => {
        if (!storeId || !editOrderId) return;
        if (orderData.state !== ORDERSTATE_COMPLETED) {
            notification.warn({ message: '訂單必須已完成才能做取消' });
            return;
        }
        setLoading(true);
        TourniiAPI.store.stores.orders.undo(storeId, editOrderId, {}).then((response) => {
            notification.info({ message: '已取消完成' });
            refresh();
        }).finally(() => {
            setLoading(false);
        });
    }
    const handlePickup = () => {
        if (!storeId || !editOrderId) return;
        setLoading(true);
        TourniiAPI.store.stores.orders.pickup(storeId, editOrderId, {}).then((response) => {
            notification.info({ message: '已取車完成' });
            refresh();
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleComplete = () => {
        if (!storeId || !editOrderId) return;
        setLoading(true);
        TourniiAPI.store.stores.orders.complete(storeId, editOrderId, {}).then((response) => {
            notification.info({ message: '已完成訂單' });
            refresh();
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleUserChange = (value, user) => {
        if (orderForm.current) {
            orderForm.current.setFieldsValue({
                'rentername': user.name,
                'rentercellphone': user.cellphone
            });
        }
    }
    return (
        <ContentLayout title="租車">
            {isError && <Result title="您沒有權限存取該訂單" />}
            {!isError && <Spin spinning={loading}>
                <AuthStoreSelect disabled={editMode !== 'store'} value={storeId} onSelect={(value) => setStoreId(value)} />
                <Form ref={orderForm} onFinish={handleFinish} labelCol={{ span: 4 }} size="middle" initialValues={{
                    source: 'store',
                    expecttime: [defaultStartTime, defaultStartTime.clone().add(1, 'hour')],
                }}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Card title={`${editMode === 'store' ? '新增' : '修改'}訂單`}>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Space>
                                    {(orderData?.state !== ORDERSTATE_COMPLETED && orderData?.settledstate !== ORDERSETTLEDSTATE_SETTLED) &&
                                        <Button type="primary" onClick={handleShowPayModal}>付款</Button>
                                    }
                                    {(orderData?.state !== ORDERSTATE_COMPLETED) &&
                                        <Button onClick={handleShowRefundModal}>退款</Button>
                                    }
                                    {orderData?.state === ORDERSTATE_COMPLETED && <Popconfirm onConfirm={handleUndo} title="是否確定取消完成?">
                                        <Button danger>取消完成</Button>
                                    </Popconfirm>}
                                    {(orderData?.state === ORDERSTATE_NEW || orderData?.state === ORDERSTATE_DEPOSIT_PAID) &&
                                        <Button type="primary" onClick={handlePickup}>完成取車</Button>
                                    }
                                    {(orderData?.state === ORDERSTATE_PICKEDUP) &&
                                        <Button type="primary" onClick={handleComplete}>完成訂單</Button>
                                    }
                                </Space>
                            </Form.Item>
                            <Form.Item label="訂單編號" shouldUpdate>
                                {({ getFieldValue }) => getFieldValue('serialno') ?? '無'}
                            </Form.Item>
                            <Form.Item label="訂單來源" name="source">
                                <Select disabled={true}>
                                    <Select.Option value="store">店家</Select.Option>
                                    <Select.Option value="platform">平台</Select.Option>
                                    <Select.Option value="external-kkday">外部-KKDay</Select.Option>
                                    <Select.Option value="external-klook">外部-KLook</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="使用者" name="renteruser_id">
                                <StoreUserSelect storeId={storeId} onChange={handleUserChange} placeholder="請輸使用者 Email 或手機查詢" />
                            </Form.Item>
                            <Form.Item label="承租者姓名" name="rentername" rules={[{ required: true, message: '請輸入承租者姓名' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="承租者電話" name="rentercellphone" rules={[{ required: true, message: '請輸入承租者電話' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="預計取還車時間" name="expecttime">
                                <DatePicker.RangePicker showTime={true} format="YYYY-MM-DD HH:mm" />
                            </Form.Item>
                            <Form.Item label="承租者備註" name="renternote">
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label="訂單備註" name="processmemo">
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item>
                                <Row>
                                    <Col xs={24} md={12}>
                                        <Form.Item labelCol={{ span: 8 }} label="狀態">
                                            <OrderStateBadge state={orderData?.state} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="結帳狀態" shouldUpdate>
                                            {({ getFieldValue }) => <OrderSettledStateBadge state={getFieldValue('settledstate')} />}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="實際取車時間" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('actualpickuptime') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="實際還車時間" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('actualreturntime') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="處理人員" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('modifieduser')?.name ?? '無'}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item labelCol={{ span: 8 }} label="預計金額" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('expecttotalamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="實際金額" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('totalamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="已折扣" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('allowanceamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="已收" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('receivedamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="應收帳款" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('accountsreceivable') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="發票號碼" hidden={true} shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('receiptnumber') ?? '未開立'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="發票日期" hidden={true} shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('receiptdate') ? moment(getFieldValue('receiptdate')).format("YYYY-MM-DD HH:mm:ss") : '未開立'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="建立日期" shouldUpdate>
                                            {({ getFieldValue }) => moment(getFieldValue('created_at')).format("YYYY-MM-DD HH:mm:ss")}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="修改日期" shouldUpdate>
                                            {({ getFieldValue }) => moment(getFieldValue('updated_at')).format("YYYY-MM-DD HH:mm:ss")}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Card>
                        <Card title="明細" extra={<Button onClick={handleShowVehicleSelect}>選擇車輛</Button>}>
                            <Form.List name="items" initialValue={[]}>
                                {(fields, { add, remove }, { errors }) => {
                                    // console.log(fields);
                                    if (vehicleSpecInvLoading) {
                                        return <div style={{ textAlign: 'center', padding: 10 }}>
                                            <Spin spinning={vehicleSpecInvLoading} />
                                        </div>;
                                    }
                                    return (
                                        <List bordered>
                                            {fields.length === 0 && <Empty style={{ padding: 10 }} description="請先選擇車輛" />}
                                            {fields.map((field, index) => {
                                                const items = orderForm.current.getFieldValue('items');
                                                const item = items[index];
                                                //console.log("field", field, "fields", fields, "item", item);
                                                return <VehicleFormListItem
                                                    key={field.key}
                                                    field={field} // 表單欄位資訊
                                                    item={item} // 明細資訊
                                                    vehicleSpecInvs={vehicleSpecInvs} // 庫存資訊
                                                    onClick={handleShowOrderItem.bind(this, index)}
                                                    onRemove={remove.bind(this, field.name)}
                                                />;
                                            })}
                                        </List>
                                    )
                                }}
                            </Form.List>
                        </Card>
                        <Card title="付款記錄">
                            <Spin spinning={paymentLogsLoading}>
                                <Table columns={[
                                    {
                                        dataIndex: 'date',
                                        title: '日期',
                                    },
                                    {
                                        dataIndex: 'action',
                                        title: '動作',
                                    },
                                    {
                                        dataIndex: 'salesamountdiff',
                                        title: '銷售差額',
                                        align: 'right',
                                        render: (value) => value.toLocaleString()
                                    },
                                    // {
                                    //     dataIndex: 'salesamountremaining',
                                    //     title: '銷售餘額',
                                    // },
                                    {
                                        dataIndex: 'receivedamountdiff',
                                        title: '已收差額',
                                        align: 'right',
                                        render: (value) => value.toLocaleString()
                                    },
                                    // {
                                    //     dataIndex: 'receivedamountremaining',
                                    //     title: '已收餘額',
                                    // },
                                    {
                                        dataIndex: 'accountsreceivable',
                                        title: '應收',
                                        align: 'right',
                                        render: (value) => value.toLocaleString()
                                    },
                                    {
                                        dataIndex: 'paytype',
                                        title: '付款方式',
                                    },
                                ]} dataSource={paymentLogs} rowKey="id" scroll={{ x: 700 }} pagination={false} />
                            </Spin>
                        </Card>
                        <div style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit">送出</Button>
                        </div>
                    </Space>
                </Form>
            </Spin>}
            <Modal open={showPayModal} onOk={() => payForm?.current.submit()} onCancel={handleHidePayModal} title="付款">
                <Form ref={payForm} onFinish={handlePay} initialValues={defaultPayFieldsValue}>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="應收帳款">
                                {orderData?.accountsreceivable}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="類型" name="paytype">
                                <Select>
                                    <Select.Option value="cash">現金</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="折扣" name="allowanceamount">
                                <InputNumber min={0} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="收款" name="amount">
                                <InputNumber min={0} max={orderData?.accountsreceivable} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="issettled" label="結清" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal open={showRefundModal} onOk={() => refundForm?.current.submit()} onCancel={handleHideRefundModal} title="退款">
                <Form ref={refundForm} onFinish={handleRefund} initialValues={defaultRefundFieldsValue}>
                    <Form.Item label="已收">
                        {orderData?.receivedamount}
                    </Form.Item>
                    <Form.Item label="類型" name="paytype">
                        <Select>
                            <Select.Option value="cash">現金</Select.Option>
                            {/* <Select.Option value="tappay">TapPay</Select.Option> */}
                        </Select>
                    </Form.Item>
                    <Form.Item label="金額" name="amount">
                        <InputNumber min={0} max={orderData?.receivedamount} />
                    </Form.Item>
                </Form>
            </Modal>
            <VehicleSelectDrawer
                storeId={storeId}
                visible={showVehicleSelectDrawer}
                startTime={startTime}
                endTime={endtime}
                onOk={handleSelectVehicle}
                onClose={handleHideVehicleSelect} />
            <StoreOrderItemDrawer
                ref={orderItemDrawer}
                storeId={storeId}
                orderId={editOrderId}
                itemId={selectedOrderItem.id}
                quantity={selectedOrderItem.quantity}
                vehicleSpecId={selectedOrderItem.vehiclespec_id}
                visible={showOrderItemDrawer}
                onClickShowItemVehicle={handleShowItemVehicleDrawer}
                onClose={handleHideOrderItem} />
            <StoreOrderItemVehicleDrawer
                ref={orderItemVehicleDrawer}
                storeId={storeId}
                orderId={editOrderId}
                itemId={selectedOrderItem.id}
                vehicleId={showItemVehicleDrawer.vehicleId}
                visible={showItemVehicleDrawer.visible}
                onClose={handleHideItemVehicleDrawer}
                onClickOdoMeterUpload={handleShowOdoMeterUpload}
                onClickCredentialUpload={handleShowCredentialUpload}
                onClickSignature={handleShowSignature}
                onClickVehiclePhotoUpload={handleShowVehiclePhotoUpload}
                onUpdate={() => {
                    if (orderItemDrawer.current) {
                        orderItemDrawer.current.refresh();
                    }
                    refresh();
                }}
            />
            <OdoMeterUploadDrawer
                visible={showOdoMeterUpload}
                value={odoMeterValue}
                photo={odoMeterSrc}
                onFinish={handleFinishOdoMeterUpload}
                onClose={handleHideOdoMeterUpload}
                onDelete={handleDeleteOdoMeterUpload}
            />
            <VehiclePhotoUploadDrawer
                visible={showVehiclePhotoUpload}
                value={vehiclePhotoSrc}
                onFinish={handleFinishVehiclePhotoUpload}
                onClose={handleHideVehiclePhotoUpload}
                onDelete={handleDeleteVehiclePhotoUpload}
            />
            <CredentialUploadDrawer
                visible={showCredentialUpload}
                value={credentialSrc}
                onFinish={handleFinishCredentialUpload}
                onClose={handleHideCredentialUpload}
                onDelete={handleDeleteCredentialUpload}
            />
            <SignatureDrawer
                visible={showSignatureDrawer.visible}
                contract={showSignatureDrawer.contract}
                dataUrl={showSignatureDrawer.dataUrl}
                onFinish={handleFinishSignature}
                onClose={handleHideSignature}
                onDelete={handleDeleteSignature}
            />

        </ContentLayout>
    )
}

export default RentPage

function VehicleFormListItem({ field, item, vehicleSpecInvs, onRemove, onClick, ...props }) {
    let isNewItem = !item.vehiclespec;
    let imageUrl = null;
    let vehicleName = null;
    if (!isNewItem) { // 若為現有資料則直接顯示 item 內的車輛資訊
        imageUrl = TourniiAPI.getImageHostUrl(item.vehiclespec.photos[0].thumbnailfileurl);
        vehicleName = `${item.vehiclespec.vehicletype.brand.name} ${item.vehiclespec.name}`;
    } else { // 若非現有資料則從庫存資訊中查詢車輛資訊
        const vehicleSpecInv = vehicleSpecInvs.find((row) => row.vehiclespec_id === item.vehiclespec_id);
        if (!vehicleSpecInv) return <Empty description="查無庫存資訊" />;
        imageUrl = TourniiAPI.getImageHostUrl(vehicleSpecInv.vehiclespec.photos[0].thumbnailfileurl);
        vehicleName = `${vehicleSpecInv.vehiclespec.vehicletype.brand.name} ${vehicleSpecInv.vehiclespec.name}`;
    }
    return (
        <List.Item style={{ cursor: 'pointer' }} onClick={onClick}>
            <Row style={{ flex: 1 }} gutter={[5, 5]}>
                <Col xs={24} lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Image
                        style={{ width: 100, aspectRatio: 1, objectFit: 'cover' }}
                        src={imageUrl}
                        preview={false}
                    />
                </Col>
                <Col xs={24} lg={14} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <h3>{vehicleName} {isNewItem && <Tag color="red">新增</Tag>}</h3>
                    <div>
                        {!isNewItem && item.vehicles?.map((vehicle) => <Tag key={vehicle.id}>{vehicle.vehicleplatenumber}</Tag>)}
                    </div>
                    <Form.Item {...field} name={[field.name, "vehiclespec_id"]} hidden={true} style={{ marginBottom: 0 }}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Row gutter={[10, 10]}>
                        <Col span={24} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            <div style={{ flex: 2 }}>
                                <Form.Item {...field} name={[field.name, "quantity"]} style={{ marginBottom: 0 }} onClick={(event) => event.stopPropagation()}>
                                    <InputNumber min={1} style={{ width: '100%' }} />
                                </Form.Item>
                            </div>
                            <Button style={{ flex: 1 }}>取還車</Button>
                            <Button style={{ flex: 1 }} onClick={(event) => { onRemove(); event.stopPropagation(); }} >刪除</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </List.Item>
    );
}