import { Button, notification, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import TourniiAPI from '../../api/TourniiAPI';
import EmptyRenterResult from '../../components/EmptyRenterResult';
import ContentLayout from '../ContentLayout'

const defaultWidth = 340;
const defaultHeight = 340;

const LoadingMask = () => {
    return <div style={{
        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        backgroundColor: 'white', opacity: 0.9, zIndex: 1,
    }}><Spin spinning={true} /></div>
}
function SignaturePage() {
    const user = TourniiAPI.auth.getLoginUser();
    const renterId = user ? user.renter_id : null;
    const [loading, setLoading] = useState(false);
    const [, setCanvasSize] = useState({ width: defaultWidth, height: defaultHeight });
    const [sigDataUrl, setSigDataUrl] = useState();
    const canvas = useRef(null);
    const wrapper = useRef(null);

    const restoreData = useCallback(() => {
        if (!canvas.current) return;
        canvas.current.clear();
        if (sigDataUrl) {
            canvas.current.fromDataURL(sigDataUrl, { ratio: 1 });
        }
    }, [sigDataUrl]);

    const resized = useCallback(() => {
        // console.log("resized");
        if (!canvas.current) return;
        // const rawCanvas = canvas.current.getCanvas();
        // console.log("canvas", rawCanvas.width, rawCanvas.height);
        const wrapperWidth = wrapper.current ? wrapper.current.clientWidth : defaultWidth;
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : defaultHeight;

        // console.log("wrapper", wrapperWidth, wrapperHeight);
        const tmpData = canvas.current.toDataURL();

        // Resize canvas
        canvas.current.getCanvas().width = wrapperWidth;
        canvas.current.getCanvas().height = wrapperHeight;
        setCanvasSize({ width: wrapperWidth, height: wrapperHeight });

        canvas.current.clear();
        canvas.current.fromDataURL(tmpData, { ratio: 1 });
    }, [canvas]);

    useEffect(() => {
        restoreData();
    }, [restoreData]);

    useEffect(() => {
        setTimeout(() => {
            resized(); // after drawer visible
        }, 100);
        window.addEventListener("resize", resized);
        return () => window.removeEventListener("resize", resized);
    }, [resized]);

    const handleClear = () => {
        canvas.current.clear();
    }
    const refresh = useCallback(() => {
        if (!renterId) return;
        setLoading(true);
        TourniiAPI.renter.signature.show(renterId).then((response) => {
            const result = response.data;
            // console.log("read signature size", result.filedata.length);
            setSigDataUrl(result.signaturefiledata);
        }).finally(() => {
            setLoading(false);
        });
    }, [renterId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleFinishSignature = () => {
        const dataUrl = canvas.current.toDataURL();
        if (!renterId) {
            notification.warn({ message: '缺少資料 renterId' });
            return;
        }
        // console.log("save signature size", dataUrl.length);
        // console.log('handleFinishSig', itemId, vehicleId, file, dataUrl);
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            imagefile: file,
        };
        TourniiAPI.renter.signature.store(renterId, data).then((response) => {
            notification.info({ message: '簽名儲存成功' });
        }).finally(() => {
            refresh();
        });
    }

    const handleDeleteSignature = () => {
        if (!renterId) {
            notification.warn({ message: '缺少資料 renterId' });
            return;
        }
        TourniiAPI.renter.signature.destroy(renterId).then((response) => {
            notification.info({ message: '簽名刪除成功' });
        }).finally(() => {
            setSigDataUrl();
            refresh();
        });
    }
    return (
        <ContentLayout title="簽名">
            {loading ? <LoadingMask /> : null}
            <EmptyRenterResult renterId={renterId}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ flex: 1, lineHeight: 0, border: '1px solid black', overflow: 'auto' }}>
                        <div ref={wrapper} style={{ height: '100%', minHeight: 540, position: 'relative' }}>
                            <ReactSignatureCanvas ref={canvas} penColor='blue' clearOnResize={false}
                                canvasProps={{ width: defaultWidth, height: defaultHeight, className: 'sigCanvas' }} />
                            <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                                <Space>
                                    {sigDataUrl && <Button onClick={handleDeleteSignature}>刪除</Button>}
                                    <Button onClick={handleClear}>清除</Button>
                                    <Button type="primary" onClick={handleFinishSignature}>送出</Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                </div>
            </EmptyRenterResult>
        </ContentLayout>
    )
}

export default SignaturePage