import { notification } from 'antd';
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TourniiAPI from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout';

// 此頁面用來統一處理由後端導回的請求
function VerifyPage() {
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();
    const emailVerfied = searchParams.get('email_verified');

    useEffect(() => {
        console.log(emailVerfied);
        if (emailVerfied !== null) {
            if (emailVerfied) {
                notification.info({ message: '電子郵件驗證成功' });
            } else {
                notification.warn({ message: '電子郵件驗證失敗' })
            }
            navigate('/', { replace: true });
        }
        const storeMemberInviteCode = searchParams.get('store-member-invitecode');
        if (storeMemberInviteCode !== null) {
            if (TourniiAPI.auth.getLoginUser()) {
                TourniiAPI.auth.me.storeMemberAccept(storeMemberInviteCode).then((response) => {
                    notification.info({ message: '加入店家成員成功' });
                    TourniiAPI.auth.refreshPermission();
                }).finally(() => {
                    navigate('/', { replace: true });
                });
                return;
            } else {
                notification.info({ message: '請先登入以加入店家成員' });
                navigate('/login?redirect=' + encodeURIComponent('/verify/?store-member-invitecode=' + encodeURIComponent(storeMemberInviteCode)), { replace: true });
            }
        }
    }, [navigate, searchParams, emailVerfied]);
    return (
        <ContentLayout>驗證中</ContentLayout>
    )
}

export default VerifyPage