import { Tag } from 'antd'
import React from 'react'
import { VehicleStates } from '../api/TourniiAPI';

function VehicleStateTag({ state }) {
    let name = state || '未取車';
    let color = null;
    const found = VehicleStates.find((row) => row.id === state);
    if (found) {
        name = found.name;
        color = found.color;
    }
    return (
        <Tag color={color}>{name}</Tag>
    )
}

export default VehicleStateTag