import { Alert, Button, Checkbox, Col, Descriptions, Input, List, notification, Result, Row, Skeleton, Space, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import ContentLayout from '../ContentLayout'
import { useOrderCart } from '../../OrderCartContext';
import TourniiAPI from '../../api/TourniiAPI';
import PickAndReturnDatePicker from '../../components/PickAndReturnDatePicker';
import RwdInputNumber from '../../components/RwdInputNumber';


const VehicleItem = ({ itemData, onClick, onCheck, onDelete, onUpdate }) => {
  if (!itemData.data) return <div style={{ textAlign: 'center', padding: 10 }}><Spin spinning={true} /></div>;
  return <div style={{ width: '100%' }}>
    <Row gutter={[15, 15]}>
      <Col xs={24} md={6} onClick={onClick}>
        <img
          style={{ width: '100%', height: 160, objectFit: 'cover' }}
          alt={itemData.data.vehiclespec.vehicletype.brand.name} src={TourniiAPI.getImageHostUrl(itemData.data.vehiclespec.photos[0].thumbnailfileurl)} />
      </Col>
      <Col xs={2} md={1} style={{ display: 'flex', alignItems: 'center ' }}>
        <Checkbox checked={itemData.checked} onChange={onCheck} />
      </Col>
      <Col xs={22} md={17}>
        <Row gutter={[10, 10]} style={{ height: '100%' }}>
          <Col xs={24} md={24} style={{ display: 'flex', alignItems: 'center' }}>
            <h3 >{itemData.data.vehiclespec.vehicletype.brand.name} {itemData.data.vehiclespec.name}</h3>
          </Col>
          <Col xs={24} md={8} style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>價格</span> ${itemData.price}
            </Space>
          </Col>
          <Col xs={16} md={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <span style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>數量</span>
              <RwdInputNumber min={1} value={itemData.quantity} max={10} style={{ width: 60 }} onChange={onUpdate} />
            </Space>
          </Col>
          <Col xs={8} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <Button onClick={onDelete} danger>刪除</Button>
          </Col>
          <Col span={24}>
            {itemData.data.errors?.map((message, index) => <Alert key={`alert-${index}`} style={{ marginBottom: 10 }} message={message} type="error" />)}
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
}
function CartListPage() {
  const [orderCart, cartActions] = useOrderCart();
  const navigate = useNavigate();
  const renterId = TourniiAPI.auth.getLoginUser() ? TourniiAPI.auth.getLoginUser().renter_id : null;
  const starttime = orderCart.starttime ? moment(orderCart.starttime) : null;
  const endtime = orderCart.endtime ? moment(orderCart.endtime) : null;
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState({
    startDate: starttime,
    endDate: endtime,
  });
  const [cartItems, setCartItems] = useState(orderCart.items);

  const isLogined = () => {
    return !!TourniiAPI.auth.getLoginUser();
  }
  const handleChangeSearchPeriod = (value) => {
    if (value) {
      const { startDate, endDate } = value;
      cartActions.setPeriod([
        startDate?.format("YYYY-MM-DD HH:mm:ss"),
        endDate?.format("YYYY-MM-DD HH:mm:ss")
      ])
    } else {
      cartActions.setPeriod([null, null])
    }
    setSelectedPeriod(value);
  }
  const handleClear = useCallback(() => {
    cartActions.clear();
  }, [cartActions]);

  const handleAddOrder = useCallback(() => {
    const newOrderCart = { ...orderCart };
    const items = newOrderCart.items.filter((item) => item.checked);
    if (items.length === 0) {
      notification.warn({ message: '請至少勾選一個項目' });
      return;
    }
    if (!isLogined()) {
      cartActions.markAddOrderAfterLogin();
      newOrderCart.addOrderAfterLogin = true;
      navigate('/login?redirect=' + encodeURIComponent('/cart'));
      return;
    }
    if (!renterId) {
      notification.warn({ message: '請先新增租車資料' });
      navigate('/profile?redirect=' + encodeURIComponent('/cart'));
      return;
    }
    cartActions.clearAddOrderAfterLogin();

    const newItems = items.map((item) => ({
      "vehiclespec_id": item.vehiclespec_id,
      "quantity": item.quantity
    }));

    const order = {
      store_id: newOrderCart.storeId,
      expectpickuptime: newOrderCart.starttime,
      expectreturntime: newOrderCart.endtime,
      items: newItems,
      renternote: newOrderCart.renterNote,
    };
    console.log("order", order);
    setLoading(true);
    TourniiAPI.renter.renters.orders.store(renterId, order).then((response) => {
      notification.info({ message: "預訂成功" });
      navigate('/orders');
      handleClear();
    }).finally(() => {
      setLoading(false);
    });

  }, [orderCart, navigate, renterId, handleClear, cartActions]);

  const handleContinue = () => {
    navigate(`/search?type=store&typeId=${orderCart.storeId}`);
  };
  const handleChangeQty = (index, value) => {
    cartActions.updateItemQty(index, value);
  }
  const handleCheckItem = (index, checked) => {
    cartActions.checkItem(index, checked);
  }
  const handleDelete = (index) => {
    cartActions.removeItem(index);
  }

  const handleUpdateRenterNote = (e) => {
    cartActions.updateRenterNote(e.target.value);
  }

  const total = orderCart.items.reduce((pv, cv, idx) => {
    if (cv.checked) {
      return pv + cv.price * cv.quantity;
    }
    return pv;
  }, 0);

  useEffect(() => {
    if (orderCart.addOrderAfterLogin) {
      handleAddOrder();
    }
  }, [handleAddOrder, orderCart.addOrderAfterLogin]);


  const fetchStore = useCallback(() => {
    if (!orderCart.storeId) return;
    TourniiAPI.public.stores.show(orderCart.storeId).then((response) => {
      const result = response.data;
      setStoreData(result);
    });
  }, [orderCart.storeId]);

  useEffect(() => {
    fetchStore();
  }, [fetchStore]);

  const fetchVehicleListingInfo = useCallback((starttime, endtime, vehicleSpecInvId) => {
    return TourniiAPI.public.listingVehicles.show(vehicleSpecInvId, {
      expectpickuptime: starttime,
      expectreturntime: endtime,
    }).then((response) => {
      return response;
    });
  }, []);

  useEffect(() => {
    console.log("refresh cart items");
    // Load cart items from local storage store to state.
    const newCartItems = [...orderCart.items];

    setLoading(true);
    let promises = [];
    // Get price info for every cart item.
    newCartItems.forEach((item, index) => {
      const vehiclespecinv_id = item.vehiclespecinv_id;
      if (vehiclespecinv_id) {
        let promise = fetchVehicleListingInfo(orderCart.starttime, orderCart.endtime, vehiclespecinv_id).then((response) => {
          const detail = response.data;
          item.data = { ...detail }; // Append listing info to item.
          item.price = detail.price;
          console.log("fetchVehicleListingInfo", item);
        });
        promises.push(promise);
      }
    });

    // Wait all promises finish.
    Promise.all(promises).then(() => {
      console.log("newCartItems", newCartItems);
      setCartItems(newCartItems);
    }).finally(() => {
      setLoading(false);
    });
  }, [orderCart, orderCart.starttime, orderCart.endtime, fetchVehicleListingInfo]);

  return (
    <ContentLayout title="購物車">
      <Spin spinning={loading}>
        {orderCart.items.length === 0 && <Result title="目前沒有任何項目" extra={<Button type="primary" onClick={() => navigate('/')}>搜尋車輛</Button>} />}
        {orderCart.items.length > 0 &&
          <Row gutter={[10, 10]}>
            {!storeData ? <Skeleton /> : <Col span={24}>
              <Descriptions column={1}>
                <Descriptions.Item label="車行">{storeData.company.name}</Descriptions.Item>
                <Descriptions.Item label="店家">{storeData.name}</Descriptions.Item>
                <Descriptions.Item label="地址">{storeData.address}</Descriptions.Item>
                <Descriptions.Item label="營業時間">
                  <ul>
                    {storeData.opentimes.map((opentime) => <li key={opentime.id}>{moment(opentime.dayofweek, 'e').format("dddd")} {opentime.starttime} {opentime.endtime}</li>)}
                  </ul>
                </Descriptions.Item>
                <Descriptions.Item label="最低租賃時數">
                  {storeData.rentminhours ? `${storeData.rentminhours} 時` : '無'}
                </Descriptions.Item>
                <Descriptions.Item label="承租時間">
                  <PickAndReturnDatePicker value={selectedPeriod} onChange={handleChangeSearchPeriod} />
                </Descriptions.Item>
              </Descriptions>
            </Col>}
            <Col span={24}>
              <h3>車輛清單</h3>
              <List style={{ backgroundColor: 'white' }} bordered>
                {
                  cartItems.map((row, index) => <List.Item key={row.vehiclespec_id} span={24}>
                    <VehicleItem
                      itemData={row}
                      onClick={() => handleCheckItem(index, !row.checked)}
                      onCheck={(evt) => handleCheckItem(index, evt.target.checked)}
                      onDelete={() => handleDelete(index)}
                      onUpdate={(value) => handleChangeQty(index, value)}
                    />
                  </List.Item>)
                }
              </List>
            </Col>
            <Col span={24}>
              <h3>承租備註</h3>
              <Input.TextArea value={orderCart.renterNote} onChange={handleUpdateRenterNote} />
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <h3>總價 ${(total).toLocaleString()}</h3>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button onClick={handleClear}>清空</Button>
                <Button onClick={handleContinue}>繼續選車</Button>
                <Button type="primary" loading={loading} onClick={handleAddOrder}>預訂</Button>
              </Space>
            </Col>
          </Row>
        }
      </Spin>
    </ContentLayout>
  )
}

export default CartListPage