import { Spin } from 'antd';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'

function VehiclePhoto({ onItemVehiclePhotoShow, vehicleId, type, onClick }, ref) {
    const [loading, setLoading] = useState(false);
    const [vehiclePhoto, setVehiclePhoto] = useState();

    let title = "";
    switch (type) {
        case 'front':
            title = "車頭";
            break;
        case 'back':
            title = "車尾";
            break;
        case 'body-left':
            title = "左側";
            break;
        case 'body-right':
            title = "右側";
            break;
        default:
            title = "其他";
    }
    const refresh = useCallback(() => {
        if (!vehicleId || !type) {
            setVehiclePhoto();
            return;
        }
        setLoading(true);
        onItemVehiclePhotoShow(vehicleId, type).then((response) => {
            const result = response.data;
            setVehiclePhoto(result.filedata);
        }).catch((error) => {
            setVehiclePhoto(null);
        }).finally(() => {
            setLoading(false);
        })
    }, [onItemVehiclePhotoShow, vehicleId, type]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        refresh: refresh
    }));
    return (
        <Spin spinning={loading}>
            <div onClick={onClick} style={{
                cursor: 'pointer',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 100,
                background: 'lightgray',
                overflow: 'hidden',
            }}>
                {vehiclePhoto ? <img src={vehiclePhoto} alt={title} style={{ height: '100%', width: '100%', objectFit: 'cover' }} /> : <span>{title}</span>}
            </div>
        </Spin>
    )
}

export default forwardRef(VehiclePhoto)