import { InputNumber, notification } from 'antd'
import React, { useState } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

function RwdInputNumber({ value, onChange, ...props }) {
    const [cValue, setCValue] = useState(value);
    const handleOnChange = (newValue) => {
        console.log("handleOnChange", newValue);
        if (props.max && newValue > props.max) {
            notification.warn({ message: '超出預訂數量限制' });
            return;
        }
        if (props.min && newValue < props.min) {
            notification.warn({ message: '預訂數量不可小於 1' });
            return;
        }
        setCValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    }
    const handleAdd = () => {
        handleOnChange(Number.parseInt(cValue) + 1);
    }
    const handleRemove = () => {
        handleOnChange(Number.parseInt(cValue) - 1);
    }
    return (
        <div style={{ display: 'inline-block', alignItems: 'center' }}>
            <MinusOutlined style={{ marginRight: 5 }} onClick={handleRemove} />
            <InputNumber  {...props} value={cValue} onChange={handleOnChange} />
            <PlusOutlined style={{ marginLeft: 5 }} onClick={handleAdd} />
        </div>
    )
}

export default RwdInputNumber