import { Alert, Button, Col, Form, Input, notification, Radio, Row, Space, Spin, Tabs } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TourniiAPI from '../../api/TourniiAPI'
import CountrySelect from '../../components/CountrySelect'
import LineLoginButton from '../../components/sociallogin/LineLoginButton'
import ContentLayout from '../ContentLayout'

const liff = window.liff;

function UserProfilePage() {
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();
    const user = TourniiAPI.auth.getLoginUser();
    const renterId = user ? user.renter_id : null;
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [renterLoading, setRenterLoading] = useState(false);
    const [foreignNational, setForeignNational] = useState(0);
    const [userDefaultData, setUserDefaultData] = useState({});
    const userForm = useRef();
    const renterForm = useRef();

    const refreshUser = useCallback(() => {
        setLoading(true);
        TourniiAPI.auth.me.show().then((response) => {
            const result = response.data;
            setUserDefaultData(result);
            userForm.current.setFieldsValue(result);
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    const refreshRenter = useCallback(() => {
        if (renterId) {
            setLoading(true);
            TourniiAPI.renter.renters.show(renterId).then((response) => {
                const result = response.data;
                if (renterForm.current) {
                    renterForm.current.setFieldsValue(result);
                    setForeignNational(result.foreignnational);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [renterId]);
    useEffect(() => {
        refreshUser();
        refreshRenter();
    }, [refreshUser, refreshRenter]);

    useEffect(() => {
        const notifyCallback = searchParams.get('notify-callback');
        const success = searchParams.get('success');

        if (notifyCallback) {
            const notifyType = notifyCallback.charAt(0).toUpperCase() + notifyCallback.slice(1);
            if (success) {
                notification.info({ message: `${notifyType} 通知綁定成功` });
            } else {
                notification.warn({ message: `${notifyType} 通知綁定失敗` });
            }
            navigate('/profile');
        }
    }, [navigate, searchParams]);
    const handleChangeTab = (activeKey) => {
        switch (activeKey) {
            case 'user':
                refreshUser(); break;
            case 'renter':
                refreshRenter(); break;
            default: break;
        }
    }
    const handleUpdateUser = (values) => {
        setUserLoading(true);
        TourniiAPI.auth.me.update(values).then((response) => {
            if (values.pendingemail) {
                notification.info({ message: '請至您的電子郵件信箱收取驗證信以修改 E-Mail。' });
            }
            refreshUser();
        }).finally(() => {
            setUserLoading(false);
        });
    };
    const handleProfileReset = () => {
        refreshUser();
        refreshRenter();
    }
    const handleProfileSubmit = () => {
        if (userForm.current) {
            userForm.current.submit();
        }
        if (renterForm.current) {
            renterForm.current.submit();
        }
    }
    const handleUpdateRenter = (values) => {
        if (userForm.current) {
            values.cellphone = userForm.current.getFieldValue('cellphone');
        }
        if (!renterId) {
            setRenterLoading(true);
            TourniiAPI.renter.renters.store(values).then((response) => {
                // notification.info({ message: '新增承租人資料成功' });
                refreshRenter();
                if (searchParams.get('redirect')) {
                    navigate(searchParams.get('redirect'));
                }
            }).finally(() => {
                refreshRenter();
                setRenterLoading(false);
            })
        } else {
            setRenterLoading(true);
            TourniiAPI.renter.renters.update(renterId, values).then((response) => {
                // notification.info({ message: '修改承租人資料成功' });
                refreshRenter();
                if (searchParams.get('redirect')) {
                    navigate(searchParams.get('redirect'));
                }
            }).finally(() => {
                setRenterLoading(false);
            });
        }

    }
    const handleUpdatePassword = (values) => {
        setLoading(true);
        TourniiAPI.auth.me.updatePassword(values).then((response) => {
            notification.info({ message: '修改密碼成功' });
            refreshUser();
        }).finally(() => {
            setLoading(false);
        });
    };


    const handleBindLineLogin = useCallback(() => {
        console.log("handleBindLineLogin");
        if (!TourniiAPI.auth.getLoginUser()) return;
        if (!liff.isLoggedIn()) {
            // 未登入則先登入後再重導回 profile 頁完成綁定
            liff.login({ redirectUri: process.env.REACT_APP_URL + '/profile?bind=line' });
            return;
        }
        TourniiAPI.auth.me.bindLineLogin().then((response) => {
            notification.info({ message: '綁定成功' })
        }).finally(() => {
            refreshUser();
        });
    }, [refreshUser]);

    const handleUnbindLineLogin = useCallback(() => {
        TourniiAPI.auth.me.unbindLineLogin().then((response) => {
            notification.info({ message: '解除綁定成功' })
        }).finally(() => {
            refreshUser();
        });
    }, [refreshUser]);

    const handleBindLineNotify = () => {
        setLoading(true);
        TourniiAPI.auth.me.bindLineNotify().then((response) => {
            const result = response.data;
            console.log(result);
            window.location.replace(result.redirect_uri);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleUnbindLineNotify = () => {
        setLoading(true);
        TourniiAPI.auth.me.unbindLineNotify().then((response) => {
            // const result = response.data;
            notification.info({ message: 'Line 通知解除綁定' });
            refreshUser();
        }).finally(() => {
            setLoading(false);
        });
    }
    useEffect(() => {
        const bind = searchParams.get('bind');
        if (bind === 'line') {
            handleBindLineLogin();
        }
    }, [searchParams, handleBindLineLogin]);

    const handleResendPendingEmail = () => {
        TourniiAPI.auth.me.resendPendingEmail().then((response) => {
            notification.info({ message: '已重發驗證信' });
        }).finally(() => {

        });
    };
    const handleCancelPendingEmail = () => {
        TourniiAPI.auth.me.cancelPendingEmail().then((response) => {
            notification.info({ message: '已取消驗證' });
        }).finally(() => {
            refreshUser();
        })
    };
    return (
        <ContentLayout title="基本資料">
            <Spin spinning={loading}>
                <Tabs defaultActiveKey="user" onChange={handleChangeTab} size="large">
                    <Tabs.TabPane tab="基本資料" key="user">
                        <Spin spinning={userLoading}>
                            <Form ref={userForm} onFinish={handleUpdateUser} labelCol={{ span: 6 }}>
                                <Form.Item label="已驗證 E-Mail" shouldUpdate>
                                    {({ getFieldValue }) => getFieldValue('email')}
                                </Form.Item>
                                <Form.Item label="修改 E-Mail">
                                    <Row gutter={[10, 10]}>
                                        <Col xs={24} md={16}>
                                            <Form.Item name="pendingemail" style={{ marginBottom: 0 }}>
                                                <Input allowClear />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Space>
                                                <Button disabled={!userDefaultData.pendingemail} onClick={handleResendPendingEmail}>重發</Button>
                                                <Button disabled={!userDefaultData.pendingemail} onClick={handleCancelPendingEmail} danger>取消</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="名稱" name="name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="手機" name="cellphone" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="登入綁定" shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const provider = getFieldValue('provider');
                                        if (!provider) {
                                            return <LineLoginButton onClick={handleBindLineLogin}>綁定 Line 帳號</LineLoginButton>;
                                        }
                                        return <LineLoginButton onClick={handleUnbindLineLogin}>解除綁定 Line 帳號</LineLoginButton>;
                                    }}
                                </Form.Item>
                                <Form.Item label="通知" shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const notifyLineEnabled = getFieldValue('notifylineenabled');
                                        if (!notifyLineEnabled) {
                                            return <Button type="primary" onClick={handleBindLineNotify}>綁定 Line 通知</Button>;
                                        }
                                        return <Button type="primary" onClick={handleUnbindLineNotify}>解除綁定 Line 通知</Button>;
                                    }}
                                </Form.Item>
                            </Form>
                        </Spin>
                        <Spin spinning={renterLoading}>
                            <Form ref={renterForm} onFinish={handleUpdateRenter} labelCol={{ span: 6 }} initialValues={{ foreignnational: 0 }}>
                                <Form.Item label="真實姓名" name="name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="國內/國外" name="foreignnational">
                                    <Radio.Group buttonStyle="solid" onChange={(e) => setForeignNational(e.target.value)}>
                                        <Radio.Button value={0}>國內</Radio.Button>
                                        <Radio.Button value={1}>國外</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="國藉" name="idcountry" hidden={foreignNational === 0}>
                                    <CountrySelect />
                                </Form.Item>
                                <Form.Item label="身分證/居留證號" name="idnumber" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="手機" name="cellphone" hidden={true}>
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Spin>
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Button onClick={handleProfileReset}>重設 </Button>
                                <Button type="primary" loading={userLoading || renterLoading} onClick={handleProfileSubmit}>修改</Button>
                            </Space>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="修改密碼" key="password">
                        {user.nopassword && <Alert style={{ marginBottom: 20 }} message="請設定密碼以使用 Email 登入" type="warning" />}
                        <Form onFinish={handleUpdatePassword} labelCol={{ span: 4 }}>
                            <Form.Item label="舊密碼" name="old_password" hidden={user.nopassword}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="密碼" name="password">
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="確認密碼" name="password_confirmation">
                                <Input.Password />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit">修改</Button>
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>

            </Spin>
        </ContentLayout>
    )
}

export default UserProfilePage