import { notification } from "antd";
import axios from "axios";
import PermissionManager from "../PermissionManager";

const liff = window.liff; // Import from public/index.html

axios.defaults.baseURL = 'http://localhost:8083/api';
if (process.env.REACT_APP_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_URL + '/api';
}

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


// 訂單狀態：new=已預訂、deposit-paid=已付款、picked-up=已取車、completed=已完成、canceled=已取消
export const ORDERSTATE_NEW = 'new';
export const ORDERSTATE_DEPOSIT_PAID = 'deposit-paid';
export const ORDERSTATE_PICKEDUP = 'picked-up';
export const ORDERSTATE_COMPLETED = 'completed';
export const ORDERSTATE_CANCELED = 'canceled';
export const OrderStates = [
    {
        id: ORDERSTATE_NEW,
        name: '已預訂',
        //color: '#f5222d',
        color: '#999b30',
    },
    {
        id: ORDERSTATE_DEPOSIT_PAID,
        name: '已付款',
        //color: '#fadb14',
        color: '#999b30',
    },
    {
        id: ORDERSTATE_PICKEDUP,
        name: '已取車',
        //color: '#fa8c16',
        color: '#999b30',
    },
    {
        id: ORDERSTATE_COMPLETED,
        name: '已完成',
        //color: '#52c41a',
        color: '#999b30',
    },
    {
        id: ORDERSTATE_CANCELED,
        name: '已取消',
        //color: '#d9d9d9',
        color: '#999b30',
    },
];

// 付款狀態：結清狀態：unsettled=未結清, settled=已結清
export const ORDERSETTLEDSTATE_UNSETTLED = 'unsettled';
export const ORDERSETTLEDSTATE_SETTLED = 'settled';
export const OrderSettledStates = [
    {
        id: ORDERSETTLEDSTATE_UNSETTLED,
        name: '未結清',
        color: 'warning',
    },
    {
        id: ORDERSETTLEDSTATE_SETTLED,
        name: '已結清',
        color: 'success',
    },
]

export const VEHICLESTATE_NEW = "new";
export const VEHICLESTATE_PICKUPED = "pickuped";
export const VEHICLESTATE_RETURNED = "returned";
export const VehicleStates = [
    {
        id: VEHICLESTATE_NEW,
        name: '未取車',
        color: 'default',
    },
    {
        id: VEHICLESTATE_PICKUPED,
        name: '已取車',
        color: 'volcano',
    },
    {
        id: VEHICLESTATE_RETURNED,
        name: '已還車',
        color: 'green',
    },
]
const defaultPageSize = 12;
export default class TourniiAPI {
    // Add a response interceptor
    static responseFulfilledInterceptors = (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    };
    static responseRejectInterceptors = (error, navigate) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
            notification.warn({ message: '登入憑証過期，請重新登入' });
            TourniiAPI.auth.clearLoginData();
            navigate('/');
            return;
        }
        if (error.response && error.response.data) {
            const errorResponse = error.response.data;
            //console.log(errorResponse);
            // Validation errors.
            if (typeof errorResponse.errors === "object") {
                for (let key in errorResponse.errors) {
                    notification.error({
                        title: 'Error',
                        message: errorResponse.errors[key]
                    });
                }
                return Promise.reject(error);
            }
            if (errorResponse.message) {
                notification.error({
                    title: 'Error',
                    message: errorResponse.message
                });
            }
        }
        return Promise.reject(error);
    }

    /**
     * 將 Object 轉為 FormData
     * @param {*} data 
     * @returns FormData 
     */
    static toFormData = (data) => {
        var formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key] !== undefined ? data[key] : '');
        }
        return formData;
    }
    static sortDirection = (direction) => {
        if (direction === 'ascend') return 'asc';
        if (direction === 'descend') return 'desc';
    }
    static getImageHostUrl = (path) => {
        return (process.env.REACT_APP_IMAGE_URL ? process.env.REACT_APP_IMAGE_URL : '') + path
    }
    static dataUrlToFile = (dataUrl) => {
        const [contentType, blobBase64] = dataUrl.split(',')
        const blobBin = atob(blobBase64);
        const array = []
        for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i))
        }
        const file = new Blob([new Uint8Array(array)], { type: contentType })
        return file;
    }
    static auth = class {
        _appLoginedCallback = null; // 登入完成要觸發的事件，通常是 App 元件，讓 App 能夠更新元件登入狀態
        _tokenData = null; // 儲存 token
        _userData = null; // 儲存使用者資料
        static getLoginUser = () => {
            return this._userData;
        };
        static init = (appLoginedCallback) => {
            this._appLoginedCallback = appLoginedCallback;
        }

        static _afterLogin = (response, saveToken) => {
            const token = response.data.token;
            this._tokenData = token;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            saveToken = true; // Force save
            if (saveToken) {
                localStorage.setItem('token', token);
            }
            return this.refreshPermission();
        }
        static refreshPermission = () => {
            return this.me.show().then((response) => {
                PermissionManager.updateRoles(response.data.roles);
                this._appLoginedCallback(true);
            });
        }
        static login = (email, password, remember, type, storeId) => {
            let data = new FormData();
            let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
            const detectBrowser = () => {
                if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
                    return 'Opera';
                } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
                    return 'Chrome';
                } else if (navigator.userAgent.indexOf("Safari") !== -1) {
                    return 'Safari';
                } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
                    return 'Firefox';
                } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
                    return 'IE';//crap
                } else {
                    return 'Unknown';
                }
            }
            data.append('email', email);
            data.append('password', password);
            data.append('device_name', [platform, detectBrowser()].join('-'));
            if (type === "store") {
                data.append('logintype', type);
                data.append('store_id', storeId);
            }
            return axios.post(`/auth/login`, data).then((response) => {
                return this._afterLogin(response, remember);
            });

        }
        static loginWithToken = async () => {
            if (this.getLoginUser()) return;
            const savedToken = localStorage.getItem('token');
            //console.log("loginWithToken", savedToken);
            if (savedToken) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + savedToken;
                await this.me.show().then((response) => {
                    this._tokenData = savedToken;
                    PermissionManager.updateRoles(response.data.roles);
                    this._appLoginedCallback(true);
                }).catch((error) => {
                    localStorage.removeItem('token');
                });
            }
        }
        static lineLogin = ({ redirect }) => {
            if (!liff.isLoggedIn()) {
                const redirectUri = process.env.REACT_APP_URL + (redirect ? redirect : '/');
                console.log(redirectUri)
                liff.login({ redirectUri: redirectUri });
            }
        }
        static lineLogout = () => {
            if (liff.isLoggedIn()) {
                liff.logout();
            }
        }
        static verifyLineLogin = () => {
            if (!liff.isLoggedIn()) {
                console.warn("TourniiAPI.auth.verifyLineLogin: liff is not logged in")
                return new Promise((resolve, reject) => { reject({ message: 'liff is not logged in' }); });
            }
            const accessToken = liff.getAccessToken();
            const idToken = liff.getDecodedIDToken();
            console.log("verifyLineLogin: LIFF: idToken", idToken);
            console.log("verifyLineLogin: LIFF: User email", idToken.email);
            return axios.post(`/auth/login/line`, {
                email: idToken.email,
                token: accessToken
            }).then((response) => {
                return this._afterLogin(response, true);
            });
        }
        static clearLoginData = () => {
            if (!this._tokenData && !this._userData) return;
            this._tokenData = null;
            this._userData = null;
            localStorage.removeItem('token');
            this._appLoginedCallback(false);
            PermissionManager.updateRoles([]);
            this.lineLogout();
        }
        static logout = () => {
            if (!this._tokenData && !this._userData) return;
            this.clearLoginData();
            return axios.post(`/auth/logout`);
        }
        static me = class {
            static show = () => {
                return axios.get(`/auth/me`).then((response) => {
                    TourniiAPI.auth._userData = response.data;
                    //console.log(this._userData);
                    return response;
                });
            }
            static update = (data) => {
                return axios.patch(`/auth/me`, data);
            }
            static updatePassword = (data) => {
                return axios.post(`/auth/me/password`, data);
            }
            static bindLineLogin = (data) => {
                if (!liff.isLoggedIn()) {
                    console.warn("TourniiAPI.auth.bindLineLogin: liff is not logged in")
                    return new Promise((resolve, reject) => { reject({ message: 'liff is not logged in' }); });
                }
                const accessToken = liff.getAccessToken();
                return axios.post(`/auth/me/combine-line`, { token: accessToken });
            }
            static unbindLineLogin = () => {
                return axios.delete(`/auth/me/combine-line`).then((response) => {
                    TourniiAPI.auth.lineLogout();
                    return response;
                });
            }
            static bindLineNotify = () => {
                return axios.get(`/auth/me/line-notify`);
            }
            static unbindLineNotify = () => {
                return axios.delete(`/auth/me/line-notify`);
            }
            static cancelPendingEmail = () => {
                return axios.delete(`/auth/me/pending-email`);
            }
            static resendPendingEmail = () => {
                return axios.post(`/auth/me/pending-email`);
            }
            static storeMemberAccept = (code) => {
                return axios.post(`/auth/me/store-member-invitation`, { invitecode: code });
            };
        }
        static signup = (data) => {
            return axios.post(`/auth/signup`, data);
        }
        static forgot = (data) => {
            return axios.post(`/auth/forgot`, data);
        }
        static resetPassword = (data) => {
            return axios.post(`/auth/reset-password`, data);
        }

    }
    static admin = class {
        static users = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/admin/users`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };

            static show = (id) => axios.get(`/admin/users/${id}`);
            static store = (data) => axios.post(`/admin/users`, data);
            static update = (id, data) => axios.patch(`/admin/users/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/users/${id}`);
        }
        static companies = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/admin/companies`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };

            static show = (id) => axios.get(`/admin/companies/${id}`);
            static store = (data) => axios.post(`/admin/companies`, data);
            static update = (id, data) => axios.patch(`/admin/companies/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/companies/${id}`);
        }
        static stores = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/admin/stores`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };

            static show = (id) => axios.get(`/admin/stores/${id}`);
            static store = (data) => axios.post(`/admin/stores`, data);
            static update = (id, data) => axios.patch(`/admin/stores/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/stores/${id}`);

            static members = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/admin/stores/${storeId}/members`, {
                        params: {
                            ...query,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/admin/stores/${storeId}/members/${id}`);
                static store = (storeId, data) => axios.post(`/admin/stores/${storeId}/members`, data);
                static update = (storeId, id, data) => axios.patch(`/admin/stores/${storeId}/members/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/admin/stores/${storeId}/members/${id}`);
            }
        }
        static orders = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/admin/orders`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };

            static show = (id) => axios.get(`/admin/orders/${id}`);
            static store = (data) => axios.post(`/admin/orders`, data);
            static update = (id, data) => axios.patch(`/admin/orders/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/orders/${id}`);
            static refund = (id, data) => axios.post(`/admin/orders/${id}/refund`, data);

            static import = (data) => axios.post(`/admin/orders/import`, TourniiAPI.toFormData(data));
            static export = (query) => axios.get(`/admin/orders/export`, {
                responseType: 'blob',
                params: {
                    ...query,
                }
            });

            static paymentlogs = class {
                static index = (orderId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/admin/orders/${orderId}/paymentlogs`, {
                        params: {
                            ...query,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };
            }
        }
        static reports = class {
            static storeSales = (query) => axios.get(`/admin/report/store-sales`, {
                params: {
                    ...query
                }
            });
            static storeSalesExport = (query) => axios.get(`/admin/report/store-sales/export`, {
                responseType: 'blob',
                params: {
                    ...query
                }
            });
            static storeBills = (storeId, query) => axios.get(`/admin/report/store-bills/${storeId}`, {
                params: {
                    ...query
                }
            });
            static storeBillsExport = (storeId, query) => axios.get(`/admin/report/store-bills/${storeId}/export`, {
                responseType: 'blob',
                params: {
                    ...query
                }
            });
        }

    }
    static store = class {
        static stores = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/store/stores`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };
            static show = (id) => axios.get(`/store/stores/${id}`);
            static store = (data) => axios.post(`/store/stores`, data);
            static update = (id, data) => axios.patch(`/store/stores/${id}`, data);
            static destroy = (id) => axios.delete(`/store/stores/${id}`);

            static orders = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/orders`, {
                        params: {
                            ...query,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/orders/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/orders`, data);
                static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/orders/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/orders/${id}`);

                static pay = (storeId, id, data) => axios.post(`/store/stores/${storeId}/orders/${id}/pay`, data);
                static refund = (storeId, id, data) => axios.post(`/store/stores/${storeId}/orders/${id}/refund`, data);
                static pickup = (storeId, id, data) => axios.post(`/store/stores/${storeId}/orders/${id}/pick-up`, data);
                static complete = (storeId, id, data) => axios.post(`/store/stores/${storeId}/orders/${id}/complete`, data);
                static undo = (storeId, id, data) => axios.post(`/store/stores/${storeId}/orders/${id}/undo`, data);
                static users = (storeId, query) => axios.get(`/store/stores/${storeId}/orders/users`, {
                    params: {
                        ...query,
                    }
                })

                static export = (storeId, query) => axios.get(`/store/stores/${storeId}/orders/export`, {
                    responseType: 'blob',
                    params: {
                        ...query,
                    }
                });

                static vehicleSpecInv = class {
                    static index = (storeId, query, page, pageSize, sort, direction) => {
                        if (!query) query = {};
                        return axios.get(`/store/stores/${storeId}/orders/vehicle-spec-inv`, {
                            params: {
                                ...query,
                                id: query.id,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                                sort: sort,
                                direction: TourniiAPI.sortDirection(direction),
                            },
                        });
                    };

                    static show = (storeId, id) => axios.get(`/store/stores/${storeId}/orders/vehicle-spec-inv/${id}`);
                }
                static items = class {
                    static index = (storeId, orderId, query, page, pageSize, sort, direction) => {
                        if (!query) query = {};
                        return axios.get(`/store/stores/${storeId}/orders/${orderId}/items`, {
                            params: {
                                id: query.id,
                                q: query.name,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                                sort: sort,
                                direction: TourniiAPI.sortDirection(direction),
                            },
                        });
                    };
                    static show = (storeId, orderId, id) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${id}`);

                    // static show = (storeId, orderId, id) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${id}`);
                    // static store = (storeId, orderId, data) => axios.post(`/store/stores/${storeId}/orders/${orderId}/items`, data);
                    // static update = (storeId, orderId, id, data) => axios.patch(`/store/stores/${storeId}/orders/${orderId}/items/${id}`, data);
                    // static destroy = (storeId, orderId, id) => axios.delete(`/store/stores/${storeId}/orders/${orderId}/items/${id}`);

                    static vehicles = class {
                        static index = (storeId, orderId, itemId, query, page, pageSize, sort, direction) => {
                            if (!query) query = {};
                            return axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles`, {
                                params: {
                                    id: query.id,
                                    q: query.name,
                                    page: page || 1,
                                    count: pageSize || defaultPageSize,
                                    sort: sort,
                                    direction: TourniiAPI.sortDirection(direction),
                                },
                            });
                        };
                        static show = (storeId, orderId, itemId, id) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${id}`);
                        static store = (storeId, orderId, itemId, data) => axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles`, data);
                        static update = (storeId, orderId, itemId, id, data) => axios.patch(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${id}`, data);
                        static destroy = (storeId, orderId, itemId, id) => axios.delete(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${id}`);

                        static pickup = (storeId, orderId, itemId, id, data) => axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${id}/pick-up`, data);
                        static return = (storeId, orderId, itemId, id, data) => axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${id}/return`, data);
                        static undoReturn = (storeId, orderId, itemId, id, data) => axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${id}/undo`, data);

                        static credentials = class {
                            static index = (storeId, orderId, itemId, vehicleId, query, page, pageSize, sort, direction) => {
                                if (!query) query = {};
                                return axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials`, {
                                    params: {
                                        id: query.id,
                                        q: query.name,
                                        page: page || 1,
                                        count: pageSize || defaultPageSize,
                                        sort: sort,
                                        direction: TourniiAPI.sortDirection(direction),
                                    },
                                });
                            };

                            static show = (storeId, orderId, itemId, vehicleId, type, side) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials/${type}`, {
                                params: {
                                    side: side
                                }
                            });
                            static store = (storeId, orderId, itemId, vehicleId, type, side, data) => {
                                const newData = {
                                    type: type,
                                    side: side,
                                    ...data
                                }
                                return axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials`, TourniiAPI.toFormData(newData));
                            };
                            static destroy = (storeId, orderId, itemId, vehicleId, type, side) => axios.delete(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials/${type}`, {
                                params: {
                                    side: side
                                }
                            });
                        }
                        static photos = class {
                            static index = (storeId, orderId, itemId, vehicleId, query, page, pageSize, sort, direction) => {
                                if (!query) query = {};
                                return axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos`, {
                                    params: {
                                        id: query.id,
                                        q: query.name,
                                        page: page || 1,
                                        count: pageSize || defaultPageSize,
                                        sort: sort,
                                        direction: TourniiAPI.sortDirection(direction),
                                    },
                                });
                            };

                            static show = (storeId, orderId, itemId, vehicleId, type) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos/${type}`);
                            static store = (storeId, orderId, itemId, vehicleId, type, data) => {
                                const newData = {
                                    type: type,
                                    ...data
                                }
                                return axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos`, TourniiAPI.toFormData(newData));
                            };
                            static destroy = (storeId, orderId, itemId, vehicleId, type) => axios.delete(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos/${type}`);
                        }
                        static odometer = class {
                            static index = (storeId, orderId, itemId, vehicleId, query, page, pageSize, sort, direction) => {
                                if (!query) query = {};
                                return axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer`, {
                                    params: {
                                        id: query.id,
                                        q: query.name,
                                        page: page || 1,
                                        count: pageSize || defaultPageSize,
                                        sort: sort,
                                        direction: TourniiAPI.sortDirection(direction),
                                    },
                                });
                            };

                            static show = (storeId, orderId, itemId, vehicleId, type) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer/${type}`);
                            static store = (storeId, orderId, itemId, vehicleId, type, data) => {
                                const newData = {
                                    type: type,
                                    ...data,
                                }
                                return axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer`, TourniiAPI.toFormData(newData));
                            };
                            static destroy = (storeId, orderId, itemId, vehicleId, type) => axios.delete(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer/${type}`);
                        }
                        static signature = class {
                            static show = (storeId, orderId, itemId, vehicleId) => axios.get(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/signature?size=original`);
                            static store = (storeId, orderId, itemId, vehicleId, data) => axios.post(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/signature`, TourniiAPI.toFormData(data));
                            static destroy = (storeId, orderId, itemId, vehicleId) => axios.delete(`/store/stores/${storeId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/signature`);
                        }
                    }
                }
                static paymentlogs = class {
                    static index = (storeId, orderId, query, page, pageSize, sort, direction) => {
                        if (!query) query = {};
                        return axios.get(`/store/stores/${storeId}/orders/${orderId}/paymentlogs`, {
                            params: {
                                ...query,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                                sort: sort,
                                direction: TourniiAPI.sortDirection(direction),
                            },
                        });
                    };
                }
            }
            static dateType = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/date-type`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/date-type/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/date-type`, data);
                static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/date-type/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/date-type/${id}`);
            }
            static date = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/date`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/date/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/date`, data);
                static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/date/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/date/${id}`);

                static calendar = (storeId, query) => axios.get(`/store/stores/${storeId}/date/calendar`, {
                    params: {
                        ...query
                    }
                });

            }
            static specPricing = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/spec-pricing`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/spec-pricing/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/spec-pricing`, data);
                static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/spec-pricing/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/spec-pricing/${id}`);
            }
            static vehicleSpecInv = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/vehicle-spec-inv`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/vehicle-spec-inv/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/vehicle-spec-inv`, data);
                static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/vehicle-spec-inv/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/vehicle-spec-inv/${id}`);
            }
            static openTimes = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/opentimes`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/opentimes/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/opentimes`, data);
                static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/opentimes/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/opentimes/${id}`);
            }
            static listing = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/listing`, {
                        params: {
                            id: query.id,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                            ...query,
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/listing/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/listing`, data);
                // Deprecated
                //static update = (storeId, id, data) => axios.patch(`/store/stores/${storeId}/listing/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/listing/${id}`);
            }
            static report = class {
                static vehicleSpecInv = (storeId, query) => axios.get(`/store/stores/${storeId}/report/vehicle-spec-inv`, {
                    params: {
                        ...query
                    },
                });
            }
            static contracts = class {
                static index = (storeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/store/stores/${storeId}/contracts`, {
                        params: {
                            id: query.id,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                            ...query,
                        },
                    });
                };
                static latest = (storeId) => axios.get(`/store/stores/${storeId}/contracts/latest`);
                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/contracts/${id}`);
                static store = (storeId, data) => axios.post(`/store/stores/${storeId}/contracts`, data);
                static destroy = (storeId, id) => axios.delete(`/store/stores/${storeId}/contracts/${id}`);
            }
        }
        static orders = class {
            static show = (id) => axios.get(`/store/orders/${id}`);
        }
    }
    static renter = class {
        static renters = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/renter/renters`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };

            static show = (id) => axios.get(`/renter/renters/${id}`);
            static store = (data) => axios.post(`/renter/renters`, data).then(async (response) => {
                // Update login data
                await TourniiAPI.auth.me.show();
                return response;
            });
            static update = (id, data) => axios.patch(`/renter/renters/${id}`, data);
            static destroy = (id) => axios.delete(`/renter/renters/${id}`);

            static orders = class {
                static index = (renterId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/renter/renters/${renterId}/orders`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                };

                static show = (renterId, id) => axios.get(`/renter/renters/${renterId}/orders/${id}`);
                static store = (renterId, data) => axios.post(`/renter/renters/${renterId}/orders`, data);
                static update = (renterId, id, data) => axios.patch(`/renter/renters/${renterId}/orders/${id}`, data);
                static destroy = (renterId, id) => axios.delete(`/renter/renters/${renterId}/orders/${id}`);

                static pay = class {
                    static tapPay = (renterId, id, data) => axios.post(`/renter/renters/${renterId}/orders/${id}/pay/tap-pay`, data);
                }
                static items = class {
                    static index = (renterId, orderId, query, page, pageSize, sort, direction) => {
                        if (!query) query = {};
                        return axios.get(`/renter/renters/${renterId}/orders/${orderId}/items`, {
                            params: {
                                id: query.id,
                                q: query.name,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                                sort: sort,
                                direction: TourniiAPI.sortDirection(direction),
                            },
                        });
                    };
                    static show = (renterId, orderId, id) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${id}`);

                    // static show = (renterId, orderId, id) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${id}`);
                    // static store = (renterId, orderId, data) => axios.post(`/renter/renters/${renterId}/orders/${orderId}/items`, data);
                    // static update = (renterId, orderId, id, data) => axios.patch(`/renter/renters/${renterId}/orders/${orderId}/items/${id}`, data);
                    // static destroy = (renterId, orderId, id) => axios.delete(`/renter/renters/${renterId}/orders/${orderId}/items/${id}`);

                    static vehicles = class {
                        static index = (renterId, orderId, itemId, query, page, pageSize, sort, direction) => {
                            if (!query) query = {};
                            return axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles`, {
                                params: {
                                    id: query.id,
                                    q: query.name,
                                    page: page || 1,
                                    count: pageSize || defaultPageSize,
                                    sort: sort,
                                    direction: TourniiAPI.sortDirection(direction),
                                },
                            });
                        };
                        static show = (renterId, orderId, itemId, id) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${id}`);
                        static store = (renterId, orderId, itemId, data) => axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles`, data);
                        static update = (renterId, orderId, itemId, id, data) => axios.patch(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${id}`, data);
                        static destroy = (renterId, orderId, itemId, id) => axios.delete(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${id}`);

                        static pickup = (renterId, orderId, itemId, id, data) => axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${id}/pick-up`, data);
                        static return = (renterId, orderId, itemId, id, data) => axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${id}/return`, data);


                        static credentials = class {
                            static index = (renterId, orderId, itemId, vehicleId, query, page, pageSize, sort, direction) => {
                                if (!query) query = {};
                                return axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials`, {
                                    params: {
                                        id: query.id,
                                        q: query.name,
                                        page: page || 1,
                                        count: pageSize || defaultPageSize,
                                        sort: sort,
                                        direction: TourniiAPI.sortDirection(direction),
                                    },
                                });
                            };

                            static show = (renterId, orderId, itemId, vehicleId, type, side) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials/${type}`, {
                                params: {
                                    side: side
                                }
                            });
                            static store = (renterId, orderId, itemId, vehicleId, type, side, data) => {
                                const newData = {
                                    type: type,
                                    side: side,
                                    ...data
                                }
                                return axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials`, TourniiAPI.toFormData(newData));
                            };
                            static destroy = (renterId, orderId, itemId, vehicleId, type, side) => axios.delete(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/credentials/${type}`, {
                                params: {
                                    side: side
                                }
                            });
                        }
                        static photos = class {
                            static index = (renterId, orderId, itemId, vehicleId, query, page, pageSize, sort, direction) => {
                                if (!query) query = {};
                                return axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos`, {
                                    params: {
                                        id: query.id,
                                        q: query.name,
                                        page: page || 1,
                                        count: pageSize || defaultPageSize,
                                        sort: sort,
                                        direction: TourniiAPI.sortDirection(direction),
                                    },
                                });
                            };

                            static show = (renterId, orderId, itemId, vehicleId, type) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos/${type}`);
                            static store = (renterId, orderId, itemId, vehicleId, type, data) => {
                                const newData = {
                                    type: type,
                                    ...data
                                }
                                return axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos`, TourniiAPI.toFormData(newData));
                            };
                            static destroy = (renterId, orderId, itemId, vehicleId, type) => axios.delete(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/photos/${type}`);
                        }
                        static odometer = class {
                            static index = (renterId, orderId, itemId, vehicleId, query, page, pageSize, sort, direction) => {
                                if (!query) query = {};
                                return axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer`, {
                                    params: {
                                        id: query.id,
                                        q: query.name,
                                        page: page || 1,
                                        count: pageSize || defaultPageSize,
                                        sort: sort,
                                        direction: TourniiAPI.sortDirection(direction),
                                    },
                                });
                            };

                            static show = (renterId, orderId, itemId, vehicleId, type) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer/${type}`);
                            static store = (renterId, orderId, itemId, vehicleId, type, data) => {
                                const newData = {
                                    type: type,
                                    ...data,
                                }
                                return axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer`, TourniiAPI.toFormData(newData));
                            };
                            static destroy = (renterId, orderId, itemId, vehicleId, type) => axios.delete(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/odometer/${type}`);
                        }
                        static signature = class {
                            static show = (renterId, orderId, itemId, vehicleId) => axios.get(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/signature?size=original`);
                            static store = (renterId, orderId, itemId, vehicleId, data) => axios.post(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/signature`, TourniiAPI.toFormData(data));
                            static destroy = (renterId, orderId, itemId, vehicleId) => axios.delete(`/renter/renters/${renterId}/orders/${orderId}/items/${itemId}/vehicles/${vehicleId}/signature`);
                        }
                    }
                }
            }

        }

        static credentials = class {
            static index = (renterId, query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/renter/renters/${renterId}/credentials`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            };

            static show = (renterId, type, side) => axios.get(`/renter/renters/${renterId}/credentials/${type}`, {
                params: {
                    size: 'original',
                    side: side,
                }
            });
            static store = (renterId, type, side, data) => {
                const newData = {
                    ...data,
                    type: type,
                    side: side
                }
                return axios.post(`/renter/renters/${renterId}/credentials`, TourniiAPI.toFormData(newData));
            }
            static destroy = (renterId, type, side) => axios.delete(`/renter/renters/${renterId}/credentials/${type}`, {
                params: {
                    side: side,
                }
            });
        };
        static signature = class {
            static show = (renterId) => axios.get(`/renter/renters/${renterId}/signature`, {
                params: {
                    size: 'original',
                }
            });
            static store = (renterId, data) => {
                const newData = {
                    ...data,
                }
                return axios.post(`/renter/renters/${renterId}/signature`, TourniiAPI.toFormData(newData));
            }
            static destroy = (renterId) => axios.delete(`/renter/renters/${renterId}/signature`, {
                params: {
                }
            });
        }
        static stores = class {
            static contracts = class {
                // static index = (storeId, query, page, pageSize, sort, direction) => {
                //     if (!query) query = {};
                //     return axios.get(`/renter/stores/${storeId}/contracts`, {
                //         params: {
                //             id: query.id,
                //             page: page || 1,
                //             count: pageSize || defaultPageSize,
                //             sort: sort,
                //             direction: TourniiAPI.sortDirection(direction),
                //             ...query,
                //         },
                //     });
                // };
                static latest = (storeId) => axios.get(`/renter/stores/${storeId}/contracts/latest`);
                // static show = (storeId, id) => axios.get(`/renter/stores/${storeId}/contracts/${id}`);
                // static store = (storeId, data) => axios.post(`/renter/stores/${storeId}/contracts`, data);
                // static destroy = (storeId, id) => axios.delete(`/renter/stores/${storeId}/contracts/${id}`);
            }
        }
    }
    static regions = class {
        static index = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/regions`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: TourniiAPI.sortDirection(direction),
                },
            });
        };

        static show = (id) => axios.get(`/regions/${id}`);
        static store = (data) => axios.post(`/regions`, data);
        static update = (id, data) => axios.patch(`/regions/${id}`, data);
        static destroy = (id) => axios.delete(`/regions/${id}`);
    }
    static vehicleBrands = class {
        static index = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/vehicle-brands`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: TourniiAPI.sortDirection(direction),
                },
            });
        }
        static types = class {
            static index = (vehicleBrandId, query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/vehicle-brands/${vehicleBrandId}/types`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            }
            static specs = class {
                static index = (vehicleBrandId, typeId, query, page, pageSize, sort, direction) => {
                    if (!query) query = {};
                    return axios.get(`/vehicle-brands/${vehicleBrandId}/types/${typeId}/specs`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                            sort: sort,
                            direction: TourniiAPI.sortDirection(direction),
                        },
                    });
                }
            }
        }
    }
    static public = class {
        static countries = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/countries`, {
                params: {
                    ...query,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: TourniiAPI.sortDirection(direction),
                },
            });
        }
        static listingVehicles = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/listing-vehicles`, {
                    params: {
                        ...query,
                        id: query.id,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            }
            static show = (id, params) => axios.get(`/listing-vehicles/${id}`, {
                params: {
                    ...params
                }
            });
        }
        static places = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/places`, {
                params: {
                    ...query,
                    id: query.id,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: TourniiAPI.sortDirection(direction),
                },
            });
        }
        static regions = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/regions`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: TourniiAPI.sortDirection(direction),
                },
            });
        }
        static stores = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/stores`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: TourniiAPI.sortDirection(direction),
                    },
                });
            }
            static show = (id) => axios.get(`/stores/${id}`);
        }
    }

}
