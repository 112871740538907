import { Button, Col, Drawer, Form, InputNumber, notification, Popconfirm, Row, Space, Spin, Table, Typography } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import VehicleBrandSelect from '../../components/VehicleBrandSelect';
import VehicleSpecSelect from '../../components/VehicleSpecSelect';
import VehicleTypeSelect from '../../components/VehicleTypeSelect';
import ContentLayout from '../ContentLayout'

function VehiclesSpecPage() {
    const [storeId, setStoreId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vehicleBrandId, setVehicleBrandId] = useState();
    const [vehicleTypeId, setVehicleTypeId] = useState();
    const addForm = useRef();
    const [detail, setDetail] = useState([]);
    const [editModalMode, setEditModalMode] = useState('store');
    const [editModalId, setEditModalId] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);

    const refresh = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.vehicleSpecInv.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            console.log(result);
            setDetail(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [storeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleSelectStore = (value) => {
        setStoreId(value);
    }
    const handleShowAddDrawer = () => {
        setEditModalMode('store');
        setEditModalVisible(true);
    }
    const handleShowUpdateDrawer = (id) => {
        setEditModalMode('update');
        setEditModalId(id);
        setEditModalVisible(true);
        setEditModalLoading(true);
        TourniiAPI.store.stores.vehicleSpecInv.show(storeId, id).then((response) => {
            const result = response.data;
            setVehicleBrandId(result.vehiclespec.vehicletype.brand.id);
            setVehicleTypeId(result.vehiclespec.vehicletype.id);
            console.log(vehicleBrandId, vehicleTypeId);
            addForm.current.setFieldsValue(result);
        }).finally(() => {
            setEditModalLoading(false);
        });
    }
    const handleHideDrawer = () => {
        setEditModalMode('store');
        setEditModalId(null);
        setEditModalVisible(false);
    }
    const handleFinish = (values) => {
        console.log(values);
        if (editModalMode === 'store') {
            setEditModalLoading(true);
            TourniiAPI.store.stores.vehicleSpecInv.store(storeId, values).then((response) => {
                notification.info({ message: '新增車型成功' });
            }).finally(() => {
                setEditModalLoading(false);
                setEditModalVisible(false);
                refresh();
            });
        } else {
            setEditModalLoading(true);
            TourniiAPI.store.stores.vehicleSpecInv.update(storeId, editModalId, values).then((response) => {
                notification.info({ message: '修改車型成功' });
            }).finally(() => {
                setEditModalLoading(false);
                setEditModalVisible(false);
                refresh();
            });
        }

    }
    const handleDelete = (id) => {
        setLoading(true);
        TourniiAPI.store.stores.vehicleSpecInv.destroy(storeId, id).then((response) => {
            notification.info({ message: '刪除車型成功' });
        }).finally(() => {
            refresh();
        });
    }
    const columns = [
        {
            title: '品牌',
            dataIndex: 'vehiclespec_id',
            key: 'vehiclespec_id',
            render: (value, record) => record.vehiclespec.vehicletype.brand.name
        },
        {
            title: '車款',
            dataIndex: 'vehiclespec_id',
            key: 'vehiclespec_id',
            render: (value, record) => record.vehiclespec.vehicletype.name
        },
        {
            title: '車型',
            dataIndex: 'vehiclespec_id',
            key: 'vehiclespec_id',
            render: (value, record) => record.vehiclespec.name
        },
        {
            title: '數量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '動作',
            // fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowUpdateDrawer(record.id)}>修改</Typography.Link>
                <Popconfirm title="您確定是否刪除?" onConfirm={() => handleDelete(record.id)}>
                    <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
            </Space>
        }
    ]
    return (
        <ContentLayout title="車型管理">
            <Spin spinning={loading}>
                <AuthStoreSelect value={storeId} onSelect={handleSelectStore} />
                <Form style={{ marginBottom: 10 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={handleShowAddDrawer}>新增</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table columns={columns} dataSource={detail} rowKey="id" scroll={{ x: 'max-content' }} />
                <Drawer title={`${editModalMode === 'store' ? '新增' : '修改'}車型`} open={editModalVisible} onClose={handleHideDrawer}>
                    <Spin spinning={editModalLoading}>
                        <Form ref={addForm} labelCol={{ span: 6 }} onFinish={handleFinish}>
                            <Form.Item label="品牌">
                                <VehicleBrandSelect value={vehicleBrandId} onSelect={(value) => {
                                    setVehicleBrandId(value);
                                    setVehicleTypeId(null);
                                }} />
                            </Form.Item>
                            <Form.Item label="車款">
                                <VehicleTypeSelect vehicleBrandId={vehicleBrandId} value={vehicleTypeId} onSelect={(value) => {
                                    setVehicleTypeId(value);
                                }} />
                            </Form.Item>
                            <Form.Item label="車型" name="vehiclespec_id" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <VehicleSpecSelect vehicleBrandId={vehicleBrandId} vehicleTypeId={vehicleTypeId} />
                            </Form.Item>
                            <Form.Item label="數量" name="quantity" rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <InputNumber />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                <Button type="primary" htmlType="submit">送出</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default VehiclesSpecPage