import { Button, Drawer, Space } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas';

const defaultWidth = 340;
const defaultHeight = 340;

function SignatureDrawer({ title, visible, contract, onDelete, onClose, onFinish, dataUrl, ...props }) {
    const [stage, setStage] = useState('showContract');
    const [, setCanvasSize] = useState({ width: defaultWidth, height: defaultHeight });
    const canvas = useRef(null);
    const wrapper = useRef(null);

    const restoreData = useCallback(() => {
        if (!canvas.current) return;
        canvas.current.clear();
        if (dataUrl) {
            canvas.current.fromDataURL(dataUrl, { ratio: 1 });
        }
    }, [dataUrl]);

    const resized = useCallback(() => {
        // console.log("resized");
        if (!canvas.current) return;
        // const rawCanvas = canvas.current.getCanvas();
        // console.log("canvas", rawCanvas.width, rawCanvas.height);
        const wrapperWidth = wrapper.current ? wrapper.current.clientWidth : defaultWidth;
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : defaultHeight;

        // console.log("wrapper", wrapperWidth, wrapperHeight);
        const tmpData = canvas.current.toDataURL();

        // Resize canvas
        canvas.current.getCanvas().width = wrapperWidth;
        canvas.current.getCanvas().height = wrapperHeight;
        setCanvasSize({ width: wrapperWidth, height: wrapperHeight });

        canvas.current.clear();
        canvas.current.fromDataURL(tmpData, { ratio: 1 });
    }, [canvas]);

    useEffect(() => {
        restoreData();
    }, [restoreData]);

    useEffect(() => {
        if (stage !== 'signature') return;
        setTimeout(() => {
            resized(); // after drawer visible
        }, 100);
        window.addEventListener("resize", resized);
        return () => window.removeEventListener("resize", resized);
    }, [resized, visible, stage]);

    useEffect(() => {
        if (visible) {
            setStage('showContract');
        }
    }, [visible])
    const handleClear = () => {
        canvas.current.clear();
    }
    const handleSubmit = () => {
        const dataURL = canvas.current.toDataURL();
        if (onFinish) {
            onFinish(dataURL);
        }
        canvas.current.clear();
    }
    const handleDelete = () => {
        if (onDelete) {
            onDelete();
        }
    }
    const handleGoSignature = () => {
        setStage('signature');
        // Resize and restore data after show signature
        setTimeout(() => {
            resized();
            restoreData();
        }, 1);
    }
    return (
        <Drawer title={title ?? '簽名'} size="large" bodyStyle={{ padding: 10 }} open={visible} onClose={onClose} {...props} >
            {stage === 'showContract' && <>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ flex: 1, border: '1px solid black', padding: 5 }} dangerouslySetInnerHTML={{ __html: contract ? contract.description : '無合約' }}></div>
                    <div style={{ padding: 5, textAlign: 'center' }}>
                        <Space>
                            <Button onClick={onDelete}>不同意</Button>
                            <Button type="primary" onClick={handleGoSignature}>簽名</Button>
                        </Space>
                    </div>
                </div>
            </>}
            {stage === 'signature' && <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ flex: 1, lineHeight: 0, border: '1px solid black' }}>
                    <div ref={wrapper} style={{ height: '100%', position: 'relative' }}>
                        <ReactSignatureCanvas ref={canvas} penColor='blue' clearOnResize={false}
                            canvasProps={{ width: defaultWidth, height: defaultHeight, className: 'sigCanvas' }} />
                        <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                            <Space>
                                {dataUrl && onDelete && <Button onClick={handleDelete}>刪除</Button>}
                                <Button onClick={handleClear}>清除</Button>
                                <Button type="primary" onClick={handleSubmit}>送出</Button>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>}
        </Drawer>
    )
}

export default SignatureDrawer