import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function VehicleSpecSelect({ vehicleBrandId, vehicleTypeId, onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [vehicleSpecs, setVehicleSpecs] = useState([]);
    const refresh = useCallback(() => {
        if (!vehicleBrandId || !vehicleTypeId) return;
        setLoading(true);
        TourniiAPI.vehicleBrands.types.specs.index(vehicleBrandId, vehicleTypeId, {}, 1, 999).then((response) => {
            const result = response.data;
            setVehicleSpecs(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [vehicleBrandId, vehicleTypeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select {...props} loading={loading} allowClear={true} onSelect={onSelect} onClear={onSelect}>
            {vehicleSpecs.map((vehicleSpec) => <Select.Option key={vehicleSpec.id} value={vehicleSpec.id}>{vehicleSpec.name}</Select.Option>)}
        </Select>
    )
}

export default VehicleSpecSelect