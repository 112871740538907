import { Spin } from 'antd';
import React, { useState } from 'react'
import AuthStoreSelect from '../../components/AuthStoreSelect';
import ContentLayout from '../ContentLayout'

function PickUpPage() {
    const [storeId, setStoreId] = useState();
    const [loading, ] = useState(false);

    return (
        <ContentLayout title="取車">
            <Spin spinning={loading}>
                <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
            </Spin>
        </ContentLayout>
    )
}

export default PickUpPage