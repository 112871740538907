import React, { useContext } from 'react'
import { Col, Layout, Row } from 'antd'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from "@ant-design/icons";
import { MenuContext } from './MenuContext';

function ContentLayout({ ...props }) {

    const { toggleMenu, collapsed } = useContext(MenuContext);
    return (
        <Layout className="site-layout layout-content" style={{ overflowY: 'auto', position: 'relative', backgroundColor: 'white' }}>
            <Layout.Header className="site-layout-header" style={{ padding: 0, backgroundColor: 'transparent', color: 'black' }}>
                <Row>
                    <Col>
                        {React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger",
                                onClick: toggleMenu,
                            }
                        )}
                    </Col>
                    <Col style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: 0, textAlign: 'center', paddingRight: 48 }}>
                        <span style={{ fontSize: '1.3em', fontWeight: 'bold', color: 'black' }}>
                            {props.title ?? <img alt="Tournii" style={{ width: '90%', maxWidth: 60 }} src="/logo192.png" />}
                        </span>
                    </Col>
                </Row>
            </Layout.Header>
            <Layout.Content className="site-layout-background" style={{ margin: '24px 16px', padding: 5, minHeight: 'auto', position: 'relative', ...props.style }}>
                {props.children ? props.children : "無內容"}
            </Layout.Content>
        </Layout>
    )
}

export default ContentLayout