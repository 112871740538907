import { Select } from 'antd';
import React, { useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function PublicStoreSelect({ onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const refresh = () => {
        setLoading(true);
        TourniiAPI.public.stores.index({}, 1, 999).then((response) => {
            const result = response.data;
            setStores(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        refresh();
    }, []);
    return (
        <Select {...props} loading={loading} allowClear={true} onSelect={onSelect} onClear={onSelect}>
            {stores.map((store) => <Select.Option key={store.id} value={store.id}>{store.name}</Select.Option>)}
        </Select>
    )
}

export default PublicStoreSelect