import { Button, Form, InputNumber, notification, Space, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Drawer from './Drawer';
import TakePhotoPanel from './TakePhotoPanel';

function OdoMeterUploadDrawer({ title, loading, onDelete, onFinish, visible, value, photo, ...props }) {
    const [imageSrc, setImageSrc] = useState();
    const [odoMeter, setOdoMeter] = useState(0);
    const inputRef = useRef();

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus({
                        cursor: 'all',
                    });
                }
            }, 100);
        }
    }, [visible]);

    useEffect(() => {
        setOdoMeter(value);
        setImageSrc(photo);
    }, [value, photo]);

    const handleGetPhoto = (dataUrl) => {
        setImageSrc(dataUrl);
    }
    const handleSubmit = () => {
        if (!imageSrc) {
            notification.warn({ message: '請上傳照片' });
            return;
        }
        if (onFinish) {
            onFinish(odoMeter, imageSrc);
        }
    }
    const handleDelete = () => {
        if (onDelete) {
            onDelete();
        }
    }
    return (
        <Drawer title={title ?? '里程上傳'} open={visible} {...props}>
            <Spin spinning={!!loading}>
                <Form layout="vertical">
                    <Form.Item label="里程數">
                        <InputNumber ref={inputRef} value={odoMeter} min={0} onChange={(value) => setOdoMeter(value)} />
                    </Form.Item>
                    <Form.Item label="儀錶板">
                        <TakePhotoPanel visible={true} preview={imageSrc} onChange={handleGetPhoto} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Space>
                            {photo && onDelete && <Button danger onClick={handleDelete}>刪除</Button>}
                            <Button type="primary" disabled={!imageSrc} onClick={handleSubmit}>送出</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}

export default OdoMeterUploadDrawer