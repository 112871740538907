import React from 'react'
import { Link } from 'react-router-dom'
import ContentLayout from './ContentLayout'

function NotFoundPage() {
    return (
        <ContentLayout title="途你租車">
            <div style={{ textAlign: 'center' }}>
                <h1>無法存取頁面</h1>
                您的權限不足或頁面不存在，請點選 <Link to="/">此處</Link> 返回首頁
            </div>
        </ContentLayout>
    )
}

export default NotFoundPage