import { Card, Col, Form, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../../api/TourniiAPI';
import VehicleBrandSelect from '../../../components/VehicleBrandSelect'
import VehicleTypeSelect from '../../../components/VehicleTypeSelect'
import Drawer from '../../../components/Drawer';
import { DATETYPE_LIMIT_AMOUNTS, getMinPriceDateType } from '../../../utils/dateType';

const VehicleCard = ({ vehicleSpecInv, onClick }) => {
    const minPriceDateType = getMinPriceDateType(vehicleSpecInv);
    const noPrice = Object.entries(minPriceDateType).length === 0;

    let handleClick = onClick;
    let styles = { cursor: 'pointer' };
    if (noPrice) {
        handleClick = null;
        styles = { cursor: 'not-allowed' };
    }
    return <Card size="small">
        <Row
            style={styles}
            key={vehicleSpecInv.vehiclespec_id}
            gutter={[10, 10]}
            onClick={handleClick}>
            <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {vehicleSpecInv.vehiclespec.photos.length > 0 &&
                    <img
                        style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
                        alt={`${vehicleSpecInv.vehiclespec.vehicletype.brand.name} ${vehicleSpecInv.vehiclespec.name}`}
                        src={TourniiAPI.getImageHostUrl(vehicleSpecInv.vehiclespec.photos[0].thumbnailfileurl)} />
                }
            </Col>
            <Col span={16}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: 1 }}>{vehicleSpecInv.vehiclespec.vehicletype.brand.name} {vehicleSpecInv.vehiclespec.name}</div>
                    <div style={{ flex: 1 }}>現有庫存 {vehicleSpecInv.idlequantity}</div>
                    <div style={{ flex: 1 }}>
                        {noPrice && <>未定價</>}
                        {
                            DATETYPE_LIMIT_AMOUNTS.map((priceCol) => {
                                return minPriceDateType[priceCol.col] ? <div key={priceCol.col}>
                                    {priceCol.unit}租還 {minPriceDateType[priceCol.col]} 元起
                                </div> : null;
                            })
                        }
                    </div>
                </div>
            </Col>
        </Row>
    </Card>
}
function VehicleSelectDrawer({ title, storeId, visible, onOk, onClose, startTime, endTime, ...props }) {
    const [vehicleSpecInvLoading, setVehicleSpecInvLoading] = useState(false);
    const [vehicleSpecInvs, setVehicleSpecInvs] = useState([]);
    const [selectedVehicleBrand, setSelectedVehicleBrand] = useState();
    const [selectedVehicleType, setSelectedVehicleType] = useState();


    const handleSelectVehicleBrand = (value) => {
        setSelectedVehicleBrand(value);
    }
    const handleSelectVehicleType = (value) => {
        setSelectedVehicleType(value);
    }
    const handleHideVehicleSelect = () => {
        if (onClose) {
            onClose();
        }
    }
    const handleSelectVehicle = (vehicleSpecId) => {
        if (onOk) {
            onOk(vehicleSpecId);
        }
    }
    const refreshVehicleInvs = useCallback(() => {
        if (!storeId) return;
        setVehicleSpecInvLoading(true);
        TourniiAPI.store.stores.orders.vehicleSpecInv.index(storeId, {
            brand_id: selectedVehicleBrand,
            vehicletype_id: selectedVehicleType,
            starttime: startTime,
            endtime: endTime,
        }, 1, 999).then((response) => {
            const result = response.data;
            // console.log(result.data);
            setVehicleSpecInvs(result);
        }).finally(() => {
            setVehicleSpecInvLoading(false);
        });
    }, [storeId, selectedVehicleBrand, selectedVehicleType, startTime, endTime]);

    useEffect(() => {
        refreshVehicleInvs();
    }, [refreshVehicleInvs]);

    return (
        <Drawer title={title ?? '選擇車輛'} open={visible} onClose={handleHideVehicleSelect}>
            <Spin spinning={vehicleSpecInvLoading}>
                <Form>
                    <Form.Item label="品牌">
                        <VehicleBrandSelect onSelect={handleSelectVehicleBrand} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="車型">
                        <VehicleTypeSelect vehicleBrandId={selectedVehicleBrand} onSelect={handleSelectVehicleType} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item>
                        <Row gutter={[10, 10]}>
                            {vehicleSpecInvs.map((vehicleSpecInv) =>
                                <Col key={vehicleSpecInv.id} span={24}>
                                    <VehicleCard vehicleSpecInv={vehicleSpecInv} onClick={() => handleSelectVehicle(vehicleSpecInv.vehiclespec_id)} />
                                </Col>
                            )}
                        </Row>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}

export default VehicleSelectDrawer