import { Button, Result } from 'antd';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ContentLayout from '../containers/ContentLayout';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    handleReturn = () => {
        this.setState({ hasError: false });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ContentLayout title="維護">
                <Result status="warning" title="系統維護中請稍後再試" extra={<>
                    <div style={{ marginBottom: 20 }}>若持續出現此訊息，請記錄操作的過程及步驟，並連絡系統管理者我們會盡快為您處理。</div>
                    <Link to="/">
                        <Button type="primary" key="console" onClick={this.handleReturn}>
                            返回首頁
                        </Button>
                    </Link>
                </>} />
            </ContentLayout>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary