import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, notification, Row, Space, Spin, Table, Typography } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import VehicleBrandSelect from '../../components/VehicleBrandSelect';
import VehicleSpecSelect from '../../components/VehicleSpecSelect';
import VehicleTypeSelect from '../../components/VehicleTypeSelect';
import ContentLayout from '../ContentLayout'

const ClearableInputNumber = ({ value, onChange, ...props }) => {
    const [checked, setChecked] = useState(value > 0 ? true : false);
    const handleCheck = (e) => {
        console.log("handleCheck", e.target.checked);
        setChecked(e.target.checked);
        if (!e.target.checked) {
            handleChange(null);
        }
    }
    const handleChange = (value) => {
        console.log("handleChange", value);
        if (onChange) {
            onChange(value);
            if (value) {
                setChecked(true);
            }
        }
    }
    return <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
        <Checkbox checked={checked} onChange={handleCheck} />
        <InputNumber disabled={!checked} value={value} onChange={handleChange} {...props} />
    </div>;
}
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    required,
    children,
    handleEditPrice,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ?
        required ? <InputNumber min={0} /> : <ClearableInputNumber min={0} />
        : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: required,
                            message: `請輸入 ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                <div style={{ cursor: 'pointer' }} onClick={handleEditPrice ? () => handleEditPrice(record) : null}>{children}</div>
            )}
        </td>
    );
};

function PricingPage() {
    const [storeId, setStoreId] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [vehicleBrandId, setVehicleBrandId] = useState(null);
    const [vehicleTypeId, setVehicleTypeId] = useState(null);
    const [vehicleSpecId, setVehicleSpecId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vehicleSpecInvDetail, setVehicleSpecInvDetail] = useState([]);
    const [vehicleDateTypeDetail, setVehicleDateTypeDetail] = useState([]);
    const editForm = useRef();

    const refresh = useCallback(async () => {
        if (!storeId) return;
        setLoading(true);

        // 取得日期類型清單
        const p1 = await TourniiAPI.store.stores.dateType.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            console.log(result);
            setVehicleDateTypeDetail(result.data);
            return result.data;
        });

        // 取得庫存車輛
        const p2 = await TourniiAPI.store.stores.vehicleSpecInv.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            console.log(result);
            setVehicleSpecInvDetail(result.data);
            return result.data;
        });

        const [dateTypes, vehicleSpecInvs] = await Promise.all([p1, p2]);

        // 取得訂價資料並組合資料存至 setVehicleSpecInvDetail()
        await TourniiAPI.store.stores.specPricing.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            const detail = result.data;
            const newVehicleSpecInvDetail = vehicleSpecInvs.map((vehicleSpecInv) => {
                const filteredDetail = detail.filter((row) => row.vehiclespec_id === vehicleSpecInv.vehiclespec_id);
                const newDateTypes = dateTypes.map((dateType) => {
                    const raw = filteredDetail.find((row) => row.datetype_id === dateType.id);
                    return {
                        ...dateType,
                        hourprice: raw ? raw.hourprice : null,
                        limitamount6: raw ? raw.limitamount6 : null,
                        limitamount8: raw ? raw.limitamount8 : null,
                        limitamount24: raw ? raw.limitamount24 : null,
                        overtimeamount: raw ? raw.overtimeamount : null,
                        overtimeminutes: raw ? raw.overtimeminutes : null,
                        raw: raw
                    }
                })
                const vehicleSpecInvResult = {
                    ...vehicleSpecInv,
                    dateTypes: newDateTypes,
                    raw: vehicleSpecInv,
                }
                return vehicleSpecInvResult;
            });
            console.log(newVehicleSpecInvDetail);
            setVehicleSpecInvDetail(newVehicleSpecInvDetail);
        });
        setLoading(false);
    }, [storeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const dateTypeColumns = vehicleDateTypeDetail.map((dateType, index) => {
        return {
            title: dateType.name,
            dataIndex: ['dateTypes', index],
            children: [
                {
                    title: '逾時門檻 (分)',
                    dataIndex: ['dateTypes', index, 'overtimeminutes'],
                    align: 'right',
                    render: (value) => value ?? '無',
                },
                {
                    title: '逾時費用/時',
                    dataIndex: ['dateTypes', index, 'overtimeamount'],
                    align: 'right',
                    render: (value) => value ?? '無',
                },
                {
                    title: '小時價',
                    dataIndex: ['dateTypes', index, 'hourprice'],
                    align: 'right',
                    render: (value) => value ?? '-',
                },
                {
                    title: '6 小時價',
                    dataIndex: ['dateTypes', index, 'limitamount6'],
                    align: 'right',
                    render: (value) => value ?? '-',
                },
                {
                    title: '8 小時價',
                    dataIndex: ['dateTypes', index, 'limitamount8'],
                    align: 'right',
                    render: (value) => value ?? '-',
                },
                {
                    title: '24 小時價',
                    dataIndex: ['dateTypes', index, 'limitamount24'],
                    align: 'right',
                    render: (value) => value ?? '待定價',
                },
            ]
        }
    });

    const [editModalLoading, setEditModalLoading] = useState(false);
    const [pricingDetail, setPricingDetail] = useState([]);
    const handleShowEditModal = (vehicleSpecInvId, record) => {
        setEditModalVisible(true);
        setEditModalLoading(true);
        setVehicleBrandId(record.vehiclespec.vehicletype.brand.id);
        setVehicleTypeId(record.vehiclespec.vehicletype.id);
        setVehicleSpecId(record.vehiclespec_id);
        TourniiAPI.store.stores.specPricing.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            const detail = result.data;
            const filteredDetail = detail.filter((row) => row.vehiclespec_id === record.vehiclespec_id);
            const defaultPricingData = vehicleDateTypeDetail.map((dateType) => {
                const raw = filteredDetail.find((row) => row.datetype_id === dateType.id);
                return {
                    ...dateType,
                    raw: raw
                };
            });
            console.log(defaultPricingData);
            setPricingDetail(defaultPricingData);
        }).finally(() => {
            setEditModalLoading(false);
        });
    }
    const handleHideEditModal = () => {
        setEditModalVisible(false);
    }
    const handleFinish = (values) => {
        if (editingKey) {
            notification.warn({ message: '您尚未完成價格編輯，請先完成後再送出' });
            return;
        }
        setEditModalVisible(false);
        setLoading(true);
        console.log(vehicleSpecId, pricingDetail);
        pricingDetail.forEach(async (row) => {
            if (row.mode === 'delete') {
                if (row.raw.id) {
                    console.log("delete", row);
                    await TourniiAPI.store.stores.specPricing.destroy(storeId, row.raw.id);
                }
            } else if (row.mode === 'store') {
                console.log("add", row);
                await TourniiAPI.store.stores.specPricing.store(storeId, {
                    vehiclespec_id: vehicleSpecId,
                    datetype_id: row.id,
                    hourprice: row.raw.hourprice,
                    overtimeamount: row.raw.overtimeamount,
                    overtimeminutes: row.raw.overtimeminutes,
                    limitamount6: row.raw.limitamount6,
                    limitamount8: row.raw.limitamount8,
                    limitamount24: row.raw.limitamount24,
                });
            } else if (row.mode === 'update') {
                console.log("update", storeId, row.raw.id, row);
                // 後端會自動比較是同 datetype_id 與 vehiclespec_id 則做更新
                await TourniiAPI.store.stores.specPricing.store(storeId, {
                    vehiclespec_id: vehicleSpecId,
                    datetype_id: row.id,
                    hourprice: row.raw.hourprice,
                    overtimeamount: row.raw.overtimeamount,
                    overtimeminutes: row.raw.overtimeminutes,
                    limitamount6: row.raw.limitamount6,
                    limitamount8: row.raw.limitamount8,
                    limitamount24: row.raw.limitamount24,
                });
            } else {
                // unchanged.
            }
        });
        refresh();
    }

    const columns = [
        {
            title: '品牌',
            dataIndex: 'vehiclebrand',
            key: 'vehiclebrand',
            render: (value, record) => record.vehiclespec.vehicletype.brand.name
        },
        {
            title: '車款',
            dataIndex: 'vehicletype',
            key: 'vehicletype',
            render: (value, record) => record.vehiclespec.vehicletype.name
        },
        {
            title: '車型',
            dataIndex: 'vehiclespec',
            key: 'vehiclespec',
            render: (value, record) => record.vehiclespec.name
        },
        ...dateTypeColumns,
        {
            title: '動作',
            // fixed: 'right',
            align: 'center',
            width: 100,
            render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowEditModal(record.id, record)}>修改</Typography.Link>
            </Space>
        }
    ]

    const [pricingForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.id === editingKey;
    const handleEditPrice = (record) => {
        console.log("edit", record);
        pricingForm.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };
    const handleSavePrice = async (id) => {
        try {
            const data = await pricingForm.validateFields();
            console.log(data);
            const newData = [...pricingDetail];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                const mode = item.raw && item.raw.id ? 'update' : 'store';
                newData.splice(index, 1, { ...item, raw: { ...item.raw, ...data.raw }, mode: mode });
                setPricingDetail(newData);
                setEditingKey('');
            } else {
                // newData.push(row);
                // setPricingDetail(newData);
                // setEditingKey('');
            }
            console.log(newData);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const handleCancelSavePrice = () => {
        setEditingKey('');
    };
    const handleDeletePrice = (id) => {
        console.log(id);
        const newPricingDetail = Object.assign([], pricingDetail);
        const idx = newPricingDetail.findIndex((row) => row.id === id);
        const row = newPricingDetail[idx];
        const newRow = {
            ...row,
            raw: {
                ...row.raw,
                hourprice: null,
                overtimeamount: null,
                overtimeminutes: null,
                limitamount: null,
            },
            mode: 'delete',
        }
        newPricingDetail[idx] = newRow;
        setPricingDetail(newPricingDetail);
    }
    const pricingColumns = [
        {
            title: '日期類型',
            dataIndex: 'name',
        },
        {
            title: '逾時門檻 (分)',
            editable: true,
            dataIndex: ['raw', 'overtimeminutes'],
            align: 'right',
            required: true,
            render: (value) => value ?? '無',
        },
        {
            title: '逾時費用/時',
            editable: true,
            dataIndex: ['raw', 'overtimeamount'],
            align: 'right',
            required: true,
            render: (value) => value ?? '無',
        },
        {
            title: '小時價',
            editable: true,
            dataIndex: ['raw', 'hourprice'],
            align: 'right',
            required: false,
            render: (value) => value ?? '-',
        },
        {
            title: '6 小時價',
            editable: true,
            dataIndex: ['raw', 'limitamount6'],
            align: 'right',
            required: false,
            render: (value) => value ?? '-',
        },
        {
            title: '8 小時價',
            editable: true,
            dataIndex: ['raw', 'limitamount8'],
            align: 'right',
            required: false,
            render: (value) => value ?? '-',
        },
        {
            title: '24 小時價',
            editable: true,
            dataIndex: ['raw', 'limitamount24'],
            align: 'right',
            required: true,
            render: (value) => value ?? '待定價',
        },
        {
            title: '動作',
            dataIndex: 'operation',
            align: 'center',
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Typography.Link onClick={() => handleSavePrice(record.id)}>儲存</Typography.Link>
                        <Typography.Link onClick={handleCancelSavePrice}>取消</Typography.Link>
                    </Space>
                ) : (
                    <Space>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => handleEditPrice(record)}>
                            編輯
                        </Typography.Link>
                        <Typography.Link onClick={() => handleDeletePrice(record.id)}>
                            刪除
                        </Typography.Link>
                    </Space>
                );
            },
        },
    ];
    const mergedPricingColumns = pricingColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                required: !!col.required,
                title: col.title,
                editing: isEditing(record),
                handleEditPrice: handleEditPrice,
            }),
        };
    });
    return (
        <ContentLayout title="車型定價">
            <Spin spinning={loading}>
                <Form style={{ marginBottom: 20 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                            <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                        </Col>
                    </Row>
                </Form>
                <Table size="small" columns={columns} dataSource={vehicleSpecInvDetail} rowKey="id" scroll={{ x: 'max-content' }} />
                <Drawer title="修改價格" open={editModalVisible} size="large" onClose={handleHideEditModal}>
                    <Form labelCol={{ span: 3 }} ref={editForm} onFinish={handleFinish}>
                        <Spin spinning={editModalLoading}>
                            <Form.Item label="品牌">
                                <VehicleBrandSelect value={vehicleBrandId} onSelect={(value) => setVehicleBrandId(value)} disabled />
                            </Form.Item>
                            <Form.Item label="車款">
                                <VehicleTypeSelect vehicleBrandId={vehicleBrandId} value={vehicleTypeId} onSelect={(value) => setVehicleTypeId(value)} disabled />
                            </Form.Item>
                            <Form.Item label="車型">
                                <VehicleSpecSelect vehicleBrandId={vehicleBrandId} vehicleTypeId={vehicleTypeId} value={vehicleSpecId} onSelect={(value) => setVehicleSpecId(value)} disabled />
                            </Form.Item>
                            <Form.Item>
                                <Form form={pricingForm} component={false}>
                                    <Table
                                        size="small"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        bordered
                                        columns={mergedPricingColumns}
                                        dataSource={pricingDetail}
                                        rowKey="id"
                                        rowClassName="editable-row"
                                        scroll={{ x: 'max-content' }}
                                        pagination={false}
                                    />
                                </Form>
                            </Form.Item>
                            <Form.Item>
                                <ul>
                                    <li>逾時門檻 (分)：超過逾時門檻即以逾時費用/時計算</li>
                                    <li>逾時費用/時：逾時每小時費用</li>
                                </ul>
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit">送出</Button>
                            </Form.Item>
                        </Spin>
                    </Form>
                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default PricingPage