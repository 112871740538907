import { Button, Checkbox, Col, Form, Input, List, notification, Radio, Row, Space, Spin, Tabs } from 'antd'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { VEHICLESTATE_NEW, VEHICLESTATE_PICKUPED, VEHICLESTATE_RETURNED } from '../api/TourniiAPI';
import BadgeListItem from './BadgeListItem';
import CountrySelect from './CountrySelect';
import Drawer from './Drawer';
import VehiclePhoto from './VehiclePhoto';
import VehicleStateTag from './VehicleStateTag';

function OrderItemVehicleDrawer({
    title,
    vehicleId,
    visible,
    onClose,
    onClickOdoMeterUpload,
    onClickVehiclePhotoUpload,
    onClickCredentialUpload,
    onClickSignature,
    onUpdate,
    onDone,
    onItemVehicleShow,
    onItemVehicleStore,
    onItemVehicleUpdate,
    onItemVehiclePickup,
    onItemVehicleReturn,
    onItemVehicleUndoReturn,
    onItemVehiclePhotoShow,
}, ref) {
    const [loading, setLoading] = useState(false);
    const [selectedItemVehicle, setSelectedItemVehicle] = useState();
    const [editMode, setEditMode] = useState('store');
    const [editId, setEditId] = useState();
    const [hideIdCountry, setHideIdCountry] = useState(true);
    const [vehicleData, setVehicleData] = useState();
    const orderVehicleForm = useRef();
    const vehiclePhotoFront = useRef();
    const vehiclePhotoBack = useRef();
    const vehiclePhotoBodyLeft = useRef();
    const vehiclePhotoBodyRight = useRef();
    const refresh = useCallback(() => {
        // console.warn("OrderItemVehicleDrawer: refresh");
        if (!visible) return;
        if (!editId) {
            // store mode
            setEditMode('store');
            setVehicleData();
            orderVehicleForm.current.resetFields();
        } else {
            // update mode
            setLoading(true);
            onItemVehicleShow(editId).then((response) => {
                setEditMode('update');
                // setEditId(editId);
                const result = response.data;
                if (orderVehicleForm.current) {
                    orderVehicleForm.current.setFieldsValue(result);
                }
                setSelectedItemVehicle(result);
                setVehicleData(result);
                handleForeignNationalChange();
            }).finally(() => {
                setLoading(false);
            });
        }
        // refresh vehicle photo
        [vehiclePhotoFront, vehiclePhotoBack, vehiclePhotoBodyLeft, vehiclePhotoBodyRight].forEach((vehiclePhotoRef) => {
            if (vehiclePhotoRef.current) {
                vehiclePhotoRef.current.refresh();
            }
        })
    }, [onItemVehicleShow, visible, editId]);

    useEffect(() => {
        setEditId(vehicleId);
    }, [vehicleId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useImperativeHandle(ref, () => ({
        refresh: refresh
    }));

    const handleHideItemVehicleDrawer = () => {
        if (onClose) {
            onClose();
        }
    }
    const handleFinish = (values) => {
        if (editMode === 'store') {
            setLoading(true);
            onItemVehicleStore(values).then((response) => {
                const result = response.data;
                //notification.info({ message: '儲存成功' });
                setEditId(result.id);
                setVehicleData(result);
                refresh();
                console.log(result);
                if (onUpdate) {
                    onUpdate(result);
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(true);
            onItemVehicleUpdate(editId, values).then((response) => {
                const result = response.data;
                notification.info({ message: '修改成功' });
                setVehicleData(result);
                console.log(result);
                if (onUpdate) {
                    onUpdate(result);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }
    const handlePickup = () => {
        if (!editId) {
            notification.warn({ message: '缺少 editId 其中一項' });
            return;
        }
        setLoading(true);
        onItemVehiclePickup(editId).then((response) => {
            const result = response.data;
            notification.info({ message: '取車成功' });
            refresh();
            if (onDone) {
                onDone(result);
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleReturn = () => {
        if (!editId) {
            notification.warn({ message: '缺少 editId 其中一項' });
            return;
        }
        setLoading(true);
        onItemVehicleReturn(editId).then((response) => {
            const result = response.data;
            notification.info({ message: '還車成功' });
            refresh();
            if (onDone) {
                onDone(result);
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleUndoReturn = () => {
        if (!editId) {
            notification.warn({ message: '缺少 editId 其中一項' });
            return;
        }
        setLoading(true);
        onItemVehicleUndoReturn(editId).then((response) => {
            const result = response.data;
            notification.info({ message: '取消還車成功' });
            refresh();
            if (onUpdate) {
                onUpdate(result);
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleForeignNationalChange = () => {
        if (!orderVehicleForm.current) return;
        const foreignNational = orderVehicleForm.current.getFieldValue(['renter', 'foreignnational']);
        // console.log('foreignNational', foreignNational);
        setHideIdCountry(foreignNational === 0);
    }
    return (
        <Drawer title={title ?? `${editMode === 'store' ? '新增' : '修改'}車輛租賃資訊`} open={visible} onClose={handleHideItemVehicleDrawer}>
            <Spin spinning={loading}>
                <Form ref={orderVehicleForm} onFinish={handleFinish} layout="vertical" initialValues={{
                    renter: {
                        foreignnational: 0,
                    },
                }}>
                    <Form.Item label="車牌號碼" name="vehicleplatenumber" rules={[{
                        required: true,
                        pattern: '[A-Z0-9]{2,4}-[A-Z0-9]{2,4}',
                        message: '車牌格式需為: ABC-1234'
                    }]}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                    <Form.Item label="同預訂人資料" name="referorderuser" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="承租者姓名" name={["renter", "name"]} >
                        <Input />
                    </Form.Item>
                    <Form.Item label="身分證字號" name={["renter", "idnumber"]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="手機號碼" name={["renter", "cellphone"]} >
                        <Input />
                    </Form.Item>
                    <Form.Item label="國內/國外" name={["renter", "foreignnational"]}>
                        <Radio.Group buttonStyle="solid" onChange={handleForeignNationalChange}>
                            <Radio.Button value={0}>國內</Radio.Button>
                            <Radio.Button value={1}>國外</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="國藉" name={["renter", "idcountry"]} hidden={hideIdCountry}>
                        <CountrySelect />
                    </Form.Item>
                    <Form.Item label="狀態" shouldUpdate>
                        {({ getFieldValue }) => <VehicleStateTag state={getFieldValue('state')} />}
                    </Form.Item>
                    <Form.Item label="取車時間" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('pickeduptime') ?? '尚未取車'}
                    </Form.Item>
                    <Form.Item label="還車時間" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('returnedtime') ?? '尚未還車'}
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        {editMode === 'store' ?
                            <Button type="primary" htmlType="submit">確認</Button> :
                            <Button htmlType="submit">修改</Button>
                        }
                    </Form.Item>

                    {editMode === 'update' && <>
                        <Tabs size="large" defaultActiveKey={vehicleData.state === VEHICLESTATE_NEW ? "pickup" : "return"}>
                            <Tabs.TabPane key="pickup" tab="取車">
                                <Form.Item label="車況">
                                    <Row gutter={[10, 10]}>
                                        {[
                                            { type: 'front', ref: vehiclePhotoFront },
                                            { type: 'back', ref: vehiclePhotoBack },
                                            { type: 'body-left', ref: vehiclePhotoBodyLeft },
                                            { type: 'body-right', ref: vehiclePhotoBodyRight },
                                        ].map((row, index) => <Col key={`photo-${row.type}`} span={12}>
                                            <VehiclePhoto
                                                ref={row.ref}
                                                vehicleId={editId}
                                                onClick={onClickVehiclePhotoUpload.bind(this, selectedItemVehicle, row.type)}
                                                type={row.type}
                                                onItemVehiclePhotoShow={onItemVehiclePhotoShow}
                                            />
                                        </Col>
                                        )}
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <List>
                                        <BadgeListItem valid={vehicleData.validation['odometer-to']} onClick={onClickOdoMeterUpload.bind(this, selectedItemVehicle, 'to')}>
                                            里程記錄
                                        </BadgeListItem>
                                        <BadgeListItem valid={vehicleData.validation['credentials-id-card-front']} onClick={onClickCredentialUpload.bind(this, selectedItemVehicle, 'id-card', 'front')}>
                                            上傳證件正面)
                                        </BadgeListItem>
                                        <BadgeListItem valid={vehicleData.validation['credentials-id-card-back']} onClick={onClickCredentialUpload.bind(this, selectedItemVehicle, 'id-card', 'back')}>
                                            上傳證件 (反面)
                                        </BadgeListItem>
                                        <BadgeListItem valid={vehicleData.validation['credentials-license-front']} onClick={onClickCredentialUpload.bind(this, selectedItemVehicle, 'license', 'front')}>
                                            上傳駕照 (正面)
                                        </BadgeListItem>
                                        {false && <BadgeListItem valid={vehicleData.validation['credentials-license-back']} onClick={onClickCredentialUpload.bind(this, selectedItemVehicle, 'license', 'back')}>
                                            上傳駕照 (反面)
                                        </BadgeListItem>}
                                        <BadgeListItem valid={vehicleData.validation['signature']} onClick={onClickSignature.bind(this, selectedItemVehicle)}>
                                            簽名
                                        </BadgeListItem>
                                    </List>
                                </Form.Item>
                            </Tabs.TabPane>
                            <Tabs.TabPane key="return" tab="還車">
                                <Form.Item>
                                    <List>
                                        <BadgeListItem valid={vehicleData.validation['odometer-back']} onClick={onClickOdoMeterUpload.bind(this, selectedItemVehicle, 'back')}>
                                            里程記錄
                                        </BadgeListItem>
                                    </List>
                                </Form.Item>
                            </Tabs.TabPane>
                        </Tabs>
                    </>}
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Space>
                            {editMode === 'update' && <>
                                {vehicleData.state === VEHICLESTATE_NEW && <Button type="primary" onClick={handlePickup}>取車</Button>}
                                {vehicleData.state === VEHICLESTATE_PICKUPED && <Button type="primary" onClick={handleReturn}>還車</Button>}
                                {onItemVehicleUndoReturn && vehicleData.state === VEHICLESTATE_RETURNED && <Button type="primary" onClick={handleUndoReturn}>取消還車</Button>}
                            </>}
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}

export default forwardRef(OrderItemVehicleDrawer)