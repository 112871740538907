import React from 'react'
import ContentLayout from '../ContentLayout'

function PrivacyPage() {
    return (
        <ContentLayout>
            <h3>隱私權保護政策</h3>
            <p>歡迎蒞臨「tournii途你」系統網站（以下簡稱本網站），
                為了讓您能夠安心的使用本網站的各項服務與資訊，
                特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：</p>
            <h4>一、隱私權保護政策的適用範圍</h4>
            <p>隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。
                隱私權保護政策不適用於本網站以外的相關連結網站，
                也不適用於非本網站所委託或參與管理的人員。</p>
            <h4>二、 個人資料之蒐集及運用</h4>
            <p>當您註冊成為本網站會員，或在本網站中購物 (租機車)，或參加網站活動與使用本網站所提供之服務時，
                我們會要求您填寫個人資訊，如姓名、手機、E-mail、生日...等，其取得的個人資料，
                都僅供本網站於其內部、依照原來所說明的使用目的和範圍或為完成網站交易行為，
                除非事先說明或依照相關法律規定，本網站絕不販售或透露您的資料給第三人或單位。
                在一般瀏覽或查詢時，伺服器自行產生的相關記錄，包括 IP 位址、Cookie 紀錄、使用時間、
                使用的瀏覽器、瀏覽及點選資料紀錄等。上述資料之蒐集僅供本網站依服務或活動設計使用，
                以改善我們的服務品質、產品內容，不會將資料移作其他目的使用。</p>
            <h4>三、資料之保護</h4>
            <p>本網站任何個人資料的網路傳遞皆經過加密機制，以確保資料在傳輸過程中不被第三者非法擷取。</p>
            <h4>四、網站對外的相關連結</h4>
            <p>本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。
                但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。</p>
            <h4>五、與第三人共用個人資料之政策</h4>
            <p>本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，
                但有下列情形者除外：<br />
                1. 配合司法單位合法的調查。<br />
                2. 配合相關職權機關依職務需要之調查或使用。<br />
                3. 基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。<br />
            </p>
            <h4>六、Cookie 之使用</h4>
            <p>為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的 Cookie，若您不願接受
                Cookie 的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie
                的寫入，但可能會導致網站某些功能無法正常執行。
            </p>
            <h4>七、隱私權保護政策之修正</h4>
            <p>本網站將因應社會環境、法令規定及科技技術之變遷，不定時修訂本資訊通安全及隱私權政策聲明，
                並公告於本網站，以維護您瀏覽網站的安全及相關權益，並落實保障網路安全的立意。
            </p>
            <h4>八、活動政策</h4>
            <p>公司保有修改、變更或暫停任何活動之權利。
            </p>
        </ContentLayout>
    )
}

export default PrivacyPage