import { Button, Row, Col, DatePicker, Drawer, Empty, Form, Image, Input, InputNumber, List, Select, Space, Spin, Table, Typography, Modal, Upload, notification, Tag } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import TourniiAPI from '../../api/TourniiAPI';
import AdminCompanySelect from '../../components/AdminCompanySelect';
import AdminStoreSelect from '../../components/AdminStoreSelect';
import ContentLayout from '../ContentLayout'
import OrderStateBadge from '../../components/OrderStateBadge';
import OrderStateRadioGroupButton from '../../components/OrderStateRadioGroupButton';
import AdminUserSelect from '../../components/AdminUserSelect';
import OrderSettledStateBadge from '../../components/OrderSettledStateBadge';
import { createObjectUrlFromResp } from '../../utils/download';

function OrdersPage() {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [detailFilterState, setDetailFilterState] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModalLoading, setEditModalLoading] = useState(false);
    const [editModalData, setEditModalData] = useState({});
    const orderModalForm = useRef();
    const [selectedCompanyId, setSelectedCompanyId] = useState();
    const [selectedStoreId, setSelectedStoreId] = useState();
    const [showImportModal, setShowImportModal] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [fileList, setFileList] = useState([]);

    const refresh = useCallback(() => {
        setLoading(true);
        TourniiAPI.admin.orders.index({ store_id: selectedStoreId }, 1, 999).then((response) => {
            const result = response.data;
            setDetails(result.list.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [selectedStoreId]);

    const exportFile = () => {
        TourniiAPI.admin.orders.export({}).then((response) => {
            createObjectUrlFromResp(response, `tournii-orders-${moment().format('YYYY-MM-DD')}.xlsx`);
        })
    }
    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = [
        {
            title: '訂單編號',
            dataIndex: 'serialno',
        },
        {
            title: '車行',
            dataIndex: 'store',
            render: (store) => store.company.name
        },
        {
            title: '店家',
            dataIndex: 'store',
            render: (store) => store.name
        },
        {
            title: '預計取車',
            dataIndex: 'expectpickuptime',
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm")
        },
        {
            title: '預計還車',
            dataIndex: 'expectreturntime',
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm")
        },
        {
            title: '狀態',
            dataIndex: 'state',
            render: (state) => <OrderStateBadge state={state} />
        },
        {
            title: '承租者',
            dataIndex: 'rentername',
            render: (rentername) => rentername ? rentername : '無'
        },
        {
            title: '手機',
            dataIndex: 'rentercellphone',
            render: (rentercellphone) => rentercellphone ? rentercellphone : '無'
        },
        {
            title: '訂單備註',
            dataIndex: 'processmemo',
        },
        {
            title: '承租者備註',
            dataIndex: 'renternote'
        },
        {
            title: '總額',
            dataIndex: 'totalamount',
            align: 'right',
            // fixed: 'right',
        },
        {
            title: '已收',
            dataIndex: 'receivedamount',
            align: 'right',
            // fixed: 'right',
        },
        {
            title: '尚欠',
            dataIndex: 'accountsreceivable',
            align: 'right',
            // fixed: 'right',
        },
        {
            title: '動作',
            fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowOrderModal(record.id)}>檢視</Typography.Link>
                <Typography.Link onClick={() => handleShowPaymentLogModal(record.id)}>付款記錄</Typography.Link>
            </Space>
        }
    ]
    const handleChangeFilter = (event) => {
        setDetailFilterState(event.target.value);
    }
    const getFilteredDetails = () => {
        if (!detailFilterState) return details;
        return details.filter((detail) => {
            return detail.state === detailFilterState
        });
    }
    const handleShowOrderModal = (orderId) => {
        if (!orderId) return;
        setEditModalVisible(true);
        setEditModalLoading(true);
        TourniiAPI.admin.orders.show(orderId).then((response) => {
            const result = response.data;
            console.log(result);
            setEditModalData(result);
            if (orderModalForm.current) {
                const newResult = {
                    ...result,
                    expecttime: [
                        moment(result.expectpickuptime, "YYYY-MM-DD HH:mm:ss"),
                        moment(result.expectreturntime, "YYYY-MM-DD HH:mm:ss")
                    ]
                };
                delete newResult.expectpickuptime;
                delete newResult.expectreturntime;
                orderModalForm.current.setFieldsValue(newResult);
            }
        }).finally(() => {
            setEditModalLoading(false);
        })
    }
    const hideOrderModal = () => {
        setEditModalVisible(false);
    }
    const handleSelectStore = (value) => {
        setSelectedStoreId(value);
    }
    const handleSelectCompany = (value) => {
        setSelectedCompanyId(value);
    }
    const handleShowImportOrdersModal = (Value) => {
        setShowImportModal(true);
    }
    const handleHideImportOrdersModal = () => {
        setShowImportModal(false);
        setFileList([]);
    }
    const handleAddFile = (file) => {
        setFileList([file]); // Only allow to add one file
        return false;
    }
    const handleRemoveFile = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }
    const handleImportOrder = () => {
        if (fileList.length < 1) {
            notification.warn({ message: '你必須選擇一個檔案' });
            return;
        }
        setImportLoading(true);
        TourniiAPI.admin.orders.import({
            orders: fileList[0]
        }).then((response) => {
            notification.info({ message: '匯入成功' });
            handleHideImportOrdersModal();
        }).catch((error) => {
            // console.log("error", error, error.response);
            if (error?.response?.data?.details) {
                const details = error?.response?.data?.details;
                details.forEach((errMessage) => {
                    notification.error({ message: errMessage });
                });
            }
        }).finally(() => {
            setImportLoading(false);
        });
    }
    const [refundLoading, setRefundLoading] = useState(false);
    const [showPaymentLogModal, setShowPaymentLogModal] = useState({ visible: false, orderId: null });
    const [selectedPaymentLogId, setSelectedPaymentLogId] = useState();
    const [paymentLogs, setPaymentLogs] = useState([]);
    const [paymentLogsLoading, setPaymentLogsLoading] = useState(false);
    const [selectedPayType, setSelectedPayType] = useState(null);

    const handleShowPaymentLogModal = (orderId) => {
        setShowPaymentLogModal({
            visible: true,
            orderId: orderId
        });
        setPaymentLogsLoading(true);
        TourniiAPI.admin.orders.paymentlogs.index(orderId, {}, 1, 999).then((response) => {
            const result = response.data;
            setPaymentLogs(result.data);
        }).finally(() => {
            setPaymentLogsLoading(false);
        })
    }
    const handleHidePaymentLogModal = () => {
        setShowPaymentLogModal({
            visible: false,
            orderId: null,
        });
        setSelectedPayType(null);
    }
    const handleRefund = () => {
        if (!showPaymentLogModal.orderId) return;
        const paytype = paymentLogs.find((row) => row.id === selectedPaymentLogId)?.paytype;
        if (!paytype) {
            notification.warn({ message: '請選擇退款記錄' });
            return;
        }
        if (paytype === "cash") {
            notification.warn({ message: '平台只允許進行信用卡退款，現金退款需由收款店家進行' });
            return;
        }
        setRefundLoading(true);
        TourniiAPI.admin.orders.refund(showPaymentLogModal.orderId, {
            "type": 'thirdparty',
            "paymentlog_id": selectedPaymentLogId
        }).then((response) => {
            notification.info({ message: '退款成功' });
            handleHidePaymentLogModal();
            refresh();
        }).finally(() => {
            setRefundLoading(false);
        })
    }
    const handleSelectRefundPayType = (value) => {
        setSelectedPayType(value);
    }
    return (
        <ContentLayout>
            <Spin spinning={loading}>
                <Form style={{ marginBottom: 20 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                            <Form.Item label="車行">
                                <AdminCompanySelect value={selectedCompanyId} onChange={handleSelectCompany} />
                            </Form.Item>
                        </Col>
                        <Col flex="auto">
                            <Form.Item label="店家">
                                <AdminStoreSelect companyId={selectedCompanyId} value={selectedStoreId} onChange={handleSelectStore} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
                    <Col flex="auto">
                        <OrderStateRadioGroupButton details={details} onChange={handleChangeFilter} />
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={() => handleShowImportOrdersModal()}>匯入</Button>
                            <Button onClick={() => exportFile()}>匯出</Button>
                        </Space>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={getFilteredDetails()} rowKey="id" scroll={{ x: 'max-content' }} style={{ marginTop: 20 }} />
                <Drawer title="檢視訂單" size="large" open={editModalVisible} onClose={() => setEditModalVisible(false)}>
                    <Spin spinning={editModalLoading}>
                        <Form ref={orderModalForm} labelCol={{ span: 6 }} disabled={true}>
                            <Form.Item label="訂單編號" shouldUpdate>
                                {({ getFieldValue }) => getFieldValue('serialno') ?? '無'}
                            </Form.Item>
                            <Form.Item label="訂單來源" name="source">
                                <Select>
                                    <Select.Option value="internal">內部</Select.Option>
                                    <Select.Option value="kkday">KKDay</Select.Option>
                                    <Select.Option value="klook">KLook</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="使用者" name="renteruser_id">
                                <AdminUserSelect placeholder="請輸使用者 Email 或手機查詢" />
                            </Form.Item>
                            <Form.Item label="承租者姓名" name="rentername" rules={[{ required: true, message: '請輸入承租者姓名' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="承租者電話" name="rentercellphone" rules={[{ required: true, message: '請輸入承租者電話' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="預計取還車時間" name="expecttime">
                                <DatePicker.RangePicker showTime={true} format="YYYY-MM-DD HH:mm" />
                            </Form.Item>
                            <Form.Item label="承租者備註" name="renternote">
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label="訂單備註" name="processmemo">
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item>
                                <Row>
                                    <Col xs={24} md={12}>
                                        <Form.Item labelCol={{ span: 8 }} label="狀態">
                                            <OrderStateBadge state={editModalData.state} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="結帳狀態" shouldUpdate>
                                            {({ getFieldValue }) => <OrderSettledStateBadge state={getFieldValue('settledstate')} />}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="實際取車時間" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('actualpickuptime') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="實際還車時間" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('actualreturntime') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="處理人員" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('modifieduser')?.name ?? '無'}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item labelCol={{ span: 8 }} label="預計金額" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('expecttotalamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="實際金額" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('totalamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="已折扣" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('allowanceamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="已收" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('receivedamount') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="應收帳款" shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('accountsreceivable') ?? '無'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="發票號碼" hidden={true} shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('receiptnumber') ?? '未開立'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="發票日期" hidden={true} shouldUpdate>
                                            {({ getFieldValue }) => getFieldValue('receiptdate') ? moment(getFieldValue('receiptdate')).format("YYYY-MM-DD HH:mm:ss") : '未開立'}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="建立日期" shouldUpdate>
                                            {({ getFieldValue }) => moment(getFieldValue('created_at')).format("YYYY-MM-DD HH:mm:ss")}
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 8 }} label="修改日期" shouldUpdate>
                                            {({ getFieldValue }) => moment(getFieldValue('updated_at')).format("YYYY-MM-DD HH:mm:ss")}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Form.List name="items" initialValue={[]}>
                                {(fields, { add, remove }, { errors }) => {
                                    // console.log(fields);
                                    return (
                                        <List bordered>
                                            {fields.length === 0 && <Empty style={{ padding: 10 }} description="請先選擇車輛" />}
                                            {fields.map((field, index) => {
                                                const items = orderModalForm.current.getFieldValue('items');
                                                const item = items[index];
                                                const imageUrl = TourniiAPI.getImageHostUrl(item.vehiclespec.photos[0].thumbnailfileurl);
                                                return (
                                                    <List.Item key={field.key} style={{ cursor: 'pointer' }}>
                                                        <Row style={{ flex: 1 }} gutter={[5, 5]}>
                                                            <Col xs={24} lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Image
                                                                    style={{ width: 100, aspectRatio: 1, objectFit: 'cover' }}
                                                                    src={imageUrl}
                                                                    preview={false}
                                                                />
                                                            </Col>
                                                            <Col xs={24} lg={14} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <h3>{item.vehiclespec.vehicletype.brand.name} {item.vehiclespec.name}</h3>
                                                                <Form.Item {...field} key={`${field.key}-vehiclespec`} name={[field.name, "vehiclespec_id"]} hidden={true} style={{ marginBottom: 0 }}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} lg={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Space>
                                                                    <Form.Item {...field} key={`${field.key}-quantity`} name={[field.name, "quantity"]} style={{ marginBottom: 0 }} onClick={(event) => event.stopPropagation()}>
                                                                        <InputNumber min={1} />
                                                                    </Form.Item>
                                                                    <Button>取還車</Button>
                                                                    {fields.length > 0 && <Button onClick={(event) => { remove(field.name); event.stopPropagation(); }} >刪除</Button>}
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )
                                            })}
                                        </List>
                                    )
                                }}
                            </Form.List>
                        </Form>
                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button type="primary" onClick={hideOrderModal}>送出</Button>
                        </div>
                    </Spin>
                </Drawer>
            </Spin>
            <Modal title="匯入訂單" open={showImportModal} onCancel={handleHideImportOrdersModal} onOk={handleImportOrder} confirmLoading={importLoading}>
                <Space direction="vertical">
                    <div>
                        請參考 <a href="/orders-import-sample.xlsx">匯入檔案範本</a>
                    </div>
                    <Upload beforeUpload={handleAddFile} onRemove={handleRemoveFile} fileList={fileList}>
                        <Button>上傳檔案</Button>
                    </Upload>
                </Space>
            </Modal>
            <Drawer title="付款記錄" size="large" open={showPaymentLogModal.visible} onClose={handleHidePaymentLogModal}>
                <Form>
                    <Form.Item label="付款類型">
                        <Select style={{ width: 150 }} onChange={handleSelectRefundPayType} value={selectedPayType} allowClear>
                            <Select.Option value="cash">現金</Select.Option>
                            <Select.Option value="creditcard">信用卡</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
                <Spin spinning={paymentLogsLoading}>
                    <Table
                        columns={[
                            {
                                dataIndex: 'date',
                                title: '日期',
                            },
                            {
                                dataIndex: 'action',
                                title: '動作',
                            },
                            {
                                dataIndex: 'salesamountdiff',
                                title: '銷售差額',
                                align: 'right',
                                render: (value) => value.toLocaleString()
                            },
                            {
                                dataIndex: 'receivedamountdiff',
                                title: '已收差額',
                                align: 'right',
                                render: (value) => value.toLocaleString()
                            },
                            {
                                dataIndex: 'accountsreceivable',
                                title: '應收',
                                align: 'right',
                                render: (value) => value.toLocaleString()
                            },
                            {
                                dataIndex: 'paytype',
                                title: '付款方式',
                                render: (value) => {
                                    switch (value) {
                                        case 'cash': return <Tag color="gold">現金</Tag>;
                                        case 'creditcard': return <Tag color="blue">信用卡</Tag>;
                                        default: return <Tag color="red">其他</Tag>;
                                    }
                                }
                            },
                        ]}
                        dataSource={paymentLogs.filter((row) => {
                            if (selectedPayType) {
                                return row.paytype === selectedPayType
                            }
                            return true;
                        })}
                        rowSelection={{
                            type: 'radio',
                            onChange: (selectedRowKeys, selectedRows) => {
                                const [firstKey,] = selectedRowKeys
                                setSelectedPaymentLogId(firstKey);
                            },
                            getCheckboxProps: (record) => ({
                                disabled: false,
                                // Column configuration not to be checked
                                name: 'orderId',
                            }),
                        }}
                        rowKey="id" scroll={{ x: 700 }} pagination={false}
                    />
                </Spin>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" loading={refundLoading} onClick={handleRefund}>退款</Button>
                </div>
            </Drawer>
        </ContentLayout>
    )
}

export default OrdersPage