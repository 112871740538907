export const DATETYPE_LIMIT_AMOUNTS = [
    { col: 'limitamount6', unit: '6小時' },
    { col: 'limitamount8', unit: '8小時' },
    { col: 'limitamount24', unit: '24小時' }
]

export const getMinPriceDateType = (vehicleSpecInv) => {
    const dateTypes = vehicleSpecInv.datetypes;
    let minPrice = Number.MAX_SAFE_INTEGER;
    let result = {};
    dateTypes.forEach(dateType => {
        if (dateType.hourprice < minPrice) {
            minPrice = dateType.hourprice;
            result = dateType;
        }
    });
    return result;
}