import React, { useContext } from "react";
import moment from 'moment';

export const orderCartContextDefault = {
    addOrderAfterLogin: false,
    storeId: null,
    starttime: null,
    endtime: null,
    items: [],
    updateTime: null,
    renterNote: null,
}
export const orderCartActionTypes = {
    CLEAR: "CLEAR",
    SET_STORE: "SET_STORE",
    SET_PERIOD: "SET_PERIOD",
    SET_ADD_ORDER_AFTER_LOGIN: "SET_ADD_ORDER_AFTER_LOGIN",
    ADD_ITEM: "ADD_ITEM",
    REMOVE_ITEM: "REMOVE_ITEM",
    UPDATE_ITEM_PRICE: "UPDATE_ITEM_PRICE",
    UPDATE_ITEM_QTY: "UPDATE_ITEM_QTY",
    CHECK_ITEM: "CHECK_ITEM",
    UPDATE_RENTER_NOTE: "UPDATE_RENTER_NOTE",
}

export const getOrderCartInitialContext = () => {
    const orderCartJson = localStorage.getItem('orderCart');
    try {
        if (!orderCartJson) {
            return orderCartContextDefault
        }

        const orderCart = JSON.parse(orderCartJson);
        console.log("Load orderCart from localStorage", orderCart);

        const updateTime = orderCart.updateTime;
        if (moment().diff(moment(updateTime), "minute") > 60 * 3) {
            console.log("orderCart date overdue, skipped.");
            localStorage.removeItem('orderCart');
            return orderCartContextDefault;
        }
        return orderCart;
    } catch (error) {
        console.log("Load orderCart failed, use default.");
        localStorage.removeItem('orderCart');
        return orderCartContextDefault;
    }
}

export const orderCartActions = (dispatch) => {
    return {
        setStore: (storeId) => {
            dispatch({ type: orderCartActionTypes.SET_STORE, storeId });
        },
        setPeriod: (period) => {
            dispatch({ type: orderCartActionTypes.SET_PERIOD, period });
        },
        clear: () => {
            dispatch({ type: orderCartActionTypes.CLEAR });
        },
        markAddOrderAfterLogin: () => {
            dispatch({ type: orderCartActionTypes.SET_ADD_ORDER_AFTER_LOGIN, value: true });
        },
        clearAddOrderAfterLogin: () => {
            dispatch({ type: orderCartActionTypes.SET_ADD_ORDER_AFTER_LOGIN, value: false });
        },
        addItem: (detail, quantity) => {
            dispatch({ type: orderCartActionTypes.ADD_ITEM, detail, quantity });
        },
        removeItem: (index) => {
            dispatch({ type: orderCartActionTypes.REMOVE_ITEM, index });
        },
        checkItem: (index, value) => {
            dispatch({ type: orderCartActionTypes.CHECK_ITEM, index, value });
        },
        updateItemPrice: (index, value) => {
            dispatch({ type: orderCartActionTypes.UPDATE_ITEM_PRICE, index, value });
        },
        updateItemQty: (index, value) => {
            dispatch({ type: orderCartActionTypes.UPDATE_ITEM_QTY, index, value });
        },
        updateRenterNote: (value) => {
            dispatch({ type: orderCartActionTypes.UPDATE_RENTER_NOTE, value });
        },
    }
}
export const orderCartReducer = (state, action) => {
    const newOrderCart = { ...state };
    const { index, value } = action;

    // console.log("orderCartReducer", state, action);
    switch (action.type) {
        default:
            return state;
        case orderCartActionTypes.SET_STORE:
            newOrderCart.storeId = action.storeId;
            break;
        case orderCartActionTypes.SET_PERIOD:
            if (action.period) {
                const [startTime, endTime] = action.period;
                newOrderCart.starttime = startTime;
                newOrderCart.endtime = endTime;
            } else {
                newOrderCart.starttime = null;
                newOrderCart.endtime = null;
            }
            break;
        case orderCartActionTypes.CLEAR:
            newOrderCart.items = [];
            newOrderCart.storeId = null;
            newOrderCart.starttime = null;
            newOrderCart.endtime = null;
            newOrderCart.renterNote = null;
            break;
        case orderCartActionTypes.SET_ADD_ORDER_AFTER_LOGIN:
            newOrderCart.addOrderAfterLogin = !!value;
            break;
        case orderCartActionTypes.ADD_ITEM:
            const { detail, quantity } = action;
            const found = newOrderCart.items.find((item) => item.vehiclespec_id === detail.vehiclespec_id);
            if (found) {
                found.quantity += Number.parseInt(quantity);
            } else {
                newOrderCart.items.push({
                    //data: { ...detail },
                    vehiclespecinv_id: detail.id,
                    vehiclespec_id: detail.vehiclespec_id,
                    quantity: Number.parseInt(quantity),
                    //price: detail.price,
                    checked: true,
                });
            }
            break;
        case orderCartActionTypes.REMOVE_ITEM:
            newOrderCart.items.splice(index, 1);
            if (newOrderCart.items.length === 0) {
                newOrderCart.storeId = null;
            }
            break;
        case orderCartActionTypes.CHECK_ITEM:
            newOrderCart.items[index].checked = value;
            break;
        case orderCartActionTypes.UPDATE_ITEM_PRICE:
            newOrderCart.items[index].price = value;
            break;
        case orderCartActionTypes.UPDATE_ITEM_QTY:
            newOrderCart.items[index].quantity = value;
            break;
        case orderCartActionTypes.UPDATE_RENTER_NOTE:
            newOrderCart.renterNote = value;
            break;
    }
    newOrderCart.updateTime = moment().toISOString();
    console.log("Save orderCart to localStorage", newOrderCart);
    localStorage.setItem('orderCart', JSON.stringify(newOrderCart));
    return newOrderCart;
}
export const initOrderCart = (orderCart, dispatch) => {
    return {
        orderCart,
        actions: orderCartActions(dispatch)
    };
}
export const useOrderCart = () => {
    const { orderCart, actions } = useContext(OrderCartContext);
    return [orderCart, actions];
}

export const OrderCartContext = React.createContext({ orderCart: orderCartContextDefault, actions: {} });
