export function getFileNameFromResp(response) {
    // 取得檔案名稱
    console.log("getFileNameFromResp", response);
    const contentDisposition = response.headers['content-disposition'];
    const fileName = contentDisposition?.split('filename=')[1].split(';')[0].replace(/"/g, '');
    return fileName;
}
export function getBaseName(fileName) {
    return fileName.split(".").slice(0, -1).join(".");
}
export function getFileExt(fileName) {
    // 取得檔案副檔名
    const ext = fileName?.split('.').pop();
    return ext;
}
export function createObjectUrlFromResp(response, specifyFileName) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const fileName = getFileNameFromResp(response);
    const baseName = getBaseName(specifyFileName) || getBaseName(fileName);
    const fileExt = getFileExt(fileName) || getFileExt(specifyFileName);
    const newName = `${baseName}.${fileExt}`;
    link.setAttribute('download', newName);
    console.log("Download file ", newName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}