import { Button, Card, Col, Empty, Row, Space, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TourniiAPI, { ORDERSTATE_NEW } from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout'
import moment from 'moment';
import EmptyRenterResult from '../../components/EmptyRenterResult';
import OrderStateBadge from '../../components/OrderStateBadge';
import OrderSettledStateBadge from '../../components/OrderSettledStateBadge';
import OrderStateRadioGroupButton from '../../components/OrderStateRadioGroupButton';
import PaymentDrawer from './components/PaymentDrawer';
import { validOrder, validPayment, validPickup, validReturn } from '../../utils/order';
import { toCurrencyFormat } from '../../utils/currency';

function OrderListPage() {
  const user = TourniiAPI.auth.getLoginUser();
  const renterId = user ? user.renter_id : null;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [detailFilterState, setDetailFilterState] = useState(ORDERSTATE_NEW);
  const navigate = useNavigate();
  const [paymentDrawer, setPaymentDrawer] = useState({
    open: false,
    orderId: null,
  });

  const refresh = useCallback(() => {
    if (!renterId) return;
    setLoading(true);
    TourniiAPI.renter.renters.orders.index(renterId, {}, 1, 999).then((response) => {
      const result = response.data;
      setDetails(result.data);
    }).finally(() => {
      setLoading(false);
    });
  }, [renterId]);

  useEffect(() => {
    refresh();
  }, [refresh])

  console.log(details);
  const handleChangeFilter = (event) => {
    setDetailFilterState(event.target.value);
  }
  const getFilteredDetails = () => {
    let result = details;
    if (detailFilterState) {
      result = details.filter((detail) => {
        return detail.state === detailFilterState
      });
    }
    result = result.sort((a, b) => moment().diff(moment(a.expectpickuptime), 'days') - moment().diff(moment(b.expectpickuptime), 'days'));
    return result;
  }
  const handleCardClick = (row) => {
    navigate(`/orders/${row.id}`);
  }
  const filteredDetails = getFilteredDetails();

  const handleShowPayment = (id) => {
    setPaymentDrawer({
      open: true,
      orderId: id
    });
  }
  const handleOkPaymentDrawer = () => {
    setPaymentDrawer({
      open: false,
      orderId: null
    });
    refresh();
  }
  const handleClosePaymentOrder = () => {
    setPaymentDrawer({
      open: false,
      orderId: null
    });
  }
  const getActionButton = (order) => {
    let buttons = [];
    if (validPayment(order)) {
      buttons.push(<Button onClick={(e) => {
        handleShowPayment(order.id);
        e.stopPropagation();
      }}>付款</Button>);
    }
    if (validPickup(order)) {
      buttons.push(<Button type="primary">取車</Button>);
    }
    if (validReturn(order)) {
      buttons.push(<Button type="primary">還車</Button>);
    }
    return buttons;
  }
  return (
    <ContentLayout title="我的訂單">
      <Spin spinning={loading}>
        <EmptyRenterResult renterId={renterId}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <OrderStateRadioGroupButton details={details} onChange={handleChangeFilter} />
            </Col>
            <Col span={24} style={{}}>
              {
                filteredDetails.length === 0 ?
                  <Empty /> :
                  filteredDetails.map((row, index) =>
                    <Card key={row.id} style={{ marginBottom: 10, fontSize: '0.9rem', opacity: !validOrder(row) ? 0.7 : 1 }} onClick={() => handleCardClick(row)}>
                      <Row gutter={[10, 10]}>
                        <Col span={16}>
                          <h3 style={{ margin: 0 }}>{moment(row.created_at).format("LLL")}</h3>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                          <h3 style={{ margin: 0 }}>
                            <OrderStateBadge state={row.state} />
                          </h3>
                        </Col>
                        <Col span={24}>
                          <hr />
                        </Col>
                        <Col span={18} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Row gutter={[5, 5]}>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>取車時間</span>：{row.expectpickuptime}</Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>還車時間</span>：{row.expectreturntime}</Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>取車店家</span>：{row.store.company.name} {row.store.name}</Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>取車地點</span>：{row.store.address}</Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>訂單編號</span>：{row.serialno}</Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>承租備註</span>：{row.renternote}</Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>付款狀態</span>：<OrderSettledStateBadge state={row.settledstate} /></Col>
                            <Col span={24}><span style={{ fontWeight: 'bold' }}>總額</span>：${toCurrencyFormat(row.totalamount)}</Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <img alt="" style={{ width: '100%' }} src={TourniiAPI.getImageHostUrl(row.cover)} />
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Space>
                            {getActionButton(row)}
                          </Space>
                        </Col>
                      </Row>
                    </Card>)
              }
            </Col>
          </Row>
          <PaymentDrawer open={paymentDrawer.open} orderId={paymentDrawer.orderId} renterId={renterId} onOk={handleOkPaymentDrawer} onClose={handleClosePaymentOrder} />
        </EmptyRenterResult>
      </Spin>
    </ContentLayout>
  )
}

export default OrderListPage