import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import TourniiAPI from '../../../api/TourniiAPI'
import OrderItemDrawer from '../../../components/OrderItemDrawer';

function RenterOrderItemDrawer({
    renterId,
    orderId,
    itemId,
    ...props
}, ref) {
    const drawerRef = useRef();
    useImperativeHandle(ref, () => ({
        refresh: drawerRef.current.refresh()
    }));

    const handleItemShow = () => TourniiAPI.renter.renters.orders.items.show(renterId, orderId, itemId)
    const handleItemVehicleIndex = () => TourniiAPI.renter.renters.orders.items.vehicles.index(renterId, orderId, itemId, {}, 1, 999);
    return (
        <OrderItemDrawer
            ref={drawerRef}
            onItemShow={handleItemShow}
            onItemVehicleIndex={handleItemVehicleIndex}
            {...props}
        />
    )
}

export default forwardRef(RenterOrderItemDrawer)