import { Button, Card, Col, Descriptions, List, notification, Popconfirm, Row, Skeleton, Space, Spin, Grid } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import TourniiAPI from '../../api/TourniiAPI';
import { validCancel, validPayment, validPickup, validReturn } from '../../utils/order';
import { toCurrencyFormat } from '../../utils/currency';
import moment from 'moment';
import './OrderDetailPage.css';

import ContentLayout from '../ContentLayout'
import PaymentDrawer from './components/PaymentDrawer';
import RenterOrderItemDrawer from './components/RenterOrderItemDrawer';
import RenterOrderItemVehicleDrawer from './components/RenterOrderItemVehicleDrawer';
import OdoMeterUploadDrawer from '../../components/OdoMeterUploadDrawer';
import VehiclePhotoUploadDrawer from '../../components/VehiclePhotoUploadDrawer';
import CredentialUploadDrawer from '../../components/CredentialUploadDrawer';
import SignatureDrawer from '../../components/SignatureDrawer';
import VehicleStateTag from '../../components/VehicleStateTag';
import OrderStateBadge from '../../components/OrderStateBadge';
import OrderSettledStateBadge from '../../components/OrderSettledStateBadge';

const { useBreakpoint } = Grid;

function OrderDetailPage() {
    const breakpoint = useBreakpoint();
    const renterId = TourniiAPI.auth.getLoginUser() ? TourniiAPI.auth.getLoginUser().renter_id : null;
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState();
    const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
    const [selectedOrderItemVehicle, setSelectedOrderItemVehicle] = useState();

    const refresh = useCallback(() => {
        setLoading(true);
        TourniiAPI.renter.renters.orders.show(renterId, orderId).then((response) => {
            const result = response.data;
            setOrder(result);
            console.log(result);
        }).finally(() => {
            setLoading(false);
        });
    }, [renterId, orderId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleCancelOrder = (id) => {
        setLoading(true);
        TourniiAPI.renter.renters.orders.destroy(renterId, id).then((response) => {
            notification.info({ message: '取消成功' });
            refresh();
        }).finally(() => {
            setLoading(false);
        });
    }
    const minutesToTimeStr = (minutes) => {
        const min = Math.floor(minutes % 60);
        const hour = Math.floor(minutes / 60 % 24);
        const day = Math.floor(minutes / 60 / 24);
        return `${day} 天 ${hour} 時 ${min} 分 `;
    }
    const getEstReturnCountDown = () => {
        if (!order.expectreturntime) return '無';
        const minutes = moment(order.expectreturntime, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'minutes');
        if (minutes < 0) {
            return `已逾期 ${minutesToTimeStr(-minutes)}`;
        } else {
            return `${minutesToTimeStr(minutes)}`;
        }
    }
    const getVehiclesForItem = (itemId) => {
        const vehicles = order.vehicles;
        if (!vehicles) return [];
        const foundVehicles = vehicles.filter((row) => row.orderitem_id === itemId);
        return foundVehicles;
    }
    const handleShowPaymentDrawer = () => {
        if (!validPayment(order)) {
            notification.warn({ message: '該訂單並非新訂單或已過預約時間，無法進行付款' });
            return;
        }
        setShowPaymentDrawer(true);
    }
    const handleHidePaymentDrawer = () => {
        setShowPaymentDrawer(false);
    }
    const handleOkPaymentDrawer = () => {
        setShowPaymentDrawer(false);
        refresh()
    }

    // OrderitemDrawer
    const orderItemDrawer = useRef();
    const [showOrderItemDrawer, setShowOrderItemDrawer] = useState(false);
    const [selectedOrderItem, setSelectedOrderItem] = useState({});

    // ItemVehicleDrawer
    const orderItemVehicleDrawer = useRef();
    const [showItemVehicleDrawer, setShowItemVehicleDrawer] = useState({ visible: false, vehicleId: null });

    // SignatureDrawer
    const [showSignatureDrawer, setShowSignatureDrawer] = useState({ visible: false, contract: '', dataUrl: null });

    // CredentialUploadDrawer
    const [showCredentialUpload, setShowCredentialUpload] = useState(false);
    const [credentialUploadType, setCredentialUploadType] = useState();
    const [credentialUploadSide, setCredentialUploadSide] = useState();
    const [credentialSrc, setCredentialSrc] = useState();

    // OdoMeterUploadDrawer
    const [showOdoMeterUpload, setShowOdoMeterUpload] = useState(false);
    const [odoMeterUploadType, setOdoMeterUploadType] = useState();
    const [odoMeterValue, setOdoMeterValue] = useState();
    const [odoMeterSrc, setOdoMeterSrc] = useState();

    // VehiclePhotoUploadDrawer
    const [showVehiclePhotoUpload, setShowVehiclePhotoUpload] = useState(false);
    const [vehiclePhotoUploadType, setVehiclePhotoUploadType] = useState();
    const [vehiclePhotoSrc, setVehiclePhotoSrc] = useState();


    const handleShowOrderItem = (item) => {
        console.log("handleShowOrderItem", item);
        setShowOrderItemDrawer(true);
        setSelectedOrderItem({
            ...item
        });
    }
    const handleHideOrderItem = () => {
        setShowOrderItemDrawer(false);
    }

    // 車輛 Drawer
    const handleShowItemVehicleDrawer = (vehicleId) => {
        const itemId = selectedOrderItem.id;
        if (!renterId) {
            notification.warn({ message: '無承租者' });
            return;
        }
        if (!orderId) {
            notification.warn({ message: '尚未新增訂單無法查看訂單項目' });
            return;
        }
        if (!itemId) {
            notification.warn({ message: '尚未新增訂單項目無法查看車輛' });
            return;
        }
        setShowItemVehicleDrawer({
            visible: true,
            vehicleId: vehicleId,
        });
        console.log("handleShowItemVehicleDrawer", vehicleId);
    }

    const handleHideItemVehicleDrawer = () => {
        setShowItemVehicleDrawer({
            visible: false,
        });
    }

    // 簽名上傳
    const handleShowSignature = async (itemVehicle) => {
        if (!itemVehicle) {
            return;
        }
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        console.log("handleShowSignature", itemVehicle);
        setSelectedOrderItemVehicle(itemVehicle);
        const dataUrl = await TourniiAPI.renter.renters.orders.items.vehicles.signature.show(renterId, orderId, itemId, vehicleId).then((response) => {
            return response?.data?.filedata;
        })
        const contractResult = await TourniiAPI.renter.stores.contracts.latest(order.store.id).then((response) => {
            return response?.data;
        });
        setShowSignatureDrawer({ visible: true, contract: contractResult, dataUrl: dataUrl });
    }

    const handleHideSignature = () => {
        setShowSignatureDrawer({ visible: false, contract: null, dataUrl: null });
    }

    const handleFinishSignature = (dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        // console.log("save signature size", dataUrl.length);
        // console.log('handleFinishSig', itemId, vehicleId, file, dataUrl);
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: 'test',
            imagefile: file,
            // storecontract_id: 1,
        };
        TourniiAPI.renter.renters.orders.items.vehicles.signature.store(renterId, orderId, itemId, vehicleId, data).then((response) => {
            notification.info({ message: '簽名儲存成功' });
            // const result = response.data;
            // setSigDataUrl(result.filedata); // TODO 取不到資料
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowSignatureDrawer({ ...showSignatureDrawer, visible: false, contract: null });
        });
    }

    const handleDeleteSignature = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.renter.renters.orders.items.vehicles.signature.destroy(renterId, orderId, itemId, vehicleId).then((response) => {
            notification.info({ message: '簽名刪除成功' });
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            handleHideSignature();
        });
    }

    // 證件上傳
    const handleShowCredentialUpload = (itemVehicle, type, side) => {
        if (!itemVehicle) {
            return;
        }
        console.log("handleshowCredentialUpload", itemVehicle, type);
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        setSelectedOrderItemVehicle(itemVehicle);
        setCredentialUploadType(type);
        setCredentialUploadSide(side);
        TourniiAPI.renter.renters.orders.items.vehicles.credentials.show(renterId, orderId, itemId, vehicleId, type, side).then((response) => {
            const result = response.data;
            setCredentialSrc(result.filedata);
        }).catch((error) => {
            setCredentialSrc(null);
        }).finally(() => {
            setShowCredentialUpload(true);
        });
    }

    const handleHideCredentialUpload = () => {
        setShowCredentialUpload(false);
        setCredentialUploadType();
        setCredentialSrc(null);
    }

    const handleFinishCredentialUpload = (dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        // console.log("handleFinishCredentialUpload", itemId, vehicleId, dataUrl);
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        if (!dataUrl) {
            notification.warn({ message: '請上傳照片' })
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: 'test',
            imagefile: file,
        }
        TourniiAPI.renter.renters.orders.items.vehicles.credentials.store(renterId, orderId, itemId, vehicleId, credentialUploadType, credentialUploadSide, data).then((response) => {
            notification.info({ message: '證件上傳成功' })
            const result = response.data;
            console.log(result);
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowCredentialUpload(false);
            setCredentialSrc(null);
        })
    }

    const handleDeleteCredentialUpload = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.renter.renters.orders.items.vehicles.credentials.destroy(renterId, orderId, itemId, vehicleId, credentialUploadType, credentialUploadSide).then((response) => {
            notification.info({ message: '證件刪除成功' })
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowCredentialUpload(false);
            setCredentialSrc(null);
        });
    }

    // 里程數上傳
    const handleShowOdoMeterUpload = (itemVehicle, type) => {
        if (!itemVehicle) {
            return;
        }
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        console.log("handleShowOdoMeterUpload", itemVehicle, type);
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        setSelectedOrderItemVehicle(itemVehicle);
        TourniiAPI.renter.renters.orders.items.vehicles.odometer.show(renterId, orderId, itemId, vehicleId, type).then((response) => {
            const result = response.data;
            setOdoMeterSrc(result.filedata);
            setOdoMeterValue(result.odometer);
        }).finally(() => {
            setShowOdoMeterUpload(true);
            setOdoMeterUploadType(type);
        });
    }

    const handleHideOdoMeterUpload = () => {
        setShowOdoMeterUpload(false);
        setOdoMeterSrc(null);
        setOdoMeterValue();
        setOdoMeterUploadType();
    }

    const handleFinishOdoMeterUpload = (odometer, dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        // console.log("handleFinishUploadOdoMeter", itemId, vehicleId, dataUrl);
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: '里程',
            imagefile: file,
            odometerunit: 'km',
            odometer: odometer,
        };
        TourniiAPI.renter.renters.orders.items.vehicles.odometer.store(renterId, orderId, itemId, vehicleId, odoMeterUploadType, data).then((response) => {
            notification.info({ message: '記錄里程成功' })
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowOdoMeterUpload(false);
            setOdoMeterSrc(null);
            setOdoMeterValue();
            setOdoMeterUploadType();
        });
    }

    const handleDeleteOdoMeterUpload = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.renter.renters.orders.items.vehicles.odometer.destroy(renterId, orderId, itemId, vehicleId, odoMeterUploadType).then((response) => {
            notification.info({ message: '車輛照片刪除成功' });
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setShowOdoMeterUpload(false);
            setOdoMeterSrc(null);
            setOdoMeterValue();
            setOdoMeterUploadType();
        });
    }

    // 車輛照片上傳
    const handleShowVehiclePhotoUpload = (itemVehicle, type) => {
        if (!itemVehicle) {
            return;
        }
        // console.log("handleShowVehiclePhotoUpload", itemVehicle, type);
        const itemId = selectedOrderItem.id;
        const vehicleId = itemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        setSelectedOrderItemVehicle(itemVehicle);
        setVehiclePhotoUploadType(type);
        setVehiclePhotoSrc(null);
        TourniiAPI.renter.renters.orders.items.vehicles.photos.show(renterId, orderId, itemId, vehicleId, type).then((response) => {
            const result = response.data;
            // console.log("handleShowVehiclePhotoUpload", result.filedata, response.status);
            setVehiclePhotoSrc(result.filedata);
        }).finally(() => {
            setShowVehiclePhotoUpload(true);
        });
    }

    const handleHideVehiclePhotoUpload = () => {
        setVehiclePhotoSrc(null);
        setShowVehiclePhotoUpload(false);
    }

    const handleFinishVehiclePhotoUpload = (dataUrl) => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        // console.log("handleFinishVehiclePhotoUpload", itemId, vehicleId, dataUrl);
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: vehiclePhotoUploadType,
            imagefile: file,
        };
        TourniiAPI.renter.renters.orders.items.vehicles.photos.store(renterId, orderId, itemId, vehicleId, vehiclePhotoUploadType, data).then((response) => {
            notification.info({ message: '車輛照片上傳成功' })
            const result = response.data;
            console.log(result);
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setVehiclePhotoSrc(null);
            setShowVehiclePhotoUpload(false);
        });
    }

    const handleDeleteVehiclePhotoUpload = () => {
        const itemId = selectedOrderItem.id;
        const vehicleId = selectedOrderItemVehicle.id;
        if (!renterId || !orderId || !itemId || !vehicleId) {
            notification.warn({ message: '缺少資料 renterId, editId, itemId, vehicleId' });
            return;
        }
        TourniiAPI.renter.renters.orders.items.vehicles.photos.destroy(renterId, orderId, itemId, vehicleId, vehiclePhotoUploadType).then((response) => {
            notification.info({ message: '車輛照片刪除成功' })
            if (orderItemVehicleDrawer.current) {
                orderItemVehicleDrawer.current.refresh();
            }
        }).finally(() => {
            setVehiclePhotoSrc(null);
            setShowVehiclePhotoUpload(false);
        });
    }

    return (
        <ContentLayout title="訂單明細">
            {!order ? <Skeleton /> :
                <Spin spinning={loading}>
                    <Card headStyle={{ fontWeight: 'bold' }} title={`訂單資訊 ${order.serialno}`} style={{ marginBottom: 10, fontSize: '0.9rem' }}>
                        <Row gutter={[10, 10]}>
                            <Col span={24}>
                                <Row gutter={[5, 15]}>
                                    <Col xs={24}>
                                        <Descriptions size="small" bordered column={{ xs: 1, md: 2 }} labelStyle={{ width: 100 }} layout={breakpoint.xl ? 'horizontal' : 'vertical'}>
                                            <Descriptions.Item label="訂單時間">{moment(order.created_at).format("LLL")}</Descriptions.Item>
                                            <Descriptions.Item label="訂單狀態"><OrderStateBadge state={order.state} /></Descriptions.Item>
                                            <Descriptions.Item label="取車店家">{order.store.company.name} {order.store.name}</Descriptions.Item>
                                            <Descriptions.Item label="取車地點">{order.store.address}</Descriptions.Item>
                                            <Descriptions.Item label="取車時間">{order.expectpickuptime}</Descriptions.Item>
                                            <Descriptions.Item label="還車時間">
                                                {order.expectreturntime}
                                                {validReturn(order) && <div>剩下 {getEstReturnCountDown()}</div>}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="預計金額">{toCurrencyFormat(order.expecttotalamount)}</Descriptions.Item>
                                            <Descriptions.Item label="承租備註">{order.renternote}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col xs={24}>
                                        <Descriptions title="結帳資訊" size="small" bordered column={{ xs: 1, md: 2 }} labelStyle={{ width: 100 }}>
                                            <Descriptions.Item label="取車時間">{order.actualpickuptime ?? '尚未取車'}</Descriptions.Item>
                                            <Descriptions.Item label="還車時間">{order.actualreturntime ?? '尚未還車'}</Descriptions.Item>
                                            <Descriptions.Item label="實際金額">{toCurrencyFormat(order.totalamount)}</Descriptions.Item>
                                            <Descriptions.Item label="尚欠金額">{toCurrencyFormat(order.accountsreceivable)}</Descriptions.Item>
                                            <Descriptions.Item label="結帳狀態">
                                                <OrderSettledStateBadge state={order.settledstate} />
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Space>
                                    <Popconfirm title="是否確定要取消這筆訂單?" disabled={!validCancel(order)} onConfirm={(event) => {
                                        handleCancelOrder(order.id);
                                        event.stopPropagation();
                                    }} onCancel={(event) => { event.stopPropagation() }}>
                                        <Button disabled={!validCancel(order)}>取消</Button>
                                    </Popconfirm>
                                    {validPayment(order) && <Button type="primary" onClick={handleShowPaymentDrawer} disabled={!validPayment(order)}>付款</Button>}
                                    {validPickup(order) && <a href={`https://www.google.com/maps/search/?api=1&query=${order.store.address}`}>
                                        <Button type="primary">前往店家</Button>
                                    </a>}
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                    <Card headStyle={{ fontWeight: 'bold' }} title="明細" style={{ padding: 0 }}>
                        <List>
                            {order.items.map((orderItem) =>
                                <List.Item key={orderItem.id}>
                                    <Row gutter={[10, 10]} style={{ width: '100%' }}>
                                        <Col xs={8} md={4} style={{ textAlign: 'center' }}>
                                            <img
                                                style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
                                                alt={`${orderItem.vehiclespec.vehicletype.brand.name} ${orderItem.vehiclespec.name}`}
                                                src={TourniiAPI.getImageHostUrl(orderItem.vehiclespec.photos[0].thumbnailfileurl)} />
                                        </Col>
                                        <Col xs={16} md={20}>
                                            <Row style={{ fontSize: '0.9rem' }}>
                                                <Col xs={24} md={20}>
                                                    {orderItem.vehiclespec.vehicletype.brand.name} {orderItem.vehiclespec.name} x {orderItem.quantity}
                                                </Col>
                                                <Col xs={12} md={2} style={{ textAlign: 'right' }}></Col>
                                                <Col xs={12} md={2} style={{ textAlign: 'right' }}>${toCurrencyFormat(orderItem.subtotal)}</Col>
                                                <Col span={24}>
                                                    <ul style={{ marginLeft: -20 }}>
                                                        {getVehiclesForItem(orderItem.id).map((vehicle) => <li key={vehicle.id}>租借車牌號碼：{vehicle.vehicleplatenumber} <VehicleStateTag state={vehicle.state} /></li>)}
                                                    </ul>
                                                </Col>
                                                <Col span={24} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                                    <Button type="primary" onClick={(event) => {
                                                        handleShowOrderItem(orderItem);
                                                        event.stopPropagation();
                                                    }} disabled={!validPickup(order) && !validReturn(order)}>{validPickup(order) ? '取車' : '還車'}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        </List>
                    </Card>
                </Spin>
            }
            <PaymentDrawer open={showPaymentDrawer} orderId={orderId} renterId={renterId} onClose={handleHidePaymentDrawer} onOk={handleOkPaymentDrawer} />
            <RenterOrderItemDrawer
                ref={orderItemDrawer}
                renterId={renterId}
                orderId={orderId}
                itemId={selectedOrderItem.id}
                quantity={selectedOrderItem.quantity}
                visible={showOrderItemDrawer}
                onClickShowItemVehicle={handleShowItemVehicleDrawer}
                onClose={handleHideOrderItem} />
            <RenterOrderItemVehicleDrawer
                ref={orderItemVehicleDrawer}
                renterId={renterId}
                orderId={orderId}
                itemId={selectedOrderItem.id}
                vehicleId={showItemVehicleDrawer.vehicleId}
                visible={showItemVehicleDrawer.visible}
                onClose={handleHideItemVehicleDrawer}
                onClickOdoMeterUpload={handleShowOdoMeterUpload}
                onClickCredentialUpload={handleShowCredentialUpload}
                onClickSignature={handleShowSignature}
                onClickVehiclePhotoUpload={handleShowVehiclePhotoUpload}
                onDone={() => {
                    handleHideItemVehicleDrawer();
                    if (orderItemDrawer.current) {
                        orderItemDrawer.current.refresh();
                    }
                    refresh();
                }}
                onUpdate={() => {
                    if (orderItemDrawer.current) {
                        orderItemDrawer.current.refresh();
                    }
                    refresh();
                }}
            />
            <OdoMeterUploadDrawer
                visible={showOdoMeterUpload}
                value={odoMeterValue}
                photo={odoMeterSrc}
                onFinish={handleFinishOdoMeterUpload}
                onClose={handleHideOdoMeterUpload}
                onDelete={handleDeleteOdoMeterUpload}
            />
            <VehiclePhotoUploadDrawer
                visible={showVehiclePhotoUpload}
                value={vehiclePhotoSrc}
                onFinish={handleFinishVehiclePhotoUpload}
                onClose={handleHideVehiclePhotoUpload}
                onDelete={handleDeleteVehiclePhotoUpload}
            />
            <CredentialUploadDrawer
                visible={showCredentialUpload}
                value={credentialSrc}
                onFinish={handleFinishCredentialUpload}
                onClose={handleHideCredentialUpload}
                onDelete={handleDeleteCredentialUpload}
            />
            <SignatureDrawer
                visible={showSignatureDrawer.visible}
                contract={showSignatureDrawer.contract}
                dataUrl={showSignatureDrawer.dataUrl}
                onFinish={handleFinishSignature}
                onClose={handleHideSignature}
                onDelete={handleDeleteSignature}
            />
        </ContentLayout>
    )
}

export default OrderDetailPage