import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function EmptyRenterResult({ renterId, children }) {
    return (
        <>
            {!renterId ? <Result
                title="請先填寫租車資料方可查詢訂單"
                extra={
                    <Link to="/profile">
                        <Button type="primary" key="console">
                            基本資料
                        </Button>
                    </Link>
                }
            /> : children}
        </>)
}

export default EmptyRenterResult