import { Select } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';
import debounce from 'lodash/debounce';

function AdminUserSelect({ onChange, ...props }) {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const fetchRef = useRef(0);
    const debounceTimeout = 500;

    const handleSearch = useCallback((searchParams) => {
        // console.log("handleSearch", searchParams);
        setLoading(true);
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        TourniiAPI.admin.users.index(searchParams).then((response) => {
            if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
            }
            const result = response.data;
            setUsers(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleDebunceSearch = debounce(handleSearch, debounceTimeout);

    const handleChange = (value) => {
        // console.log("handleChange", value);
        if (onChange) {
            const found = users.find((row) => row.id === value);
            onChange(value, found);
        }
    }

    useEffect(() => {
        console.log(props.value);
        const fetchByValue = () => {
            const userId = props.value;
            handleSearch({ id: userId });
        }
        fetchByValue();
    }, [props.value, handleSearch])

    return (
        <Select loading={loading}
            showSearch={true}
            onSearch={(value) => handleDebunceSearch({ keyword: value })}
            onChange={handleChange}
            filterOption={false}
            onClear={handleChange}
            allowClear
            {...props}>
            {users.map((user) => <Select.Option key={user.id} value={user.id}>
                <>
                    <div>
                        <div>{user.name} {user.cellphone}</div>
                        <div><span style={{ fontSize: '0.6em', color: 'gray' }}>{user.email}</span></div>
                    </div>
                </></Select.Option>)}
        </Select>
    )
}

export default AdminUserSelect