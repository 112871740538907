import { Button, Col, Drawer, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select, Space, Spin, Table, Tag, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AdminCompanySelect from '../../components/AdminCompanySelect';
import RegionSelect from '../../components/RegionSelect';
import ContentLayout from '../ContentLayout'
import { InfoCircleOutlined } from '@ant-design/icons';

function StoresPage() {
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const [companyId, setCompanyId] = useState(null);

    // Edit Store Form
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModalMode, setEditModalMode] = useState('store');
    const [editModalId, setEditModalId] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);

    // Members Table
    const [editModalMembers, setEditModalMembers] = useState([]);
    const [editModalMemberLoading, setEditModalMemberLoading] = useState(false);
    const [editForm] = Form.useForm();

    // Edit Invite Member Modal
    const [editInviteMemberModalVisible, setEditInviteMemberModalVisible] = useState(false);
    const [editInviteMemberModalMode, setEditInviteMemberModalMode] = useState('store');
    const [editInviteMemberModalId, setEditInviteMemberModalId] = useState();
    const [editInviteMemberModalLoading, setEditInviteMemberModalLoading] = useState(false);
    const [inviteMemberForm] = Form.useForm();

    const refresh = useCallback(() => {
        setLoading(true);
        TourniiAPI.admin.stores.index({}, 1, 999).then((response) => {
            const result = response.data;
            //console.log("result", result);
            setDetail(result.data.filter((row) => !companyId || (companyId && row.company_id === companyId)));
        }).finally(() => {
            setLoading(false);
        });
    }, [companyId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleSelectCompany = (value) => {
        console.log("handleSelectCompany", value);
        setCompanyId(value);
    }
    const showAddModal = () => {
        setEditModalMode('store');
        setEditModalId(null);
        setEditModalVisible(true);
        setEditModalMembers([]);
        if (editForm) {
            editForm.resetFields();
            editForm.setFieldsValue({ company_id: companyId });
        }
    };
    const showUpdateModal = (id) => {
        if (!id) return;
        setEditModalMode('update');
        setEditModalLoading(true);
        setEditModalId(id);
        setEditModalVisible(true);
        TourniiAPI.admin.stores.show(id).then((response) => {
            const result = response.data;
            editForm.setFieldsValue(result);
            setEditModalMemberLoading(true);
            refreshMembers(id);
        }).finally(() => {
            setEditModalLoading(false);
        });

    };
    const hideModal = () => {
        setEditModalVisible(false);
        setEditModalMode('store');
        setEditModalLoading(false);
        setEditModalId(null);
        setEditModalMembers([]);
    }
    const handleSubmit = () => {
        editForm.submit();
    }
    const handleFinish = (values) => {
        console.log(values);
        if (editModalMode === 'store') {
            setEditModalLoading(true);
            TourniiAPI.admin.stores.store(values).then((response) => {
                notification.info({ message: '新增店家成功' });
            }).finally(() => {
                setEditModalLoading(false);
                refresh();
            });
        } else {
            setEditModalLoading(true);
            TourniiAPI.admin.stores.update(editModalId, values).then((response) => {
                notification.info({ message: '修改店家成功' });
            }).finally(() => {
                setEditModalLoading(false);
                refresh();
            });
        }
    };
    const handleDelete = (id) => {
        setLoading(true);
        TourniiAPI.admin.stores.destroy(id).then((response) => {
            notification.info({ message: '刪除店家成功' });
        }).finally(() => {
            setLoading(false);
            refresh();
        });
    }
    const columns = [
        {
            title: '車行',
            dataIndex: 'company',
            key: 'company',
            render: (company) => company.name,
        },
        {
            title: '店家名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '最低租賃 (時)',
            dataIndex: 'rentminhours',
            key: 'rentminhours',
            align: 'right',
        },
        {
            title: '自訂逾時門檻 (時)',
            dataIndex: 'overtimeexceedhours',
            key: 'overtimeexceedhours',
            align: 'right',
        },
        {
            title: '分潤比例',
            dataIndex: 'revenuesharerate',
            key: 'revenuesharerate',
            render: (value) => `${value * 100}%`,
            align: 'right',
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '統一編號',
            dataIndex: 'taxidnumber',
            key: 'taxidnumber',
        },
        {
            title: '聯絡人',
            dataIndex: 'contact',
            key: 'contact',
        },
        {
            title: '手機',
            dataIndex: 'contacttel',
            key: 'contacttel',
        },
        {
            title: '動作',
            fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => showUpdateModal(record.id)}>修改</Typography.Link>
                <Popconfirm title="您是否確定要刪除此店家?" onConfirm={() => handleDelete(record.id)}>
                    <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
            </Space>
        }
    ]

    const handleShowInviteMemberModal = useCallback((memberId) => {
        setEditInviteMemberModalVisible(true);
        if (memberId) {
            TourniiAPI.admin.stores.members.show(editModalId, memberId).then((response) => {
                const result = response.data;
                setEditInviteMemberModalMode('update');
                setEditInviteMemberModalId(memberId);
                inviteMemberForm.setFieldsValue(result);
            }).finally(() => {

            })
        } else {
            setEditInviteMemberModalMode('store');
        }

    }, [editModalId, inviteMemberForm]);

    const handleHideInviteMemberModal = () => {
        setEditInviteMemberModalVisible(false);
        inviteMemberForm.resetFields();
        setEditInviteMemberModalMode('store');
        setEditInviteMemberModalId();
    }
    const refreshMembers = useCallback((storeId) => {
        TourniiAPI.admin.stores.members.index(storeId || editModalId, {}, 1, 999).then((response) => {
            const result = response.data;
            setEditModalMembers(result.data);
        }).finally(() => {
            setEditModalMemberLoading(false);
        });
    }, [editModalId]);

    const handleInviteModalOk = () => {
        inviteMemberForm.submit();
    }
    const handleAddMember = (values) => {
        if (!editModalId) return;
        if (editInviteMemberModalMode === 'store') {
            setEditInviteMemberModalLoading(true);
            TourniiAPI.admin.stores.members.store(editModalId, values).then((response) => {
                notification.info({ message: '邀請成員成功' });
                refreshMembers();
                handleHideInviteMemberModal();
            }).finally(() => {
                setEditInviteMemberModalLoading(false);
            });
        } else {
            if (!editInviteMemberModalId) return;
            setEditInviteMemberModalLoading(true);
            TourniiAPI.admin.stores.members.update(editModalId, editInviteMemberModalId, values).then((response) => {
                notification.info({ message: '修改成員成功' });
                refreshMembers();
                handleHideInviteMemberModal();
            }).finally(() => {
                setEditInviteMemberModalLoading(false);
            });
        }
    }
    const handleDeleteMember = (memberId) => {
        if (!editModalId || !memberId) return;
        setEditModalMemberLoading(true);
        TourniiAPI.admin.stores.members.destroy(editModalId, memberId).then((response) => {
            notification.info({ message: '刪除成員成功' });
            refreshMembers();
        }).finally(() => {
            setEditModalMemberLoading(false);
        });
    }

    const memberColumns = [
        {
            dataIndex: 'email',
            title: '電子郵件'
        },
        {
            dataIndex: 'name',
            title: '名稱'
        },
        {
            dataIndex: 'cellphone',
            title: '手機'
        },
        {
            dataIndex: 'roles',
            title: '身份',
            render: (value) => {
                return String(value).split(",").map((role) => {
                    switch (role) {
                        case 'store_manager':
                            return <Tag key={role} color="blue">店長</Tag>;
                        case 'store_member':
                            return <Tag key={role} color="green">店員</Tag>;
                        default:
                            return <Tag key={role} color="red">不明</Tag>;
                    }
                })
            }
        },
        {
            dataIndex: 'state',
            title: '狀態',
            render: (value) => {
                //invited=已邀請, enabled=已啟用, disabled=已停用
                return String(value).split(",").map((role) => {
                    switch (role) {
                        case 'invited':
                            return <Tag key={role} color="blue">已邀請</Tag>;
                        case 'enabled':
                            return <Tag key={role} color="green">已啟用</Tag>;
                        case 'disabled':
                            return <Tag key={role} color="gray">已停用</Tag>;
                        default:
                            return <Tag key={role} color="red">不明</Tag>;
                    }
                })
            }
        },
        {
            title: '操作',
            render: (value, record) =>
                <Space>
                    <Typography.Link onClick={() => handleShowInviteMemberModal(record.id)}>修改</Typography.Link>
                    <Popconfirm title="是否確定刪除此成員" onConfirm={() => handleDeleteMember(record.id)}>
                        <Typography.Link>刪除</Typography.Link>
                    </Popconfirm>
                </Space>
        }
    ];
    return (
        <ContentLayout>
            <Spin spinning={loading}>
                <Form style={{ marginBottom: 20 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                            <Form.Item label="車行">
                                <AdminCompanySelect onSelect={handleSelectCompany} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={showAddModal}>新增</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table columns={columns} dataSource={detail} rowKey="id" scroll={{ x: 'max-content' }} />
                <Drawer title={`${editModalMode === 'store' ? '新增' : '修改'}店家`} size="large" open={editModalVisible} onClose={hideModal}>
                    <Spin spinning={editModalLoading}>
                        <Form form={editForm} onFinish={handleFinish} labelCol={{ span: 6 }}>
                            <Form.Item label="車行" name="company_id">
                                <AdminCompanySelect />
                            </Form.Item>
                            <Form.Item label="店家名稱" name="name">
                                <Input />
                            </Form.Item>
                            <Form.Item label="地區" name="region_id">
                                <RegionSelect />
                            </Form.Item>
                            <Form.Item label="地址" name="address">
                                <Input />
                            </Form.Item>
                            <Form.Item label="統一編號" name="taxidnumber">
                                <Input />
                            </Form.Item>
                            <Form.Item label="聯絡人" name="contact">
                                <Input />
                            </Form.Item>
                            <Form.Item label="手機" name="contacttel">
                                <Input />
                            </Form.Item>
                            <Form.Item label="GPS 位置" name="gpslocation" hidden={true}
                                rules={[{ pattern: '^([-+]?)([\\d]{1,2})(((\\.)(\\d+)(,)))(\\s*)(([-+]?)([\\d]{1,3})((\\.)(\\d+))?)$', message: '格式錯誤，例: 22.6304461,120.2844837' }]}>
                                <Input placeholder="22.6304461,120.2844837" />
                            </Form.Item>
                            <Form.Item label="最低租賃 (時)" name="rentminhours">
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item label="自訂逾時門檻 (時)">
                                <Space>
                                    <Form.Item name="overtimeexceedhours" noStyle={true}>
                                        <InputNumber min={0} />
                                    </Form.Item>
                                    <div>
                                        <InfoCircleOutlined /> 超過門檻小時以 24 小時計價
                                    </div>
                                </Space>
                            </Form.Item>
                            <Form.Item label="分潤比例" name="revenuesharerate">
                                <Input />
                            </Form.Item>
                            <Form.Item label="租借政策" name="rentpolicy">
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button type="primary" onClick={handleSubmit}>送出</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                    <h3>店家成員</h3>
                    <Spin spinning={editModalMemberLoading}>
                        <div style={{ marginBottom: 10, textAlign: 'right' }}>
                            <Button type="primary" disabled={editModalMode !== 'update'} onClick={() => handleShowInviteMemberModal()}>邀請</Button>
                        </div>
                        <Table columns={memberColumns} dataSource={editModalMembers} scroll={{ x: 1000 }} pagination={false} rowKey="id" />
                    </Spin>
                    <Modal title="邀請成員" open={editInviteMemberModalVisible} onOk={handleInviteModalOk} onCancel={handleHideInviteMemberModal}>
                        <Spin spinning={editInviteMemberModalLoading}>
                            <Form form={inviteMemberForm} onFinish={handleAddMember} labelCol={{ span: 6 }}>
                                <Form.Item label="電子郵件" name="email">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="名稱" name="name">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="手機" name="cellphone">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="身份" name="roles">
                                    <Select>
                                        <Select.Option value="store_manager">店長</Select.Option>
                                        <Select.Option value="store_member">店員</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Modal>
                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default StoresPage