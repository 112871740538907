import { Card, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import ContentLayout from '../ContentLayout'

function ReservePage() {
    const [storeId, setStoreId] = useState();
    const [loading, ] = useState(false);
    const refresh = useCallback(() => {

        TourniiAPI.store.stores.listing.index(storeId, {}, 1, 999).then((response) => {
            console.log(response.data);
        }).finally(() => {

        });
    }, [storeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <ContentLayout title="預訂">
            <Spin spinning={loading}>
                <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                <Card>

                </Card>
            </Spin>
        </ContentLayout>
    )
}

export default ReservePage