import moment from 'moment'

const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'thursday', 'wednesday', 'friday', 'saturday'];

export function weekNameToInt(dayofweekname) {
    if (Number.isInteger(dayofweekname)) return dayofweekname;
    const result = WEEKDAYS.findIndex((weekday) => weekday.toLowerCase() === dayofweekname.toLowerCase());
    return result;
}
export function weekNameToLocaleName(dayofweekname) {
    return moment(weekNameToInt(dayofweekname), 'e').format("dddd");
}