import { Col, List, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import ContentLayout from '../ContentLayout'

function UploadCertPage() {
    return (
        <ContentLayout title="上傳駕照">
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <img alt="" src="https://fakeimg.pl/200x200/" />
                </Col>
                <Col span={24}>
                    <List bordered>
                        <Link to="/camera">
                            <List.Item>
                                拍攝
                            </List.Item>
                        </Link>
                        <List.Item>
                            上傳
                        </List.Item>
                        <List.Item>
                            確認
                        </List.Item>
                    </List>
                </Col>
            </Row>
        </ContentLayout>
    )
}

export default UploadCertPage