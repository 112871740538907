import { Button, Col, DatePicker, Row, Space, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect'
import ContentLayout from '../ContentLayout'
import { getDaysArray } from '../../utils/time';

function VehicleInvPage() {
    const [storeId, setStoreId] = useState();
    const [loading, setLoading] = useState(false);
    const [period, setPeriod] = useState([moment(), moment().add(7, 'days')]);
    const [detail, setDetail] = useState([]);
    const PERIOD_UNIT = 'days';
    const TIME_FORMAT = "MM/DD dd";

    const dateColumns = getDaysArray(period, PERIOD_UNIT).map((row, index) => ({
        title: row.date.format(TIME_FORMAT),
        dataIndex: ['listingqty', index],
        width: 100,
        editable: true,
        align: 'right',
    }));
    const refresh = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.report.vehicleSpecInv(storeId, {
            starttime: period[0].format("YYYY-MM-DD"),
            endtime: period[1].format("YYYY-MM-DD"),
        }).then((response) => {
            const details = response.data;
            const newDetails = details.map((detail) => ({
                ...detail,
                listingqty: getDaysArray(period, PERIOD_UNIT).map((dstDate) => {
                    const found = Object.entries(detail.dates).find(([srcDateName, qtyData]) => {
                        const srcMmtDate = moment(srcDateName, "YYYY-MM-DD");
                        const dstMmtDate = moment(dstDate.date);
                        // console.log(dstMmtDate, srcMmtDate, srcMmtDate.diff(dstMmtDate, 'days'));
                        if (srcMmtDate.diff(dstMmtDate, 'days') === 0) {
                            return true;
                        }
                        return false;
                    });
                    if (found) {
                        const [, qtyData] = found;
                        // console.log("found", found);
                        return qtyData.listingidlequantity;
                    }
                    return null;
                }),
            }))
            setDetail(newDetails);
        }).finally(() => {
            setLoading(false);
        });
    }, [storeId, period]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const nextMonth = () => {
        const [starttime, endtime] = period;
        const days = endtime.diff(starttime, PERIOD_UNIT);
        setPeriod([starttime.clone().add(days, PERIOD_UNIT), endtime.clone().add(days, PERIOD_UNIT)])
    }
    const prevMonth = () => {
        const [starttime, endtime] = period;
        const days = endtime.diff(starttime, PERIOD_UNIT);
        setPeriod([starttime.clone().add(-days, PERIOD_UNIT), endtime.clone().add(-days, PERIOD_UNIT)])
    }

    const columns = [
        {
            title: '品牌',
            dataIndex: 'vehiclebrand',
            width: 100,
            render: (value, record) => record.vehiclespec.vehicletype.brand.name
        },
        // {
        //     title: '車款',
        //     dataIndex: 'vehicletype',
        //     fixed: 'left',
        //     width: 150,
        //     render: (value, record) => record.vehiclespec.vehicletype.name
        // },
        {
            title: '車型',
            dataIndex: 'vehiclespec',
            width: 200,
            render: (value, record) => record.vehiclespec.name
        },
        {
            title: '輛數',
            dataIndex: 'quantity',
            width: 80,
            // align: 'right',
        },
        ...dateColumns,
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     width: 150,
        //     fixed: 'right',
        //     render: (_, record) =>
        //         detail.length >= 1 ? (
        //             <Popconfirm title="您確定要刪除?" onConfirm={() => handleDelete(record.id)}>
        //                 <Typography.Link>刪除</Typography.Link>
        //             </Popconfirm>
        //         ) : null,
        // },
    ]
    return (
        <ContentLayout title="車輛庫存">
            <Spin spinning={loading} >
                <Row style={{ marginBottom: 10 }}>
                    <Col flex="auto">
                        <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                    </Col>
                    <Col>
                        <Space>
                            <Button onClick={prevMonth}>上一期</Button>
                            <DatePicker.RangePicker value={period} onChange={(value) => setPeriod(value)} />
                            <Button onClick={nextMonth}>下一期</Button>
                        </Space>
                    </Col>
                </Row>

                <Table columns={columns} dataSource={detail} rowKey="id" scroll={{ x: getDaysArray(period, PERIOD_UNIT).length * 200 }} />
            </Spin>
        </ContentLayout>
    )
}

export default VehicleInvPage