import React from 'react'
import {
    // UserOutlined,
    HomeOutlined,
    // BankOutlined,
    // ShopOutlined,
    // CalendarOutlined,
    // CopyOutlined,
    LoginOutlined,
    LogoutOutlined,
    SearchOutlined,
    ShoppingCartOutlined,
    ProfileFilled,
    FormOutlined,
    HighlightFilled,
    IdcardFilled,
    BankOutlined,
    UserOutlined,
    CalendarOutlined,
    CarFilled,
    AccountBookOutlined,
    DollarCircleOutlined,
    InboxOutlined,
    ScheduleOutlined,
    LineChartOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import PermissionManager from '../PermissionManager';
import TourniiAPI from '../api/TourniiAPI';

function TourniiMenu({ onClick }) {
    const navigate = useNavigate();
    const isLoggedIn = () => {
        return TourniiAPI.auth.getLoginUser();
    };
    const handleLogout = () => {
        if (TourniiAPI.auth.getLoginUser()) {
            TourniiAPI.auth.logout();
        }
    }
    const items = [
        {
            label: '登入',
            key: 'anonymous-login',
            hidden: !!isLoggedIn(),
            icon: <LoginOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS],
        },
        {
            label: '搜尋車輛',
            key: 'anonymous-rent',
            icon: <SearchOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS, PermissionManager.ROLE_RENTER],
        },
        {
            label: '購物車',
            key: 'anonymous-cart',
            icon: <ShoppingCartOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS, PermissionManager.ROLE_RENTER],
        },
        {
            type: 'group',
            label: '會員',
            key: 'g-user',
            roles: [PermissionManager.ROLE_RENTER],
            children: [
                {
                    label: '基本資料',
                    key: 'user-profile',
                    icon: <ProfileFilled />,
                },
                {
                    label: '我的訂單',
                    key: 'user-orders',
                    icon: <FormOutlined />,
                },
                {
                    label: '證件上傳',
                    key: 'user-credential',
                    icon: <IdcardFilled />,
                },
                {
                    label: '數位簽名',
                    key: 'user-signature',
                    icon: <HighlightFilled />,
                },
            ]
        },
        {
            type: 'group',
            label: '車行',
            key: 'g-company',
            roles: [PermissionManager.ROLE_COMPANY],
            children: [
                {
                    label: '車行資料',
                    key: 'company-profile',
                    icon: <BankOutlined />,
                    hidden: true,
                },
                {
                    label: '店家管理',
                    key: 'company-stores',
                    icon: <HomeOutlined />,
                    hidden: true,
                },
                {
                    label: '車行成員',
                    key: 'company-members',
                    icon: <UserOutlined />,
                    hidden: true,
                },
            ]
        },
        {
            type: 'group',
            label: '店家',
            key: 'g-store',
            roles: [PermissionManager.ROLE_STORE, PermissionManager.ROLE_STORE_MEMBER],
            children: [
                // {
                //     label: '現場作業',
                //     icon: <HomeOutlined />,
                //     children: [
                //         {
                //             label: '預訂',
                //             key: 'store-onsite-reserve',
                //             icon: <HomeOutlined />,
                //         },
                //         {
                //             label: '預訂取車',
                //             key: 'store-onesite-pickup',
                //             icon: <HomeOutlined />,
                //         },
                //         {
                //             label: '現場租車',
                //             key: 'store-onsite-rent',
                //             icon: <HomeOutlined />,
                //         },
                //         {
                //             label: '現場還車',
                //             key: 'store-onsite-return',
                //             icon: <HomeOutlined />,
                //         },
                //     ]
                // },
                {
                    label: '訂單管理',
                    key: 'store-orders',
                    icon: <FormOutlined />,
                },
                {
                    label: '上架管理',
                    icon: <ShoppingCartOutlined />,
                    children: [
                        {
                            label: '日期類型',
                            key: 'store-datetypes',
                            icon: <CalendarOutlined />,
                        },
                        {
                            label: '日期管理',
                            key: 'store-dates',
                            icon: <ScheduleOutlined />,
                        },
                        {
                            label: '車型定價',
                            key: 'store-pricing',
                            icon: <DollarCircleOutlined />,
                        },
                        {
                            label: '上架數量',
                            key: 'store-listing',
                            icon: <ShoppingCartOutlined />,
                        },
                    ]
                },
                {
                    label: '車型管理',
                    key: 'store-vehicle-specs',
                    icon: <CarFilled />,
                },
                {
                    label: '車輛庫存',
                    key: 'store-inv',
                    icon: <InboxOutlined />,
                },
                {
                    label: '車輛資料',
                    key: 'store-vehicles',
                    icon: <UnorderedListOutlined />,
                    hidden: true,
                },
                {
                    label: '銷售報表',
                    key: 'store-report-sales',
                    icon: <LineChartOutlined />,
                    hidden: true,
                },
                {
                    label: '店家資料',
                    key: 'store-profile',
                    icon: <HomeOutlined />,
                },
                {
                    label: '店家成員',
                    key: 'store-members',
                    icon: <UserOutlined />,
                    hidden: true,
                },
            ],
        },
        {
            type: 'group',
            label: '管理',
            key: 'g-admin',
            roles: [PermissionManager.ROLE_ADMIN],
            children: [
                {
                    label: '車行管理',
                    key: 'admin-companies',
                    icon: <BankOutlined />,
                },
                {
                    label: '店家管理',
                    key: 'admin-stores',
                    icon: <HomeOutlined />,
                },
                {
                    label: '訂單查詢',
                    key: 'admin-orders',
                    icon: <FormOutlined />,
                },
                {
                    label: '銷售報表',
                    key: 'admin-report-sales',
                    icon: <LineChartOutlined />,
                },
                {
                    label: '帳單',
                    key: 'admin-report-bills',
                    icon: <AccountBookOutlined />,
                },
            ],
        },
        {
            label: '登出',
            key: 'anonymous-logout',
            hidden: !isLoggedIn(),
            icon: <LogoutOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS],
        },
    ];
    const handleClick = ({ item, key, keyPath, domEvent }) => {
        //console.log(domEvent, key, keyPath);
        switch (key) {
            case 'anonymous-rent':
                navigate('/');
                break;
            case 'anonymous-login':
                if (!isLoggedIn()) {
                    navigate('/login');
                }
                break;
            case 'anonymous-logout':
                if (isLoggedIn()) {
                    handleLogout();
                    navigate('/');
                }
                break;
            case 'anonymous-cart':
                navigate('/cart');
                break;
            case 'user-orders':
                navigate('/orders');
                break;
            case 'user-profile':
                navigate('/profile');
                break;
            case 'user-signature':
                navigate('/signature');
                break;
            case 'user-credential':
                navigate('/credential');
                break;
            case 'store-onsite-reserve':
                navigate('/store/reserve');
                break;
            case 'store-onsite-rent':
                navigate('/store/rent');
                break;
            case 'store-onsite-pickup':
                navigate('/store/pickup');
                break;
            case 'store-onsite-return':
                navigate('/store/return');
                break;
            case 'store-vehicles':
                navigate('/store/vehicles');
                break;
            case 'store-vehicle-specs':
                navigate('/store/vehicle-specs');
                break;
            case 'store-orders':
                navigate('/store/orders');
                break;
            case 'store-datetypes':
                navigate('/store/datetypes');
                break;
            case 'store-dates':
                navigate('/store/dates');
                break;
            case 'store-pricing':
                navigate('/store/pricing');
                break;
            case 'store-listing':
                navigate('/store/listing');
                break;
            case 'store-profile':
                navigate('/store/profile');
                break;
            case 'store-inv':
                navigate('/store/inv');
                break;
            case 'admin-companies':
                navigate('/admin/companies');
                break;
            case 'admin-stores':
                navigate('/admin/stores');
                break;
            case 'admin-orders':
                navigate('/admin/orders');
                break;
            case 'admin-report-sales':
                navigate('/admin/report/sales');
                break;
            case 'admin-report-bills':
                navigate('/admin/report/bills');
                break;
            default:
                break;
        }
        if (onClick) {
            onClick();
        }
    }

    //console.log(PermissionManager.getRoles());
    const filteredItems = items.filter((item) => {
        return PermissionManager.getRoles().reduce((pv, role, idx) => {
            return pv || item.roles.indexOf(role) >= 0
        }, false);
    });
    //console.log(filteredItems);
    return (
        <Menu mode="inline" defaultSelectedKeys={["0"]} items={filteredItems} onClick={handleClick} />
    )
}

export default TourniiMenu