import { Button, Col, DatePicker, Form, InputNumber, Row, Space, Spin, Table } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentLayout from '../ContentLayout'
import moment from 'moment'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import { getDaysArray } from '../../utils/time';

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex, // Table dataIndex
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const [form] = Form.useForm();
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const getColData = () => {
        return record[dataIndex[0]][dataIndex[1]]
    }

    const toggleEdit = () => {
        setEditing(!editing);
        // 將欄位數值載入表單
        form.setFieldsValue({
            listingqty: getColData() ? getColData().quantity : undefined,
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            const newRecord = { ...record };
            let listingQtyColData = newRecord[dataIndex[0]][dataIndex[1]];
            if (!listingQtyColData) {
                listingQtyColData = {};
            }
            listingQtyColData.quantity = values.listingqty;
            newRecord[dataIndex[0]][dataIndex[1]] = listingQtyColData;
            console.log("save", values, newRecord);
            toggleEdit();
            handleSave(newRecord, dataIndex);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form form={form}>
                <Form.Item
                    style={{ margin: 0 }}
                    name="listingqty"
                >
                    <InputNumber style={{ width: 60 }} ref={inputRef} min={0} onPressEnter={save} onBlur={save} onFocus={(e) => {
                        e.target.select();
                    }} />
                </Form.Item>
            </Form>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, minHeight: 32 }} onClick={toggleEdit} >
                {children[1] !== null ? children : <span style={{ color: 'lightgray' }}>{record.quantity}</span>}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

function ListingPage() {

    const [loading, setLoading] = useState(false);
    const [storeId, setStoreId] = useState(null);
    const [period, setPeriod] = useState([moment().hour(0).minute(0).second(0), moment().hour(23).minute(59).second(59).add(7, 'days')]);
    const [detail, setDetail] = useState([]);
    const PERIOD_UNIT = 'days';
    const TIME_FORMAT = "MM/DD dd";
    // const PERIOD_UNIT = 'hours';
    // const TIME_FORMAT = "MM/DD dd HH:00";

    const nextMonth = () => {
        const [starttime, endtime] = period;
        const days = endtime.diff(starttime, PERIOD_UNIT);
        setPeriod([starttime.clone().add(days, PERIOD_UNIT), endtime.clone().add(days, PERIOD_UNIT)])
    }
    const prevMonth = () => {
        const [starttime, endtime] = period;
        const days = endtime.diff(starttime, PERIOD_UNIT);
        setPeriod([starttime.clone().add(-days, PERIOD_UNIT), endtime.clone().add(-days, PERIOD_UNIT)])
    }
    const refresh = useCallback(async () => {
        if (!storeId) return;

        setLoading(true);
        const getDaysListingQty = (listingDetail, detail) => {
            return getDaysArray(period, PERIOD_UNIT).map((columnDate) => {
                // 從 listingdetail 找到 columnDate 在期間內的上架數量
                const found = listingDetail.find((row2) => row2.vehiclespec_id === detail.vehiclespec_id && columnDate.date.isBetween(moment(row2.starttime), moment(row2.endtime)));
                if (found) {
                    return {
                        quantity: found.quantity,
                        raw: found
                    };
                }
                return undefined;
            });
        };

        const listingDetail = await TourniiAPI.store.stores.listing.index(storeId, {
            starttime: period[0].format("YYYY-MM-DD HH:mm:ss"),
            endtime: period[1].format("YYYY-MM-DD HH:mm:ss")
        }, 1, 999).then((response) => {
            const result = response.data;
            console.log('listing', result.data);
            return result.data;
        });

        await TourniiAPI.store.stores.vehicleSpecInv.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            const details = result.data;
            const newDetails = details.map((detail) => {
                return {
                    ...detail,
                    listingqty: getDaysListingQty(listingDetail, detail),
                };
            })
            setDetail(newDetails);
            return response;
        });
        setLoading(false);
    }, [storeId, period]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleSave = (record, dataIndex) => {
        const newData = [...detail];
        const index = newData.findIndex((item) => record.id === item.id);
        const item = newData[index];

        const listingQtyColData = record[dataIndex[0]][dataIndex[1]];
        const listingDate = getDaysArray(period, PERIOD_UNIT)[dataIndex[1]];
        const listingStartDate = listingDate.date.startOf(PERIOD_UNIT).format("YYYY-MM-DD HH:mm:ss");
        const listingEndDate = listingDate.date.endOf(PERIOD_UNIT).format("YYYY-MM-DD HH:mm:ss");

        console.log("listingQtyData", record, dataIndex, listingQtyColData)
        const saveData = {
            vehiclespec_id: record.vehiclespec_id,
            starttime: listingStartDate,
            endtime: listingEndDate,
            quantity: listingQtyColData.quantity
        };
        console.log("handleSave", record, dataIndex, saveData);

        if (!listingQtyColData.raw) {
            if (!Number.isInteger(listingQtyColData.quantity) && listingQtyColData.quantity < 0) return;
            setLoading(true);
            TourniiAPI.store.stores.listing.store(storeId, saveData).then((response) => {
                console.log(item, record);
                const result = response.data;
                const newListingQty = [...item.listingqty];
                listingQtyColData.raw = result;
                newListingQty[dataIndex[1]] = listingQtyColData;
                newData.splice(index, 1, { ...item, listingqty: newListingQty });
                setDetail(newData);
                // notification.info({ message: '上架數量新增成功' });
            }).finally(() => {
                setLoading(false);
            });
        } else {
            // update or delete
            if (Number.isInteger(listingQtyColData.quantity) && listingQtyColData.quantity >= 0) {
                if (listingQtyColData.quantity === listingQtyColData.raw.quantity) return;
                // update
                setLoading(true);
                TourniiAPI.store.stores.listing.store(storeId, saveData).then((response) => {
                    //notification.info({ message: '上架數量更新成功' });
                    refresh();
                }).finally(() => {
                    setLoading(false);
                });

            } else {
                //delete
                setLoading(true);
                TourniiAPI.store.stores.listing.destroy(storeId, listingQtyColData.raw.id).then((response) => {
                    //notification.info({ message: '上架數量刪除成功' });
                    refresh();
                }).finally(() => {
                    setLoading(false);
                });
            }
        }

    };

    const dateColumns = getDaysArray(period, PERIOD_UNIT).map((row, index) => ({
        title: row.date.format(TIME_FORMAT),
        dataIndex: ['listingqty', index, 'quantity'],
        width: 100,
        editable: true,
        align: 'right',
    }));
    const columns = [
        {
            title: '品牌',
            dataIndex: 'vehiclebrand',
            // fixed: 'left',
            width: 100,
            render: (value, record) => record.vehiclespec.vehicletype.brand.name
        },
        // {
        //     title: '車款',
        //     dataIndex: 'vehicletype',
        //     fixed: 'left',
        //     width: 150,
        //     render: (value, record) => record.vehiclespec.vehicletype.name
        // },
        {
            title: '車型',
            dataIndex: 'vehiclespec',
            // fixed: 'left',
            width: 200,
            render: (value, record) => record.vehiclespec.name
        },
        {
            title: '輛數',
            dataIndex: 'quantity',
            width: 80,
            // fixed: 'left',
            align: 'right',
        },
        ...dateColumns,
    ]
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <ContentLayout title="上架數量">
            <Spin spinning={loading}>
                <Row style={{ marginBottom: 10 }}>
                    <Col flex="auto">
                        <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                    </Col>
                    <Col>
                        <Space>
                            <Button onClick={prevMonth}>上一期</Button>
                            <DatePicker.RangePicker value={period} onChange={(value) => setPeriod(value)} />
                            <Button onClick={nextMonth}>下一期</Button>
                        </Space>
                    </Col>
                </Row>
                <Table
                    size="small"
                    columns={mergedColumns}
                    rowKey="id"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    rowClassName={() => 'editable-row'}
                    dataSource={detail}
                    scroll={{ x: getDaysArray(period, PERIOD_UNIT).length * 200 }} />
            </Spin>
        </ContentLayout>
    )
}

export default ListingPage