import { Drawer as AntdDrawer } from 'antd'
import './Drawer.css'
import React from 'react'

function Drawer(props) {
  return (
    <AntdDrawer {...props} />
  )
}

export default Drawer