import { notification } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';
import PropTypes from 'prop-types';

const TextEditor = ({ onChange, onUploadImage, placeholder, value }) => {
  const quillRef = useRef(null);
  const reactQuillRef = useRef(null);

  const saveToServer = useCallback((file) => {
    if (onUploadImage) {
      onUploadImage(file).then((url) => {
        console.log('insertToEditor', url);
        insertToEditor(url);
      });
    }
  }, [onUploadImage]);

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        saveToServer(file);
      } else {
        notification.warning({ message: '你只能上傳圖片' });
        console.warn('You could only upload images.');
      }
    };
  }, [saveToServer]);

  const attachQuillRefs = useCallback(() => {
    if (typeof reactQuillRef.current.getEditor !== 'function') return;
    quillRef.current = reactQuillRef.current.getEditor();

    if (quillRef.current) {
      const toolbar = quillRef.current.getModule('toolbar');
      toolbar.addHandler('image', (...values) => {
        console.log('TextEditor: toolbar: image: ', values);
        selectLocalImage();
      });
    }
  }, [selectLocalImage]);

  useEffect(() => {
    attachQuillRefs();
  }, [attachQuillRefs]);

  useEffect(() => {
    attachQuillRefs();
  });



  const insertToEditor = (url) => {
    // push image url to rich editor.
    const range = quillRef.current.getSelection();
    quillRef.current.insertEmbed(range.index, 'image', `${url}`);
  };

  let linkBar = ['link', 'code'];
  let formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    // 'link',
    // 'code',
  ];

  if (onUploadImage) {
    formats.push('image');
    linkBar.push('image');
  }

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      linkBar,
      ['clean'],
    ],
  };

  return (
    <>
      <ReactQuill
        theme="snow"
        value={value || ''}
        modules={modules}
        formats={formats}
        onChange={onChange}
        placeholder={placeholder}
        ref={reactQuillRef}
      ></ReactQuill>
    </>
  );
};

TextEditor.propTypes = {
  /**
   * onUploadImage(file)
   * 
   * @returns Promise
   */
  onUploadImage: PropTypes.func,
};

export default TextEditor;