import { ORDERSTATE_CANCELED, ORDERSTATE_COMPLETED, ORDERSTATE_DEPOSIT_PAID, ORDERSTATE_NEW, ORDERSTATE_PICKEDUP } from "../api/TourniiAPI";
import moment from 'moment';

export function validOrder(order) {
    if (moment().diff(moment(order.expectpickuptime), 'days') > 0) return false;
    if (moment().diff(moment(order.expectreturntime), 'days') > 0) return false;
    return true;
}
export function validPayment(order) {
    if (!validOrder(order)) return false;
    return order.state === ORDERSTATE_NEW;
}
export function validCancel(order) {
    if (order.state === ORDERSTATE_CANCELED || order.state === ORDERSTATE_COMPLETED) return false;
    return true;
}
export function validPickup(order) {
    return order.state === ORDERSTATE_DEPOSIT_PAID;
}
export function validReturn(order) {
    return order.state === ORDERSTATE_PICKEDUP;
}