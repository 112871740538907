import { Button, Calendar, Col, DatePicker, Drawer, Form, InputNumber, notification, Popconfirm, Row, Select, Space, Spin, Table, Tabs, Tag, Typography } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import ContentLayout from '../ContentLayout'
import moment from 'moment';
import locale from 'antd/es/locale/zh_TW';
import DateTypeSelect from './components/DateTypeSelect';

const REPEAT_TYPES = [
    {
        id: 'none',
        name: '無'
    },
    {
        id: 'daily',
        name: '天'
    },
    {
        id: 'weekly',
        name: '週'
    },
    {
        id: 'monthly',
        name: '個月'
    },
    {
        id: 'yearly',
        name: '年'
    },
]
const REPEAT_WEEKDAYS = [
    {
        id: 0,
        name: '週日'
    },
    {
        id: 1,
        name: '週一'
    },
    {
        id: 2,
        name: '週二'
    },
    {
        id: 3,
        name: '週三'
    },
    {
        id: 4,
        name: '週四'
    },
    {
        id: 5,
        name: '週五'
    },
    {
        id: 6,
        name: '週六'
    }
]
const repeatTypeToName = (value) => {
    const found = REPEAT_TYPES.find((row) => row.id === value);
    if (found) return found.name;
    return;
}
const repeatWeekdaysToNames = (weekdays) => {
    if (!Array.isArray(weekdays)) return;
    return weekdays.map((weekday) => {
        const found = REPEAT_WEEKDAYS.find((row) => row.id === parseInt(weekday));
        if (found) {
            return <Tag key={found.id}>{found.name}</Tag>
        }
        return <Tag key="unknown">不明</Tag>;
    });
}
function DatePage() {
    const [storeId, setStoreId] = useState();
    const [loading, setLoading] = useState(false);
    const [dateDetail, setDateDetail] = useState([]);
    const [calendarDetail, setCalendarDetail] = useState([]);
    const [calendarMode, setCalendarMode] = useState('month');
    const [calendarDate, setCalendarDate] = useState();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [drawerLoading, setDrawerLoading] = useState(false);
    const [editMode, setEditMode] = useState('store');
    const [editId, setEditId] = useState();
    const defaultRepeatType = 'none';
    const [repeatType, setRepeatType] = useState(defaultRepeatType);
    const formRef = useRef();

    const refresh = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.date.index(storeId, {}, 1, 999).then((response) => {
            console.log(response);
            const result = response.data;
            setDateDetail(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [storeId]);

    const refreshCalendar = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.date.calendar(storeId, {
            starttime: calendarDate?.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            endtime: calendarDate?.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        }).then((response) => {
            console.log(response);
            const result = response.data;
            setCalendarDetail(result);
        }).finally(() => {
            setLoading(false);
        });
    }, [storeId, calendarDate]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        refreshCalendar();
    }, [refreshCalendar]);

    const columns = [
        {
            title: '開始',
            dataIndex: 'starttime',
            key: 'starttime',
            render: (value) => value ? moment(value).format("YYYY-MM-DD") : null,
        },
        {
            title: '結束',
            dataIndex: 'endtime',
            key: 'endtime',
            render: (value) => value ? moment(value).format("YYYY-MM-DD") : '不結束',
        },
        {
            title: '重複間隔',
            dataIndex: 'repeatinterval',
            key: 'repeatinterval',
            render: (value, record) => value ? `${value} ${repeatTypeToName(record.repeattype)}` : '無'
        },
        {
            title: '每週重複',
            dataIndex: 'repeatweekday',
            key: 'repeatweekday',
            render: (value) => repeatWeekdaysToNames(value)
        },
        {
            title: '每月重複',
            dataIndex: 'repeatmonthday',
            key: 'repeatmonthday',
            render: (value) => {
                if (value === 'day') return '每月第 N 天';
                if (value === 'weekday') return '每月第 N 個星期 Y';
                return value;
            }
        },
        {
            title: '重複結束時間',
            dataIndex: 'repeatenddate',
            key: 'repeatenddate',
        },
        {
            title: '日期類別',
            dataIndex: 'datetype_id',
            key: 'datetype_id',
            render: (value, record) => record.datetype.name
        },
        {
            title: '優先權',
            dataIndex: 'priority',
            key: 'priority',
        },
        {
            title: '動作',
            // fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={showEditDrawer.bind(this, record.id)}>修改</Typography.Link>
                <Popconfirm onConfirm={handleDelete.bind(this, record.id)} title="是否確認刪除此日期">
                    <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
            </Space>
        }
    ]

    const getListData = (date) => {
        const found = calendarDetail.find((row) => {
            const srcMmtDate = moment(row.date, 'YYYY-MM-DD');
            const dstMmtDate = date;
            return srcMmtDate.diff(dstMmtDate, 'days') === 0;
        })
        if (found) {
            // console.log("getListdata", found);
            let details = found.details;
            details.sort((a, b) => {
                return a.priority - b.priority;
            });
            details = details.slice(0, 1); // Get first priority
            // console.log(details);
            const result = details.map((row) => ({
                id: `${found.date}-${row.id}`,
                type: 'success',
                content: row.datetype.name,
                priority: row.priority,
            }));
            return result;
        }
        return [];
    }
    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul style={{
                margin: 0,
                padding: 0,
                listStyle: 'none',
                textAlign: 'center'
            }}>
                {listData.map((item) => (
                    <li key={item.id}>
                        {`${item.content}`}
                    </li>
                ))}
            </ul>
        );
    };
    const handleSelectCalendarPanelMode = (date, mode) => {
        // console.log("handleSelectCalendarPanelMode", date, mode);
        setCalendarMode(mode);
        setCalendarDate(date);
    }
    const handleSelectCalendarDate = (date) => {
        // console.log("handleSelectCalendarDate", date);
        if (calendarMode === 'year') {
            setCalendarMode('month');
        }
        setCalendarDate(date);
    }
    const showAddDrawer = () => {
        setEditMode('store');
        setEditId(null);
        setEditModalVisible(true);
        if (formRef.current) {
            formRef.current.resetFields();
        }
    }
    const showEditDrawer = (id) => {
        console.log("showEditDrawer", id);
        setEditModalVisible(true);
        setEditMode('update');
        setEditId(id);
        setDrawerLoading(true);
        TourniiAPI.store.stores.date.show(storeId, id).then((response) => {
            const result = response.data;
            console.log(result);
            if (formRef.current) {
                const newResult = {
                    ...result,
                    starttime: moment(result.starttime),
                    endtime: result.endtime ? moment(result.endtime) : null,
                    repeatweekday: result.repeatweekday?.map((weekday) => Number.parseInt(weekday)) ?? undefined,
                }
                setRepeatType(result.repeattype);
                formRef.current.setFieldsValue(newResult);
            }
        }).finally(() => {
            setDrawerLoading(false);

        })
    }
    const hideDrawer = () => {
        setEditModalVisible(false);
    }
    const handleFinish = (values) => {
        // const [starttime, endtime] = values.startendtime;
        values.starttime.set('hour', 0).set('minute', 0).set('second', 0);
        values.endtime.set('hour', 23).set('minute', 59).set('second', 59);
        const newValues = {
            ...values,
            starttime: values.starttime.format("YYYY-MM-DD HH:mm:ss"),
            endtime: values.endtime.format("YYYY-MM-DD HH:mm:ss"),
        }
        delete newValues.startendtime;
        console.log(newValues);
        setLoading(true);
        if (editMode === 'store') {
            TourniiAPI.store.stores.date.store(storeId, newValues).then((response) => {
                notification.info({ message: '新增日期成功' });
            }).finally(() => {
                setLoading(false);
                refresh();
            });
        } else {
            setLoading(true);
            TourniiAPI.store.stores.date.update(storeId, editId, newValues).then((response) => {
                notification.info({ message: '修改日期成功' });
            }).finally(() => {
                setLoading(false);
                refresh();
            })
        }
    }
    const handleDelete = (id) => {
        if (!id) return;
        setLoading(true);
        TourniiAPI.store.stores.date.destroy(storeId, id).then((response) => {
            notification.info({ message: '日期刪除成功' });
        }).finally(() => {
            setLoading(false);
            refresh();
        });
    }
    const handleChangeRepeatType = (value) => {
        setRepeatType(value);
    }
    return (
        <ContentLayout title="日期類型">
            <Spin spinning={loading}>
                <Form style={{ marginBottom: 20 }}>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                            <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                        </Col>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Space>
                                <Button type="primary" onClick={showAddDrawer}>新增</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Tabs defaultActiveKey="1" items={[
                    {
                        key: "1",
                        label: "明細",
                        children: <>
                            <Table columns={columns} dataSource={dateDetail} rowKey="id" scroll={{ x: 'max-content' }} />
                        </>,
                    },
                    {
                        key: "2",
                        label: "日曆",
                        children: <>
                            <Calendar
                                locale={locale}
                                style={{ padding: 20 }}
                                dateCellRender={dateCellRender}
                                mode={calendarMode}
                                value={calendarDate}
                                onPanelChange={handleSelectCalendarPanelMode}
                                onSelect={handleSelectCalendarDate} />
                        </>,
                    }
                ]} />
                <Drawer title={`${editMode === 'store' ? '新增' : '修改'}日期`} open={editModalVisible} onClose={hideDrawer}>
                    <Spin spinning={drawerLoading}>
                        <Form ref={formRef} onFinish={handleFinish} layout="vertical" labelCol={{ span: 8 }} initialValues={{ repeattype: defaultRepeatType }}>
                            <Form.Item label="開始結束日期" required={true}>
                                <Space>
                                    <Form.Item name="starttime" rules={[{ required: true }]} style={{ display: 'inline-block', marginBottom: 0 }}>
                                        <DatePicker minuteStep={15} format="YYYY-MM-DD" placeholder="開始日期" />
                                    </Form.Item>
                                    <Form.Item name="endtime" style={{ display: 'inline-block', marginBottom: 0 }}>
                                        <DatePicker minuteStep={15} format="YYYY-MM-DD" placeholder="結束日期" />
                                    </Form.Item>
                                </Space>
                            </Form.Item>
                            <Form.Item label="重複間隔" style={{ marginBottom: 0 }}>
                                <Space>
                                    <Form.Item name="repeatinterval" style={{ display: 'inline-block', }}>
                                        <InputNumber min={0} placeholder="間隔時間" />
                                    </Form.Item>
                                    <Form.Item name="repeattype" style={{ display: 'inline-block', width: 80 }}>
                                        <Select placeholder="間隔" onChange={handleChangeRepeatType}>
                                            {
                                                REPEAT_TYPES.map((type) => <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Space>
                            </Form.Item>
                            <Form.Item label="每週重複" name="repeatweekday" hidden={repeatType !== 'weekly'}>
                                <Select mode="multiple">
                                    <Select.Option value={0}>週日</Select.Option>
                                    <Select.Option value={1}>週一</Select.Option>
                                    <Select.Option value={2}>週二</Select.Option>
                                    <Select.Option value={3}>週三</Select.Option>
                                    <Select.Option value={4}>週四</Select.Option>
                                    <Select.Option value={5}>週五</Select.Option>
                                    <Select.Option value={6}>週六</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="每月重複" name="repeatmonthday" hidden={repeatType !== 'monthly'}>
                                <Select allowClear={true}>
                                    <Select.Option value="day">每月第 N 天</Select.Option>
                                    <Select.Option value="weekday">每月第 N 個星期 Y</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="重複結束時間" name="repeatenddate">
                                <DatePicker format="YYYY-MM-DD" allowClear={true} />
                            </Form.Item>
                            <Form.Item label="日期類別" name="datetype_id" required={true}>
                                <DateTypeSelect storeId={storeId} />
                            </Form.Item>
                            <Form.Item label="優先權" name="priority" required={true}>
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit">送出</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default DatePage