import { Badge, List } from 'antd';
import React from 'react'

function BadgeListItem({ onClick, valid, children, ...props }) {
    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
        }
    }
    return <List.Item style={{ cursor: 'pointer' }} onClick={handleClick}>
        <Badge status={valid ? 'success' : 'default'} /> {children}
    </List.Item>
}
export default BadgeListItem