import { Badge, Space } from 'antd'
import React from 'react'
import { OrderStates } from '../api/TourniiAPI';

function OrderStateBadge({ state }) {
    let name = state;
    let color = null;
    const found = OrderStates.find((row) => row.id === state);
    if (found) {
        name = found.name;
        color = found.color;
    }
    return (
        <Space>
            <Badge color={color} />{name}
        </Space>
    )
}

export default OrderStateBadge