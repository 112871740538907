import { Button, Col, Row, Select, Space } from 'antd';
import React from 'react'
import Webcam from 'react-webcam';
import ContentLayout from '../ContentLayout';

function CameraShotPage() {
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    const webcamRef = React.useRef(null);

    const handleDevices = React.useCallback(
        mediaDevices =>
            setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    React.useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
        [handleDevices]
    );
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log('capture', imageSrc);
        },
        [webcamRef]
    );
    return (
        <ContentLayout title="拍攝">
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <Space>
                        選擇相機
                        <Select style={{ width: 100 }} onClick={(event) => {
                            console.log(event.target.value);
                            setDeviceId(event.target.value);
                        }}>
                            {devices.map((device, key) => (
                                <Select.Option key={device.deviceId} value={device.deviceId}>
                                    {device.label || `Device ${key + 1}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space>
                </Col>
                <Col span={24}>
                    <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={400}
                        screenshotFormat="image/jpeg"
                        width={400}
                        videoConstraints={{
                            deviceId: deviceId,
                            width: 1280,
                            height: 720,
                            facingMode: { exact: "environment" }
                        }}
                    />
                </Col>
                <Col span={24}>
                    <Button onClick={capture}>拍照</Button>
                </Col>
            </Row>
        </ContentLayout>
    )
}

export default CameraShotPage