import { Button, Col, DatePicker, Form, notification, Row, Spin, Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import AdminStoreSelect from '../../components/AdminStoreSelect';
import ContentLayout from '../ContentLayout'
import moment from 'moment';
import AdminCompanySelect from '../../components/AdminCompanySelect';
import { createObjectUrlFromResp } from '../../utils/download';

function BillsReportPage() {
    const [loading, setLoading] = useState(false);
    const [storeId, setStoreId] = useState();
    const [detail, setDetail] = useState([]);
    const [period, setPeriod] = useState([moment(), moment().add(30, 'days')])
    const [selectedCompanyId, setSelectedCompanyId] = useState();

    const refresh = useCallback(() => {
        if (!storeId || !period) return;
        setLoading(true);
        TourniiAPI.admin.reports.storeBills(storeId, {
            startdate: period[0].format('YYYY-MM-DD'),
            enddate: period[1].format('YYYY-MM-DD')
        }).then((response) => {
            const result = response.data;
            setDetail(result.dates);
        }).finally(() => {
            setLoading(false);
        })
    }, [storeId, period]);

    const exportFile = () => {
        if (!storeId || !period)  {
            notification.warn({ message: '請先選擇店家與期間' });
            return;
        }
        TourniiAPI.admin.reports.storeBillsExport(storeId, {
            startdate: period[0].format('YYYY-MM-DD'),
            enddate: period[1].format('YYYY-MM-DD')
        }).then((response) => {
            createObjectUrlFromResp(response, `tournii-bill-${moment().format('YYYY-MM-DD')}.xlsx`);
        })
    }

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = [
        {
            title: '日期',
            dataIndex: 'date',
        },
        {
            title: '銷售總額',
            dataIndex: 'totalamount',
        },
        {
            title: '分潤金額',
            dataIndex: 'revenueshareamount',
        },
    ]
    return (
        <ContentLayout title="帳單">
            <Spin spinning={loading}>
                <Form>
                    <Row gutter={[10, 10]}>
                        <Col flex="auto">
                            <Form.Item label="車行">
                                <AdminCompanySelect value={selectedCompanyId} onChange={(value) => setSelectedCompanyId(value)} />
                            </Form.Item>
                        </Col>
                        <Col flex="auto">
                            <Form.Item label="店家">
                                <AdminStoreSelect companyId={selectedCompanyId} value={storeId} onSelect={(value) => setStoreId(value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item label="期間">
                                <DatePicker.RangePicker value={period} onChange={(period) => setPeriod(period)} />
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={() => exportFile()}>匯出</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table columns={columns} dataSource={detail} />
            </Spin>
        </ContentLayout>
    )
}

export default BillsReportPage