import { Col, DatePicker, notification, Row } from 'antd'
import React, { useState } from 'react'
import moment from 'moment';
import TimeSelect from './TimeSelect';

function PickAndReturnDatePicker({ value = {}, onChange, minIntvalMinutes = 60 }) {
    const [startDate, setStartDate] = useState(value.startDate);
    const [endDate, setEndDate] = useState(value.endDate);

    const triggerChange = (changedValue) => {
        onChange?.({
            startDate,
            endDate,
            ...value,
            ...changedValue,
        });
    };

    const handleStartDateChange = (mmtDate, type) => {
        if (!mmtDate) {
            if (type === "date") {
                mmtDate = moment().clone().hour(0).minute(0).second(0)
                handleEndDateChange(null, type)
            }
            if (type === "time") {
                mmtDate = startDate.clone().hour(0).minute(0).second(0)
            }
        }
        let newEndDate = null
        if (endDate) {
            const diffMinute = endDate.diff(mmtDate, 'minute');
            if (diffMinute < minIntvalMinutes) {
                // 低於最小間距時間則，自動把結束日期依原本的間距延後
                const diffOldMinute = endDate.diff(startDate, 'minute');
                newEndDate = mmtDate.clone().add(diffOldMinute, 'minute');
            }
        }
        setStartDate(mmtDate);
        let changes = {
            startDate: mmtDate,
        };
        if (newEndDate) {
            setEndDate(newEndDate);
            changes.endDate = newEndDate;
        }
        triggerChange(changes);
    }
    const handleEndDateChange = (mmtDate, type) => {
        if (type === 'date') {
            mmtDate?.set("minute", mmtDate.minute() - (mmtDate.minute() % 15));
        }
        if (type === "time") {
            // 若只指定時間則假設日期延用開始期間
            if (!endDate) mmtDate.year(startDate.year()).month(startDate.month()).date(startDate.date());
        }


        // 判斷最少需要租一個小時，否則自動加時數
        if (startDate && mmtDate && mmtDate.diff(startDate, 'minute') < 60) {
            mmtDate.hour(startDate.hour()).minute(startDate.minute()).second(startDate.second());
            mmtDate.add(1, 'hour');
            console.log("mmDate", mmtDate);
            notification.warn({ message: '至少預約一個小時' });
        }
        setEndDate(mmtDate);
        triggerChange({
            endDate: mmtDate,
        });
    }
    const disabledStartDate = (current) => {
        if (current && endDate) {
            return current.clone().startOf('day').diff(endDate.clone().startOf('day'), 'day') > 0;
        }
        return false;
    }
    const disabledEndDate = (current) => {
        if (current && startDate) {
            return current.clone().startOf('day').diff(startDate.clone().startOf('day'), 'day') < 0;
        }
        return false;
    }
    const genNumArray = (start, to) => {
        return Array.from(Array(to - start).keys(), (v, k) => k + start);
    }
    const disabledStartTime = () => {
        if (!startDate || !endDate) return {};
        return {
            disabledHours: () => {
                if (startDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")) {
                    return genNumArray(endDate.hour() + 1, 24);
                }
                return [];
            },
            disabledMinutes: (selectedHour) => {
                if (startDate.clone().hour(selectedHour).format("YYYY-MM-DD HH") === endDate.format("YYYY-MM-DD HH")) {
                    return genNumArray(endDate.minute(), 60);
                }
                return [];
            },
            disabledSeconds: (selectedHour, selectedMinute) => {
                if (startDate.clone().hour(selectedHour).minute(selectedMinute).format("YYYY-MM-DD HH:mm") === endDate.format("YYYY-MM-DD HH:mm")) {
                    return genNumArray(endDate.second(), 60);
                }
                return [];
            },
        }
    }
    const disabledEndTime = () => {
        if (!startDate) return {};
        let newEndDate = endDate;
        if (!newEndDate) newEndDate = startDate;
        return {
            disabledHours: () => {
                if (startDate.format("YYYY-MM-DD") === newEndDate.format("YYYY-MM-DD")) {
                    return genNumArray(0, startDate.hour());
                }
                return [];
            },
            disabledMinutes: (selectedHour) => {
                if (startDate.format("YYYY-MM-DD HH") === newEndDate.clone().hour(selectedHour).format("YYYY-MM-DD HH")) {
                    return genNumArray(0, startDate.minute());
                }
                return [];
            },
            disabledSeconds: (selectedHour, selectedMinute) => {
                if (startDate.format("YYYY-MM-DD HH:mm") === newEndDate.clone().hour(selectedHour).minute(selectedMinute).format("YYYY-MM-DD HH:mm")) {
                    return genNumArray(0, startDate.second());
                }
                return [];
            },
        }
    }
    return (
        <Row gutter={[5, 5]}>
            <Col xs={24} md={4}>取車時間</Col>
            <Col xs={14} md={12}>
                <DatePicker style={{ width: '100%' }} value={startDate} onChange={(value) => handleStartDateChange(value, 'date')} disabledDate={disabledStartDate} />
            </Col>
            <Col xs={10} md={8}>
                <TimeSelect style={{ width: '100%' }} value={startDate} onChange={(value) => handleStartDateChange(value, 'time')} format="HH:mm" minuteStep={15} disabledDate={disabledStartDate} disabledTime={disabledStartTime} />
            </Col>
            <Col xs={24} md={4}>還車時間</Col>
            <Col xs={14} md={12}>
                <DatePicker style={{ width: '100%' }} value={endDate} onChange={(value) => handleEndDateChange(value, 'date')} disabledDate={disabledEndDate} />
            </Col>
            <Col xs={10} md={8}>
                <TimeSelect style={{ width: '100%' }} value={endDate} onChange={(value) => handleEndDateChange(value, 'time')} format="HH:mm" minuteStep={15} disabledDate={disabledEndDate} disabledTime={disabledEndTime} />
            </Col>
        </Row>
    )
}

export default PickAndReturnDatePicker