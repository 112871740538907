import { Col, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI'

function AuthStoreSelect({ onSelect, value: storeId, disabled, ...props }) {
    const me = TourniiAPI.auth.getLoginUser();
    const rolesexpand = me.rolesexpand;
    const companies = rolesexpand.companies;
    const [selectedCompanyId, setCompanyId] = useState(null);
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const selectedCompany = companies.find((company) => company.id === selectedCompanyId);
    const stores = selectedCompany ? selectedCompany.stores : null;

    const saveSelected = useCallback(() => {
        // console.log("saveSelected", selectedCompanyId, selectedStoreId);
        if (!selectedCompanyId && !selectedStoreId) return;
        localStorage.setItem("AuthStoreSelect:companyId", JSON.stringify(selectedCompanyId));
        localStorage.setItem("AuthStoreSelect:storeId", JSON.stringify(selectedStoreId));
    }, [selectedStoreId, selectedCompanyId]);

    useEffect(() => {
        saveSelected();
    }, [saveSelected]);

    const loadSelected = useCallback(() => {
        try {
            const savedCompanyId = JSON.parse(localStorage.getItem("AuthStoreSelect:companyId"));
            const savedStoreId = JSON.parse(localStorage.getItem("AuthStoreSelect:storeId"));
            // console.log("loadSelected", savedCompanyId, savedStoreId);
            return { savedCompanyId, savedStoreId };
        } catch (error) {
            localStorage.removeItem("AuthStoreSelect:companyId");
            localStorage.removeItem("AuthStoreSelect:storeId");
            console.log("Parse error", error);
        }
        return { savedCompanyId: null, savedStoreId: null };
    }, []);

    const handleSelect = useCallback((value) => {
        // console.log("handleSelect", value);
        setSelectedStoreId(value);
        if (onSelect) {
            onSelect(value);
        }
    }, [onSelect]);

    const handleSelectCompany = useCallback((value) => {
        setCompanyId(value);
        const company = companies.find((company) => company.id === value);
        const stores = company?.stores;
        const { savedStoreId } = loadSelected();

        // console.log("handleSelectCompany: savedStoreId", savedStoreId, stores)
        if (Array.isArray(stores) && stores.length > 0) {
            if (stores.find((row) => row.id === savedStoreId)) {
                //console.log("handleSelectCompany: load from save storeId", savedStoreId)
                handleSelect(savedStoreId);
            } else {
                handleSelect(stores[0].id);
            }
        }
    }, [companies, handleSelect, loadSelected]);

    useEffect(() => {
        // Auto select first store.
        if (!storeId && companies.length > 0) {
            const { savedCompanyId } = loadSelected();
            if (companies.find((row) => row.id === savedCompanyId)) {
                handleSelectCompany(savedCompanyId);
            } else {
                handleSelectCompany(companies[0].id);
            }
        }
        if (storeId && companies.length > 0) {
            const findCompanyIdByStoreId = (storeId) => {
                for (const company of companies) {
                    const store = company.stores.find((store) => parseInt(store.id) === Number.parseInt(storeId));
                    // console.log("store", store, storeId, company.stores);
                    if (store) {
                        return company.id;
                    }
                }
                return null; // 如果没有找到匹配的 storeId，则返回 null 或其他适当的值
            };
            const companyId = findCompanyIdByStoreId(storeId);
            setCompanyId(companyId);
        }

    }, [handleSelect, handleSelectCompany, loadSelected, companies, stores, storeId]);

    return (
        <>
            <Row gutter={[10, 10]} style={{ marginBottom: 10, width: '100%', maxWidth: 1000 }}>
                <Col xs={24} md={2} xxl={2} style={{ display: 'flex', alignItems: 'center' }}>車行: </Col>
                <Col xs={24} md={10} xxl={10}>
                    <Select
                        onSelect={handleSelectCompany}
                        value={selectedCompanyId}
                        disabled={disabled}
                        style={{ width: '100%', maxWidth: '100%' }}>
                        {companies.map((company) => <Select.Option key={company.id} value={company.id}>{company.name}</Select.Option>)}
                    </Select>
                </Col>
                <Col xs={24} md={2} xxl={2} style={{ display: 'flex', alignItems: 'center' }}>店家: </Col>
                <Col xs={24} md={10} xxl={10}>
                    <Select
                        onSelect={handleSelect}
                        value={storeId}
                        disabled={disabled}
                        style={{ width: '100%', maxWidth: '100%' }}
                        {...props}>
                        {stores && stores.map((store) => <Select.Option key={store.id} value={store.id}>{store.name}</Select.Option>)}
                    </Select>
                </Col>
            </Row>
        </>
    )
}

export default AuthStoreSelect