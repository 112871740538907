import { Button, Col, Form, Input, InputNumber, notification, Row, Space, Spin } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI'
import AuthStoreSelect from '../../components/AuthStoreSelect'
import OpenTimeTable from './components/OpenTimeTable'
import RegionSelect from '../../components/RegionSelect'
import ContentLayout from '../ContentLayout'
import { InfoCircleOutlined } from '@ant-design/icons';
import TextEditor from '../../components/TextEditor'

function StoreProfilePage() {
    const [storeId, setStoreId] = useState(null);
    const [editModalLoading, setEditModalLoading] = useState(false);
    const [editContractLoading, setEditContractLoading] = useState(false);
    const editForm = useRef();
    const contractForm = useRef();

    const refresh = useCallback(() => {
        if (!storeId) return;
        setEditModalLoading(true);
        TourniiAPI.store.stores.show(storeId).then((response) => {
            const result = response.data;
            if (editForm) {
                result.revenuesharerate = result.revenuesharerate * 100;
                editForm.current.setFieldsValue(result);
            }
        }).finally(() => {
            setEditModalLoading(false);
        });

        setEditContractLoading(true);
        TourniiAPI.store.stores.contracts.latest(storeId).then((response) => {
            const result = response.data;
            if (contractForm.current) {
                contractForm.current.setFieldsValue(result);
            }
        }).finally(() => {
            setEditContractLoading(false);

        });
    }, [storeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleFinish = (values) => {
        console.log(values);
        values.revenuesharerate = values.revenuesharerate / 100;
        setEditModalLoading(true);
        TourniiAPI.store.stores.update(storeId, values).then((response) => {
            notification.info({ message: '修改成功' });
        }).finally(() => {
            setEditModalLoading(false);
            refresh();
        });
    }
    const handleSaveContract = (values) => {
        setEditContractLoading(true);
        TourniiAPI.store.stores.contracts.store(storeId, values).then((response) => {
            notification.info({ message: '合約儲存成功' });
        }).finally(() => {
            setEditContractLoading(false);
            refresh();
        });
    }
    return (
        <ContentLayout title="店家資料">
            <Row>
                <Col flex="auto">
                    <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                </Col>
                <Col span={12}>
                </Col>
            </Row>

            <Spin spinning={editModalLoading}>
                <h3>店家資料</h3>
                <Form ref={editForm} onFinish={handleFinish} labelCol={{ span: 3 }}>
                    <Form.Item label="車行" name="company_id" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="店家名稱" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="地區" name="region_id">
                        <RegionSelect />
                    </Form.Item>
                    <Form.Item label="地址" name="address">
                        <Input />
                    </Form.Item>
                    <Form.Item label="統一編號" name="taxidnumber">
                        <Input />
                    </Form.Item>
                    <Form.Item label="聯絡人" name="contact">
                        <Input />
                    </Form.Item>
                    <Form.Item label="手機" name="contacttel">
                        <Input />
                    </Form.Item>
                    <Form.Item label="GPS 位置" name="gpslocation" hidden={true}
                        rules={[{ pattern: '^([-+]?)([\\d]{1,2})(((\\.)(\\d+)(,)))(\\s*)(([-+]?)([\\d]{1,3})((\\.)(\\d+))?)$', message: '格式錯誤，例: 22.6304461,120.2844837' }]}>
                        <Input placeholder="22.6304461,120.2844837" />
                    </Form.Item>
                    <Form.Item label="最低租賃 (時)" name="rentminhours">
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="自訂逾時門檻 (時)">
                        <Space>
                            <Form.Item name="overtimeexceedhours" noStyle={true}>
                                <InputNumber min={0} />
                            </Form.Item>
                            <div>
                                <InfoCircleOutlined /> 超過門檻小時以 24 小時計價
                            </div>
                        </Space>
                    </Form.Item>
                    {true && <Form.Item label="租借政策" name="rentpolicy">
                        <Input.TextArea rows={10} />
                    </Form.Item>}
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">儲存</Button>
                    </Form.Item>
                </Form>
            </Spin>
            <h3>營業時間</h3>
            <OpenTimeTable storeId={storeId} />
            {true && <>
                <h3>租賃合約</h3>
                <Spin spinning={editContractLoading}>
                    <Form ref={contractForm} onFinish={handleSaveContract} layout="vertical">
                        <Form.Item label="合約條款" name="description">
                            {/* <Input.TextArea rows={10} /> */}
                            <TextEditor />
                        </Form.Item>
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Button htmlType="submit" type="primary">儲存</Button>
                            </Space>
                        </div>
                    </Form>
                </Spin>
            </>}
        </ContentLayout>
    )
}

export default StoreProfilePage