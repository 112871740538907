import { Badge, Radio } from 'antd'
import React from 'react'
import { OrderStates } from '../api/TourniiAPI'

function OrderStateRadioGroupButton({ details, onChange, ...props }) {
    const getCountByState = (state) => {
        return details.filter((detail) => detail.state === state).length;
    }
    return (
        <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
            <Radio.Group buttonStyle="solid" defaultValue="wait-pick-up" onChange={onChange} style={{ textOverflow: '' }}>
                {
                    OrderStates.map((state) =>
                        <Radio.Button key={state.id} value={state.id}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: 5 }}>{state.name}</span>
                                <Badge key={`badge-${state.id}`} count={getCountByState(state.id)} style={{ backgroundColor: state.color }} />
                            </div>
                        </Radio.Button>
                    )
                }
            </Radio.Group>
        </div>
    )
}

export default OrderStateRadioGroupButton