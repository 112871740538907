import { Button, Col, notification, Row, Space, Spin } from 'antd'
import React, { useEffect } from 'react'
import Drawer from './Drawer';
import TakePhotoPanel from './TakePhotoPanel';

function VehiclePhotoUploadDrawer({ title, loading, onDelete, onFinish, visible, value, ...props }) {
    const [imageSrc, setImageSrc] = React.useState();

    useEffect(() => {
        setImageSrc(value);
    }, [value]);

    const handleGetPhoto = (dataUrl) => {
        setImageSrc(dataUrl);
    }
    const handleSubmit = () => {
        if (!imageSrc) {
            notification.warn({ message: '請上傳照片' });
            return;
        }
        if (onFinish) {
            onFinish(imageSrc);
        }
    }
    const handleDelete = () => {
        if (onDelete) {
            onDelete();
        }
    }
    return (
        <Drawer title={title ?? '車輛照片上傳'} open={visible} {...props}>
            <Spin spinning={!!loading}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <TakePhotoPanel visible={true} preview={imageSrc} onChange={handleGetPhoto} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Space>
                            {value && onDelete && <Button danger onClick={handleDelete}>刪除</Button>}
                            <Button type="primary" disabled={!imageSrc} onClick={handleSubmit}>送出</Button>
                        </Space>
                    </Col>
                </Row>
            </Spin>
        </Drawer>
    )
}

export default VehiclePhotoUploadDrawer