import { Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function CountrySelect({ value, ...props }) {
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const refresh = useCallback((query) => {
        setLoading(true);
        TourniiAPI.public.countries(query).then((response) => {
            const result = response.data;
            setDetail(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        refresh({ code: value });
    }, [refresh, value]);

    const handleSearch = (keyword) => {
        refresh({ keyword: keyword });
    }
    return (
        <Select loading={loading} showSearch={true} onSearch={handleSearch} allowClear value={value} {...props}>
            {detail.map((item) => <Select.Option key={item.code} value={item.code}>{`${item.cn} (${item.code})`}</Select.Option>)}
        </Select>
    )
}

export default CountrySelect