export const getDaysArray = function ([from, to], unit) {
    let date = from.clone();
    let result = [];
    while (date.diff(to, unit)) {
        result.push({
            date: date.clone(),
            unit: unit,
        });
        date.add(1, unit);
    }
    return result;
}