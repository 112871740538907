import { Checkbox, Col, List, Row, Spin, TimePicker } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import TourniiAPI from '../../../api/TourniiAPI'
import { weekNameToInt } from '../../../utils/openTime';

function OpenTimeTable({ storeId, ...props }) {
    const [loading, setLoading] = useState(false);
    const [openTimes, setOpenTimes] = useState([]);

    const refresh = useCallback(() => {
        const defaultOpentime = [0, 1, 2, 3, 4, 5, 6].map((dayofweek) => ({
            dayofweek: dayofweek,
            starttime: moment('08:00', 'H:m'),
            endtime: moment('18:00', 'H:m'),
            loading: false,
            holiday: true
        }));

        if (!storeId) return;
        setLoading(true);
        TourniiAPI.store.stores.openTimes.index(storeId, {}, 1, 999).then((response) => {
            const result = response.data;
            const results = defaultOpentime.map((openTime) => {
                const raw = result.data.find((o) => weekNameToInt(o.dayofweek) === openTime.dayofweek);
                return {
                    ...openTime,
                    starttime: raw ? moment(raw.starttime, "HH:mm:ss") : openTime.starttime,
                    endtime: raw ? moment(raw.endtime, "HH:mm:ss") : openTime.endtime,
                    holiday: !raw,
                    raw: raw
                };
            });
            setOpenTimes(results);
        }).finally(() => {
            setLoading(false);
        });
    }, [storeId]);

    useEffect(() => {
        refresh();
    }, [refresh]);


    const updateOpentime = (index, newData) => {
        const newOpentimes = Object.assign([], openTimes);
        const newOpentime = newOpentimes[index];
        Object.assign(newOpentime, newData);
        setOpenTimes(newOpentimes);
    }

    const handleUpdateTime = (index) => {
        const item = openTimes[index];
        if (item.holiday) {
            // 若為假日則刪除記錄
            updateOpentime(index, { loading: true });
            TourniiAPI.store.stores.openTimes.destroy(storeId, item.raw.id).then((response) => {
                updateOpentime(index, { raw: undefined });
            }).finally(() => {
                updateOpentime(index, { loading: false });
            });
        } else {
            // 否則判斷新增或修改
            const insertOrUpdateData = {
                starttime: item.starttime.format("HH:mm:ss"),
                endtime: item.endtime.format("HH:mm:ss"),
                dayofweek: item.dayofweek,
                holiday: item.holiday,
            };
            // console.log("insert or update", insertOrUpdateData);
            if (item.raw) {
                updateOpentime(index, { loading: true });
                TourniiAPI.store.stores.openTimes.update(storeId, item.raw.id, insertOrUpdateData).then((response) => {
                    updateOpentime(index, { raw: response.data });
                }).finally(() => {
                    updateOpentime(index, { loading: false });
                });
            } else {
                updateOpentime(index, { loading: true });
                TourniiAPI.store.stores.openTimes.store(storeId, insertOrUpdateData).then((response) => {
                    updateOpentime(index, { raw: response.data });
                }).finally(() => {
                    updateOpentime(index, { loading: false });
                });
            }

        }
    }
    const handleChecked = (item, index, event) => {
        // console.log("handleChecked", item, index, event);
        updateOpentime(index, { holiday: !item.holiday });
        handleUpdateTime(index);
    }
    const handleSetTimes = (item, index, times) => {
        // console.log("handleSetTimes", item, index, times);
        if (!times) return;
        const [starttime, endtime] = times;
        updateOpentime(index, { starttime: starttime, endtime: endtime });
        handleUpdateTime(index);
    }
    return (
        <Spin spinning={loading}>
            <List
                dataSource={openTimes}
                renderItem={(item, index) => <List.Item>
                    <Checkbox checked={!item.holiday} onChange={handleChecked.bind(this, item, index)}>
                        <Spin spinning={item.loading}>
                            <Row gutter={10}>
                                <Col span={6}>
                                    {moment(item.dayofweek, 'e').format("dddd")}
                                </Col>
                                <Col span={18}>
                                    <TimePicker.RangePicker format="HH:mm" minuteStep={15} value={[item.starttime, item.endtime]} onChange={handleSetTimes.bind(this, item, index)} disabled={item.holiday} />
                                </Col>
                            </Row>
                        </Spin>
                    </Checkbox>
                </List.Item>} />
        </Spin>
    )
}

export default OpenTimeTable