import { Button, Col, Collapse, DatePicker, Drawer, Form, Input, notification, Popconfirm, Row, Space, Spin, Table, Tabs, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI, { ORDERSTATE_NEW } from '../../api/TourniiAPI';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import ContentLayout from '../ContentLayout'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import OrderStateBadge from '../../components/OrderStateBadge';
import OrderStateRadioGroupButton from '../../components/OrderStateRadioGroupButton';
import { createObjectUrlFromResp } from '../../utils/download';

function OrdersPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [storeId, setStoreId] = useState(null);
    const [details, setDetails] = useState([]);
    const [detailFilterState, setDetailFilterState] = useState(ORDERSTATE_NEW);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [query, setQuery] = useState({});
    const [queryForm] = Form.useForm();

    const refresh = useCallback(() => {
        if (!storeId) return;
        setLoading(true);
        console.log(query);
        TourniiAPI.store.stores.orders.index(storeId, {
            ...query,
            startdate: query.startdate ? query.startdate.format("YYYY-MM-DD") : null,
            enddate: query.enddate ? query.enddate.format("YYYY-MM-DD") : null,
            expectpickupstartdate: query.expectpickupstartdate ? query.expectpickupstartdate.format("YYYY-MM-DD") : null,
            expectpickupenddate: query.expectpickupenddate ? query.expectpickupenddate.format("YYYY-MM-DD") : null,
        }, 1, 999).then((response) => {
            const result = response.data;
            setDetails(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [storeId, query]);

    const exportFile = () => {
        if (!storeId) {
            notification.warn({ message: '請先選擇店家' });
            return;
        }
        TourniiAPI.store.stores.orders.export(storeId, {}).then((response) => {
            createObjectUrlFromResp(response, `tournii-store-orders-${moment().format('YYYY-MM-DD')}.xlsx`);
        })
    }

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = [
        {
            title: '訂單編號',
            dataIndex: 'serialno',
            render: (value, record) => <Typography.Link onClick={() => handleShowRentPage(record)}>{value}</Typography.Link>,
            sorter: (a, b) => a.serialno.localeCompare(b.serialno),

        },
        // {
        //     title: '車行',
        //     dataIndex: 'xx',
        // },
        // {
        //     title: '店家',
        //     dataIndex: 'xx',
        // },
        {
            title: '訂單時間',
            dataIndex: 'created_at',
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
            sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at), 'second'),
        },
        {
            title: '預計取車',
            dataIndex: 'expectpickuptime',
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
            sorter: (a, b) => moment(a.expectpickuptime).diff(moment(b.expectpickuptime), 'second'),

        },
        {
            title: '預計還車',
            dataIndex: 'expectreturntime',
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
            sorter: (a, b) => moment(a.expectreturntime).diff(moment(b.expectreturntime), 'second'),
        },
        {
            title: '狀態',
            dataIndex: 'state',
            render: (state) => <OrderStateBadge state={state} />,
        },
        {
            title: '承租者',
            dataIndex: 'rentername',
            render: (rentername) => rentername ? rentername : '無',
            sorter: (a, b) => a.rentername?.localeCompare(b.rentername),

        },
        {
            title: '手機',
            dataIndex: 'rentercellphone',
            render: (rentercellphone) => rentercellphone ? rentercellphone : '無',
            sorter: (a, b) => a.rentercellphone?.localeCompare(b.rentercellphone),
        },
        {
            title: '訂單備註',
            dataIndex: 'processmemo',
        },
        {
            title: '承租者備註',
            dataIndex: 'renternote'
        },
        {
            title: '總額',
            dataIndex: 'totalamount',
            align: 'right',
            sorter: (a, b) => a.totalamount - b.totalamount,
            // fixed: 'right',
        },
        {
            title: '已收',
            dataIndex: 'receivedamount',
            align: 'right',
            sorter: (a, b) => a.receivedamount - b.receivedamount,
            // fixed: 'right',
        },
        {
            title: '尚欠',
            dataIndex: 'accountsreceivable',
            align: 'right',
            sorter: (a, b) => a.accountsreceivable - b.accountsreceivable,
            // fixed: 'right',
        },
        {
            title: '動作',
            // fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowRentPage(record)}>修改</Typography.Link>
                <Popconfirm title="你確定是否要通知客戶此張訂單被取消?" onConfirm={() => handleCancelOrder(record.id)}>
                    <Typography.Link>取消</Typography.Link>
                </Popconfirm>
            </Space>
        }
    ]
    const handleChangeFilter = (event) => {
        setDetailFilterState(event.target.value);
    }
    const getFilteredDetails = () => {
        if (!detailFilterState) return details;
        return details.filter((detail) => {
            return detail.state === detailFilterState
        });
    }
    const handleShowRentPage = (record) => {
        console.log('handleShowRentPage', record)
        if (record) {
            navigate(`/store/orders/${record.id}`);
        } else {
            navigate(`/store/rent`);
        }
    }

    const handleCancelOrder = (orderId) => {
        if (!orderId) return;
        setLoading(true);
        TourniiAPI.store.stores.orders.destroy(storeId, orderId).then((response) => {
            notification.info({ message: '訂單取消成功' });
            refresh();
        }).finally(() => {
            setLoading(false);
        })
    }
    const handleFinishQueryForm = (values) => {
        setQuery(values);
    }
    const handleResetQueryForm = () => {
        queryForm.resetFields();
        queryForm.submit();
    }
    return (
        <ContentLayout title="訂單管理">
            <Spin spinning={loading}>
                <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
                    <Col flex="auto">
                        <AuthStoreSelect value={storeId} onSelect={(value) => setStoreId(value)} />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Collapse>
                    <Collapse.Panel key="1" header="進階查詢" style={{ marginBottom: 10 }}>
                        <Form form={queryForm} onFinish={handleFinishQueryForm} labelCol={{ sm: { span: 4 }, md: { span: 3 } }}>
                            <Form.Item label="訂單期間">
                                <Form.Item name="startdate" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0, marginRight: 10 }}>
                                    <DatePicker allowClear={true} style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item name="enddate" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}>
                                    <DatePicker allowClear={true} style={{ width: '100%' }} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="預計取車">
                                <Form.Item name="expectpickupstartdate" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0, marginRight: 10 }}>
                                    <DatePicker allowClear={true} style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item name="expectpickupenddate" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}>
                                    <DatePicker allowClear={true} style={{ width: '100%' }} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="姓名" name="rentername">
                                <Input allowClear={true} />
                            </Form.Item>
                            <Form.Item label="手機" name="rentercellphone">
                                <Input allowClear={true} />
                            </Form.Item>
                            <Form.Item label="車牌號碼" name="vehicleplatenumber">
                                <Input allowClear={true} />
                            </Form.Item>
                            <div style={{ textAlign: 'right' }}>
                                <Space>
                                    <Button onClick={handleResetQueryForm}>清空</Button>
                                    <Button htmlType="submit" type="primary">查詢</Button>
                                </Space>
                            </div>
                        </Form>
                    </Collapse.Panel>
                </Collapse>

                <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
                    <Col flex="auto">
                        <OrderStateRadioGroupButton details={details} onChange={handleChangeFilter} />
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={() => handleShowRentPage()}>新增</Button>
                            <Button onClick={() => exportFile()}>匯出</Button>
                        </Space>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={getFilteredDetails()} rowKey="id" scroll={{ x: 'max-content' }} />
                <Drawer title="新增/修改訂單" size="large" open={editModalVisible} onClose={() => setEditModalVisible(false)}>
                    <Tabs defaultActiveKey='1'>
                        <Tabs.TabPane key="1" tab="基本資料">
                            <Form labelCol={{ span: 6 }}>
                                <Form.Item label="訂單編號" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="車行" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="店家" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="取車時間" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="還車時間" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="承租者" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="手機" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="金額" name="">
                                    <Input />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                    <Button type="primary" onClick={() => setEditModalVisible(false)}>送出</Button>
                                </Form.Item>
                            </Form>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="訂單明細">

                        </Tabs.TabPane>
                        <Tabs.TabPane key="3" tab="車輛資訊">

                        </Tabs.TabPane>
                    </Tabs>

                </Drawer>
            </Spin>
        </ContentLayout>
    )
}

export default OrdersPage