import { Select } from 'antd';
import React, { useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function RegionSelect({ onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [regions, setRegions] = useState([]);
    const refresh = () => {
        setLoading(true);
        TourniiAPI.regions.index({}, 1, 999).then((response) => {
            const result = response.data;
            setRegions(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        refresh();
    }, []);
    return (
        <Select
            loading={loading}
            allowClear={true}
            onSelect={onSelect}
            onClear={onSelect}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            style={{ width: 200, maxWidth: '100%' }}
            {...props}
        >
            {regions.map((region) => <Select.Option key={region.id} value={region.id}>{region.name}</Select.Option>)}
        </Select>
    )
}

export default RegionSelect