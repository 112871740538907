import "./MainLayout.css";
import React, { useState } from 'react'
import { Layout } from 'antd'
import TourniiMenu from "../components/TourniiMenu";
import { MenuContext } from "./MenuContext";

function MainLayout(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(true);

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };
  const hideMask = () => {
    toggleMenu();
  };
  const handleBreakpoint = (isMobile) => {
    setIsMobile(isMobile);
    if (!isMobile && collapsed) {
      setCollapsed(false);
    }
    if (isMobile && !collapsed) {
      setCollapsed(true);
    }
  };
  return (
    <Layout style={{ height: '100%' }}>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="md"
        onBreakpoint={handleBreakpoint}
        collapsedWidth={isMobile ? 0 : 60}
        style={
          isMobile
            ? { position: "absolute", height: "100vh", zIndex: 99, overflowY: 'auto' }
            : { overflowY: 'auto' }
        }
        className="layout-sider"
      >
        <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
          <img alt="Tournii" style={{ width: '90%', maxWidth: 80 }} src="/logo192.png" />
        </div>
        <TourniiMenu onClick={() => {
          if (isMobile) {
            setTimeout(() => {
              setCollapsed(true)
            }, 200);
          }
        }} />
      </Layout.Sider>
      <div
        onClick={hideMask}
        style={{
          display:
            isMobile && !collapsed ? "block" : "none",
          position: "absolute",
          height: "100vh",
          width: "100%",
          opacity: 1,
          backgroundColor: "rgba(0,0,0,.45)",
          animation: "antdDrawerFadeIn .3s cubic-bezier(.7,.3,.1,1)",
          zIndex: 98,
        }}
      ></div>
      <MenuContext.Provider value={{ toggleMenu: toggleMenu, collapsed: collapsed }}>
        {props.children ? props.children : "無內容"}
      </MenuContext.Provider>
    </Layout>
  )
}
export default MainLayout