import { Button, Col, Result, Row, Spin, Tag } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TourniiAPI from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout'
import moment from 'moment';
import PublicPlaceSelect, { getPlaceKeyByType } from '../../components/PublicPlaceSelect';
import PickAndReturnDatePicker from '../../components/PickAndReturnDatePicker';
import RwdInputNumber from '../../components/RwdInputNumber';
import { DATETYPE_LIMIT_AMOUNTS, getMinPriceDateType } from '../../utils/dateType';

const VehicleCard = ({ vehicleSpecInv }) => {
  const rentable = vehicleSpecInv.rentable;
  const minPriceDateType = getMinPriceDateType(vehicleSpecInv);
  return <div style={{
    opacity: rentable ? 1 : 0.5,
    background: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.16), 0 1px 3px rgba(0,0,0,0.23)',
    borderRadius: 10,
    padding: 15,
    height: '100%'
  }}>
    <Row>
      <Col span={24} style={{ textAlign: 'right' }}>
        {!rentable && <Tag>已售完</Tag>}
      </Col>
      <Col span={24}>
        <img
          style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
          alt={vehicleSpecInv.vehiclespec.name}
          src={TourniiAPI.getImageHostUrl(vehicleSpecInv.vehiclespec.photos[0].thumbnailfileurl)} />
      </Col>
      <Col span={24}>
        <h3 style={{ textAlign: 'center' }}>{vehicleSpecInv.vehiclespec.vehicletype.brand.name} {vehicleSpecInv.vehiclespec.name}</h3>
      </Col>
      <Col span={24}>
        {
          DATETYPE_LIMIT_AMOUNTS.map((priceCol) => {
            return minPriceDateType[priceCol.col] ? <h4 key={priceCol.col} style={{ textAlign: 'center' }}>
              {priceCol.unit}租還 {minPriceDateType[priceCol.col]} 元起
            </h4> : null;
          })
        }
        {false && <h4 style={{ textAlign: 'center' }}>{minPriceDateType.hourprice} 元起/時</h4>}
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>
        <h5 style={{ textAlign: 'center' }}>
          {vehicleSpecInv.store.company.name} {vehicleSpecInv.store.name}
        </h5>
      </Col>
    </Row>
  </div>;
}
function SelectDateAndVehiclePage() {
  const { regionId } = useParams();
  const navigate = useNavigate();

  const [searchParams,] = useSearchParams();
  const type = searchParams.get('type');
  const typeId = searchParams.get('typeId');
  const queryStartTime = searchParams.get("starttime");
  const queryEndTime = searchParams.get("endtime");

  const getDateRound = (minutes) => {
    const minute = moment().minute();
    return moment().minute(minute - minute % minutes).second(0);
  }
  const [selectedPeriod, setSelectedPeriod] = useState({
    startDate: queryStartTime ? moment(queryStartTime) : getDateRound(30),
    endDate: queryEndTime ? moment(queryEndTime) : getDateRound(30).add(1, 'hour'),
  });
  const [searchQuantity, setSearchQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  const startTime = selectedPeriod?.startDate?.format("YYYY-MM-DD HH:mm:ss");
  const endTime = selectedPeriod?.endDate?.format("YYYY-MM-DD HH:mm:ss");

  const fetchListingVehicles = useCallback(() => {
    setLoading(true);
    TourniiAPI.public.listingVehicles.index({
      region_id: regionId,
      type: type,
      type_id: typeId,
      expectpickuptime: startTime,
      expectreturntime: endTime,
      quantity: searchQuantity
    }, 1, 999).then((response) => {
      const result = response.data;
      console.log(result);
      if (!Array.isArray(result) && typeof result === 'object') {
        setDetails(Object.values(result));
      } else {
        setDetails(result);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [regionId, type, typeId, startTime, endTime, searchQuantity]);

  useEffect(() => {
    fetchListingVehicles();
  }, [fetchListingVehicles]);

  const handleSelectPlace = (value) => {
    if (value) {
      navigate(`/search/?type=${value.type}&typeId=${value.id}`);
    }
  }

  const placeValue = getPlaceKeyByType(type, typeId);
  return (
    <ContentLayout title="租機車">
      <Spin spinning={loading}>
        <Row>
          <Col xs={24} md={16} xl={12} xxl={8}>
            <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
              <Col span={24}>
                <Button onClick={() => navigate('/')}>返回</Button>
              </Col>
              <Col xs={24} md={4}>地點</Col>
              <Col xs={24} md={20}>
                <PublicPlaceSelect style={{ width: '100%' }} defaultValue={placeValue} onSelect={handleSelectPlace} />
              </Col>
              <Col span={24}>
                <PickAndReturnDatePicker value={selectedPeriod} onChange={(period) => setSelectedPeriod(period)} />
              </Col>
              <Col xs={24} md={4}>預訂數量</Col>
              <Col xs={24} md={20}>
                <RwdInputNumber min={1} max={10} value={searchQuantity} onChange={(value) => setSearchQuantity(value)} />
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={4} xl={6} xxl={8}></Col>
          <Col xs={0} md={4} xl={6} xxl={8}></Col>
        </Row>
        <Row gutter={[10, 10]}>
          {!loading && details.length === 0 && <Col span={24}>
            <Result title="查無可預訂車輛，請選擇其他地區或店家" />
          </Col>}
          {details.map((vehicleSpecInv) =>
            <Col key={vehicleSpecInv.id} xs={24} sm={12} md={8} lg={6} xl={6} xxl={4} >
              {vehicleSpecInv.rentable ?
                <Link to={`/add-to-cart/${vehicleSpecInv.id}?starttime=${startTime}&endtime=${endTime}&qty=${searchQuantity}`}>
                  <VehicleCard vehicleSpecInv={vehicleSpecInv} startTime={startTime} endTime={endTime} qty={searchQuantity} />
                </Link>
                : <>
                  <VehicleCard vehicleSpecInv={vehicleSpecInv} startTime={startTime} endTime={endTime} qty={searchQuantity} />
                </>}
            </Col>
          )}
        </Row>
      </Spin>
    </ContentLayout>
  )
}

export default SelectDateAndVehiclePage