import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI';

function VehicleBrandSelect({ onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [vehicleBrands, setVehicleBrands] = useState([]);
    const refresh = useCallback(() => {
        setLoading(true);
        TourniiAPI.vehicleBrands.index({}, 1, 999).then((response) => {
            const result = response.data;
            setVehicleBrands(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, []);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select {...props}
            loading={loading}
            allowClear={true}
            onSelect={onSelect}
            onClear={onSelect}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
        >
            {vehicleBrands.map((vehicleBrand) => <Select.Option key={vehicleBrand.id} value={vehicleBrand.id}>{vehicleBrand.name}</Select.Option>)}
        </Select>
    )
}

export default VehicleBrandSelect