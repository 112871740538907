export const disableConsole = (function () {
    var savedConsole = console.log;
    /**
    * @param {boolean} disabled
    */
    return function (disabled) {
        if (disabled === true) {
            console.log = function () { };
        } else {
            console.log = savedConsole;
        }
    };
})();