import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import localization from 'moment/locale/zh-tw';
import { disableConsole } from './utils/disable-console';

if (process.env.NODE_ENV === 'production') {
  // disable console.log when production build
  console.disable = disableConsole
  console.disable(true);
}

moment.locale("zh-tw", localization);

const liff = window.liff;
// console.log("liff init", process.env.REACT_APP_LINE_LIFF_ID);
liff.init({
  liffId: process.env.REACT_APP_LINE_LIFF_ID, // Use own liffId
});

const TPDirect = window.TPDirect;
TPDirect.setupSDK(process.env.REACT_APP_TAPPAY_APP_ID, process.env.REACT_APP_TAPPAY_APP_KEY, process.env.REACT_APP_TAPPAY_SERVER_TYPE);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

