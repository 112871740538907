import { Button, Col, notification, Row, Space } from 'antd'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PickAndReturnDatePicker from '../../components/PickAndReturnDatePicker';
import PublicPlaceSelect from '../../components/PublicPlaceSelect';
import ContentLayout from '../ContentLayout';
import moment from 'moment';
import TourniiAPI from '../../api/TourniiAPI';

const getDateRound = (minutes) => {
  const minute = moment().minute();
  return moment().minute(minute - minute % minutes + minutes).second(0);
}
function SelectRegionPage() {
  const navigate = useNavigate();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState({
    startDate: getDateRound(30),
    // endDate: getDateRound(30).add(60, 'minute')
  });
  const handleSelectPlace = (value) => {
    console.log(value);
    setSelectedPlace(value);
  }
  const handleNext = () => {
    const { startDate, endDate } = selectedPeriod;

    if (!selectedPlace) {
      notification.warn({ message: '請先選擇地點' });
      return;
    }
    if (!endDate) {
      notification.warn({ message: '請選擇還車時間' });
      return;
    }
    if (endDate.isBefore(startDate)) {
      notification.warn({ message: '還車時間不得早於取車時間' });
      return;
    }
    const paramsToQuery = (obj) => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }
    const params = {
      type: selectedPlace.type,
      typeId: selectedPlace.id ?? '',
      starttime: startDate?.format("YYYY-MM-DD HH:mm:ss"),
      endtime: endDate?.format("YYYY-MM-DD HH:mm:ss")
    }
    const regionUrl = `/search/?${paramsToQuery(params)}`
    navigate(regionUrl);
    return;
  }
  const handleOnChange = (value) => {
    setSelectedPeriod(value);
    const { startDate, endDate } = value;
    console.log("handleOnChange", startDate?.format("lll"), endDate?.format("lll"));
  }
  const loginUser = TourniiAPI.auth.getLoginUser();
  return (
    <ContentLayout>
      <Row gutter={[10, 10]}>
        <Col xs={0} md={4} xl={6} xxl={8}></Col>
        <Col xs={24} md={16} xl={12} xxl={8}>
          <Col span={24}>
            <h3>{loginUser ? `Hi ${loginUser.name}! ` : ''}想在那裡租車呢?</h3>
          </Col>
          <Row gutter={[10, 10]}>
            <Col xs={24} md={4}>地點</Col>
            <Col xs={24} md={20}>
              <PublicPlaceSelect onSelect={handleSelectPlace} placeholder="租車的地點或店家名稱" style={{ width: '100%' }} />
            </Col>
            <Col span={24}>
              <PickAndReturnDatePicker value={selectedPeriod} onChange={handleOnChange} />
            </Col>
            <Col xs={24} md={24}>
              <Button style={{ width: '100%' }} type="primary" onClick={handleNext}>下一步</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24}>
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Space>
                  <Link to="/privacy">隱私權保護政策</Link>
                </Space>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={4} xl={6} xxl={8}></Col>
      </Row>
    </ContentLayout>
  )
}

export default SelectRegionPage