import { Button, Checkbox, Col, Form, Input, notification, Row, Space, Typography } from 'antd';
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TourniiAPI from '../../api/TourniiAPI';
import PublicStoreSelect from '../../components/PublicStoreSelect';
import ContentLayout from '../ContentLayout';
import LineLoginButton from '../../components/sociallogin/LineLoginButton';

const liff = window.liff;

function LoginPage() {
  const navigate = useNavigate();
  const [searchParams,] = useSearchParams();
  const isLogined = TourniiAPI.auth.getLoginUser();
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState('user');

  // print the environment in which the LIFF app is running
  const handleLineLogin = () => {
    const redirect = searchParams.get('redirect');
    if (!liff.isLoggedIn()) {
      TourniiAPI.auth.lineLogin({ redirect: redirect });
    } else {
      notification.warn({ message: 'Already logined.' });
    }
  }

  const handleNormalLogin = (values) => {
    // console.log(values);
    values.logintype = loginType;
    setLoading(true);
    TourniiAPI.auth.login(values.email, values.password, values.remember, values.logintype, values.store_id).then((response) => {
      // notification.info({ message: '登入成功' });
      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'));
      } else {
        navigate('/');
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <ContentLayout>
      {isLogined ? <p>您已登入成功，可由選單點選租車進行租車。</p> : <>
        <Row>
          <Col span={24} style={{ textAlign: 'left', color: 'white' }}>
            <h2>登入完成預訂</h2>
            <h3>立即登入，取得車輛預訂</h3>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} xl={12}>
            <Row gutter={[10, 10]}>
              {loginType !== 'store' && <>
                <Col span={24}>
                  <LineLoginButton onClick={handleLineLogin} />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  或
                </Col>
              </>}
              <Col span={24}>
                <Form labelCol={{ span: 6 }} onFinish={handleNormalLogin} initialValues={{ logintype: 'user', remember: true }}>
                  <Form.Item label="店家" name="store_id" hidden={loginType !== 'store'}>
                    <PublicStoreSelect />
                  </Form.Item>
                  <Form.Item name="email" label="電子郵件">
                    <Input placeholder="請輸入您的電子郵件位置" />
                  </Form.Item>
                  <Form.Item name="password" label="密碼">
                    <Input.Password placeholder="請輸入您的密碼" />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item name="remember" valuePropName="checked" style={{ display: 'inline-block', width: '50%' }} >
                      <Checkbox>記住登入狀態</Checkbox>
                    </Form.Item>
                    <Form.Item style={{ display: 'inline-block', width: '50%', textAlign: 'right' }} >
                      <Link to="/forgot-password">忘記密碼? </Link>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>登入</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Space>
                  新使用者請點選此處
                  <Link to="/signup">
                    <Button type="primary">註冊</Button>
                  </Link>
                </Space>
              </Col>
              {false && <Col span={24} style={{ textAlign: 'center' }}>
                {loginType === 'user' && <Typography.Link onClick={() => setLoginType('store')}>店家後台</Typography.Link>}
                {loginType === 'store' && <Typography.Link onClick={() => setLoginType('user')}>一般使用者</Typography.Link>}
              </Col>}
            </Row>
          </Col>
        </Row>
      </>
      }
    </ContentLayout>
  )
}

export default LoginPage