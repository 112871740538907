import { Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI'

function AdminCompanySelect({ onSelect, ...props }) {
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const refresh = useCallback(() => {
        setLoading(true);
        TourniiAPI.admin.companies.index({}, 1, 999).then((response) => {
            const result = response.data;
            setCompanies(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, []);
    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select {...props} loading={loading} allowClear={true} onSelect={onSelect} onClear={onSelect}>
            {companies.map((company) => <Select.Option key={company.id} value={company.id}>{company.name}</Select.Option>)}
        </Select>
    )
}

export default AdminCompanySelect