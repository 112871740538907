import { Empty, Select, Spin, Tag } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../api/TourniiAPI'

export const getPlaceKeyByType = (type, typeId) => !type && !typeId ? undefined : `${type}-${typeId}`
export const getTypeByPlaceKey = (key) => {
    if (key) {
        return key.split('-');
    }
}

function PublicPlaceSelect({ onSelect, defaultValue, ...props }) {
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState();
    const [items, setItems] = useState([]);
    const refresh = useCallback(() => {
        if (!searchKeyword && !defaultValue) return;
        setLoading(true);
        let type, typeId;

        if (!searchKeyword && defaultValue) {
            [type, typeId] = getTypeByPlaceKey(defaultValue);
        };
        TourniiAPI.public.places({ keyword: searchKeyword, type: type, type_id: typeId }, 1, 10).then((response) => {
            const result = response.data;
            setItems(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [searchKeyword, defaultValue]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleSearch = (value) => {
        setSearchKeyword(value);
    }
    const typeToName = (type) => {
        switch (type) {
            case 'company': return <Tag color="blue">車行</Tag>;
            case 'store': return <Tag color="green">店家</Tag>;
            default:
            case 'region': return <Tag color="red">地區</Tag>;
        }
    }
    const handleSelect = (value) => {
        if (!value) return;
        const [type, id] = value.split('-');
        const found = items.find((item) => item.type === type && item.id === parseInt(id));
        if (onSelect) {
            onSelect(found);
        }
    }
    const handleClear = () => {
        setItems([]);
        // refresh();
        onSelect();
    }
    return (
        <Select
            loading={loading}
            allowClear={true}
            onSelect={handleSelect}
            onClear={handleClear}
            showSearch={true}
            onSearch={handleSearch}
            notFoundContent={loading ? <div style={{ textAlign: 'center' }}><Spin /></div> : <Empty description="請輸入店家名稱或地區關鍵字" />}
            filterOption={false}
            // optionFilterProp="children"
            // filterOption={(input, option) => option.children.includes(input)}
            style={{ width: 200, maxWidth: '100%' }}
            defaultValue={defaultValue}
            {...props}>

            {items.map((item) => <Select.Option key={getPlaceKeyByType(item.type, item.id)} value={getPlaceKeyByType(item.type, item.id)}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>{item.name}</div>
                    <div>{typeToName(item.type)}</div>
                </div>
            </Select.Option>)}
        </Select>
    )
}

export default PublicPlaceSelect