import { List, notification, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import BadgeListItem from '../../components/BadgeListItem'
import CredentialUploadDrawer from '../../components/CredentialUploadDrawer';
import EmptyRenterResult from '../../components/EmptyRenterResult';
import ContentLayout from '../ContentLayout'

function UserCredentialPage() {
    const user = TourniiAPI.auth.getLoginUser();
    const renterId = user ? user.renter_id : null;

    const [validation, setValidation] = useState({});
    const [loading, setLoading] = useState(false);
    // CredentialUploadDrawer
    const [showCredentialUpload, setShowCredentialUpload] = useState(false);
    const [credentialUploadType, setCredentialUploadType] = useState();
    const [credentialUploadSide, setCredentialUploadSide] = useState();
    const [credentialSrc, setCredentialSrc] = useState();

    const refresh = useCallback(() => {
        if (!renterId) return;
        setLoading(true);
        TourniiAPI.renter.renters.show(renterId).then((response) => {
            const result = response.data;
            const validation = result.validation;
            setValidation(validation);
        }).finally(() => {
            setLoading(false);
        })
    }, [renterId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    // 證件上傳
    const handleShowCredentialUpload = (type, side) => {
        console.log("handleShowCredentialUpload", type, side);
        if (!renterId) {
            notification.warn({ message: '缺少資料 renterId' });
            return;
        }
        setCredentialUploadType(type);
        setCredentialUploadSide(side);
        TourniiAPI.renter.credentials.show(renterId, type, side).then((response) => {
            const result = response.data;
            setCredentialSrc(result.filedata);
        }).catch((error) => {
            setCredentialSrc(null);
        }).finally(() => {
            setShowCredentialUpload(true);
        });
    }

    const handleHideCredentialUpload = () => {
        setShowCredentialUpload(false);
        setCredentialUploadType();
        setCredentialSrc(null);
    }

    const handleFinishCredentialUpload = (dataUrl) => {
        console.log("handleFinishCredentialUpload", dataUrl);
        if (!renterId) {
            notification.warn({ message: '缺少資料 renterId' });
            return;
        }
        if (!dataUrl) {
            notification.warn({ message: '請上傳照片' })
            return;
        }
        const file = TourniiAPI.dataUrlToFile(dataUrl);
        const data = {
            name: 'test',
            imagefile: file,
        }
        TourniiAPI.renter.credentials.store(renterId, credentialUploadType, credentialUploadSide, data).then((response) => {
            notification.info({ message: '證件上傳成功' })
            const result = response.data;
            console.log(result);
            refresh();
        }).finally(() => {
            setShowCredentialUpload(false);
            setCredentialSrc(null);
        })
    }

    const handleDeleteCredentialUpload = () => {
        if (!renterId) {
            notification.warn({ message: '缺少資料 renterId' });
            return;
        }
        TourniiAPI.renter.credentials.destroy(renterId, credentialUploadType, credentialUploadSide).then((response) => {
            notification.info({ message: '證件刪除成功' })
            refresh();
        }).finally(() => {
            setShowCredentialUpload(false);
            setCredentialSrc(null);
        });
    }
    return (
        <ContentLayout title="證件上傳">
            <EmptyRenterResult renterId={renterId}>
                <Spin spinning={loading}>
                    <List bordered style={{ backgroundColor: 'white' }}>
                        <BadgeListItem valid={validation['credentials-id-card-front']} onClick={handleShowCredentialUpload.bind(this, 'id-card', 'front')}>
                            上傳證件 (正面)
                        </BadgeListItem>
                        <BadgeListItem valid={validation['credentials-id-card-back']} onClick={handleShowCredentialUpload.bind(this, 'id-card', 'back')}>
                            上傳證件 (反面)
                        </BadgeListItem>
                        <BadgeListItem valid={validation['credentials-license-front']} onClick={handleShowCredentialUpload.bind(this, 'license', 'front')}>
                            上傳駕照 (正面)
                        </BadgeListItem>
                        {false && <BadgeListItem valid={validation['credentials-license-back']} onClick={handleShowCredentialUpload.bind(this, 'license', 'back')}>
                            上傳駕照 (反面)
                        </BadgeListItem>}
                    </List>
                    <CredentialUploadDrawer
                        visible={showCredentialUpload}
                        value={credentialSrc}
                        onFinish={handleFinishCredentialUpload}
                        onClose={handleHideCredentialUpload}
                        onDelete={handleDeleteCredentialUpload}
                    />
                </Spin>
            </EmptyRenterResult>
        </ContentLayout>
    )
}

export default UserCredentialPage