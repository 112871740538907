import { Button, Col, Drawer, Form, InputNumber, Row, Select, Space, Table, Typography } from 'antd'
import React, { useState } from 'react'
import ContentLayout from '../ContentLayout'

function VehiclesPage() {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const columns = [
        {
            title: '品牌',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '車款',
            dataIndex: 'xx',
            key: 'xx',
        },
        {
            title: '車型',
            dataIndex: 'xx',
            key: 'xx',
        },
        {
            title: '車牌號碼',
            dataIndex: 'xx',
            key: 'xx',
        },
        {
            title: '最近出租',
            dataIndex: 'xx',
            key: 'xx',
        },
        {
            title: '里程',
            dataIndex: 'xx',
            key: 'xx',
        },
        {
            title: '動作',
            fixed: 'right',
            render: () => <Space>
                <Typography.Link>訂單</Typography.Link>
                <Typography.Link>車況</Typography.Link>
                <Typography.Link onClick={() => setEditModalVisible(true)}>修改</Typography.Link>
                <Typography.Link>刪除</Typography.Link>
            </Space>
        }
    ]
    return (
        <ContentLayout title="車輛管理">
            <Form style={{ marginBottom: 20 }}>
                <Row gutter={[10, 10]}>
                    <Col flex="auto">
                        <Form.Item label="品牌">
                            <Select>
                                <Select.Option>全部</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col flex="auto">
                        <Form.Item label="車款">
                            <Select>
                                <Select.Option>全部</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={() => setEditModalVisible(true)}>新增</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table columns={columns} dataSource={[{ name: 'Example' }]} rowKey="id" scroll={{ x: 'max-content' }} />
            <Drawer title="新增/修改車輛" open={editModalVisible} onClose={() => setEditModalVisible(false)}>
                <Form labelCol={{ span: 6 }}>
                    <Form.Item label="品牌" name="">
                        <Select>
                            <Select.Option>KYMCO</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="車款" name="">
                        <Select>
                            <Select.Option>MANY</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="車型" name="">
                        <Select>
                            <Select.Option>MANY 110 EV</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="車牌號碼" name="">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                        <Button type="primary" onClick={() => setEditModalVisible(false)}>送出</Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </ContentLayout>
    )
}

export default VehiclesPage