import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import TourniiAPI from '../../../api/TourniiAPI';
import OrderItemVehicleDrawer from '../../../components/OrderItemVehicleDrawer';

function RenterOrderItemVehicleDrawer({
    renterId,
    orderId,
    itemId,
    ...props
}, ref) {
    const drawerRef = useRef();
    useImperativeHandle(ref, () => ({
        refresh: drawerRef.current.refresh()
    }));

    const handleItemVehicleShow = (editId) => TourniiAPI.renter.renters.orders.items.vehicles.show(renterId, orderId, itemId, editId);
    const handleItemVehicleStore = (values) => TourniiAPI.renter.renters.orders.items.vehicles.store(renterId, orderId, itemId, values);
    const handleItemVehicleUpdate = (editId, values) => TourniiAPI.renter.renters.orders.items.vehicles.update(renterId, orderId, itemId, editId, values);
    const handleItemVehiclePickup = (editId) => TourniiAPI.renter.renters.orders.items.vehicles.pickup(renterId, orderId, itemId, editId);
    const handleItemVehicleReturn = (editId) => TourniiAPI.renter.renters.orders.items.vehicles.return(renterId, orderId, itemId, editId);
    const handleItemVehiclePhotoShow = (vehicleId, type) => TourniiAPI.renter.renters.orders.items.vehicles.photos.show(renterId, orderId, itemId, vehicleId, type);
    return (
        <OrderItemVehicleDrawer
            ref={drawerRef}
            onItemVehicleShow={handleItemVehicleShow}
            onItemVehicleStore={handleItemVehicleStore}
            onItemVehicleUpdate={handleItemVehicleUpdate}
            onItemVehiclePickup={handleItemVehiclePickup}
            onItemVehicleReturn={handleItemVehicleReturn}
            onItemVehiclePhotoShow={handleItemVehiclePhotoShow}
            {...props}
        />
    )
}

export default forwardRef(RenterOrderItemVehicleDrawer)