import './App.less';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { ConfigProvider, Skeleton } from 'antd';
import zhTW from 'antd/es/locale/zh_TW';

import TourniiAPI from './api/TourniiAPI';
import PermissionManager from './PermissionManager';

import NotFoundPage from './containers/NotFoundPage';
import MainLayout from './containers/MainLayout';

// Anonymous
import { OrderCartContext, orderCartReducer, initOrderCart, getOrderCartInitialContext } from './OrderCartContext';
import LoginPage from './containers/anonymous/LoginPage';
import SelectRegionPage from './containers/anonymous/SelectRegionPage';
import SelectDateAndVehiclePage from './containers/anonymous/SelectDateAndVehiclePage';
import AddToCartPage from './containers/anonymous/AddToCartPage';
import CartListPage from './containers/anonymous/CartListPage';
import ForgotPasswordPage from './containers/anonymous/ForgotPasswordPage';
import ResetPasswordPage from './containers/anonymous/ResetPasswordPage';
import SignUpPage from './containers/anonymous/SignUpPage';


// User
import OrderListPage from './containers/user/OrderListPage';
import OrderDetailPage from './containers/user/OrderDetailPage';
import UserProfilePage from './containers/user/UserProfilePage';
import UploadCertPage from './containers/user/UploadCertPage';
import CameraShotPage from './containers/user/CameraShotPage';
import SignaturePage from './containers/user/SignaturePage';
import UserCredentialPage from './containers/user/UserCredentialPage';

// Store
import VehiclesSpecPage from './containers/store/VehiclesSpecPage';
import PricingPage from './containers/store/PricingPage';
import DatePage from './containers/store/DatePage';
import DateTypePage from './containers/store/DateTypePage';
import ListingPage from './containers/store/ListingPage';
import StoreOrdersPage from './containers/store/OrdersPage';
import VehiclesPage from './containers/store/VehiclesPage';
import StoreProfilePage from './containers/store/StoreProfilePage';
import ReservePage from './containers/store/ReservePage';
import RentPage from './containers/store/RentPage';
import PickUpPage from './containers/store/PickUpPage';
import ReturnPage from './containers/store/ReturnPage';
import VehicleInvPage from './containers/store/VehicleInvPage';

// Admin
import StoresPage from './containers/admin/StoresPage';
import CompaniesPage from './containers/admin/CompaniesPage';
import AdminOrdersPage from './containers/admin/OrdersPage';
import AdminPage from './containers/admin/AdminPage';
import SalesReportPage from './containers/admin/SalesReportPage';
import BillsReportPage from './containers/admin/BillsReportPage';

import VerifyPage from './containers/anonymous/VerifyPage';
import axios from 'axios';
import PrivacyPage from './containers/anonymous/PrivacyPage';
import ErrorBoundary from './components/ErrorBoundary';

const liff = window.liff;

function App() {
  const [isReady, setIsReady] = useState(false);
  const [appLogined, setAppLogined] = useState(false);
  const [roles, setRoles] = useState([]);
  const [orderCart, dispatch] = useReducer(orderCartReducer, getOrderCartInitialContext());

  const navigate = useNavigate();
  const [searchParams,] = useSearchParams();

  const handleAppLogined = (value) => {
    setAppLogined(value);
  }
  const handleUpdateRole = (roles) => {
    setRoles(roles);
  }

  const loginWithLine = useCallback(async () => {
    if (!liff.isLoggedIn()) return;
    if (TourniiAPI.auth.getLoginUser()) return;
    console.log("login with line");
    await TourniiAPI.auth.verifyLineLogin().then((response) => {
      const idToken = liff.getDecodedIDToken();
      console.log("LIFF: idToken", idToken);
      console.log("LIFF: User email", idToken.email);
      console.log("LIFF: Get profile....");
      liff.getProfile().then((profile) => {
        console.log("LIFF: User Profile", profile);
      });

      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'));
      }
    });
  }, [searchParams, navigate]);

  const initCallback = useCallback(() => {
    // console.log("init");
    TourniiAPI.auth.init(handleAppLogined);
    PermissionManager.init(handleUpdateRole);
    // console.log("init callback");
    liff.ready.then(() => {
      // console.log("liff ready");
      // try login with token first, login with line second.
      // 若既有使用者要綁定 line 登入，則必須先以 token 登入，再進行 line 登入，否則會變成以 line 註冊新使用者
      TourniiAPI.auth.loginWithToken().finally(() => {
        loginWithLine().finally(() => {
          setIsReady(true);
        });
      });
    });

  }, [loginWithLine]);

  useEffect(() => {
    initCallback();
  }, [initCallback]);

  useEffect(() => {
    const id = axios.interceptors.response.use(
      (response) => TourniiAPI.responseFulfilledInterceptors(response, navigate),
      (error) => TourniiAPI.responseRejectInterceptors(error, navigate));
    return () => axios.interceptors.response.eject(id);
  }, [navigate]);

  // console.log(appLogined, roles);
  return (
    <ConfigProvider locale={zhTW}>
      <OrderCartContext.Provider value={initOrderCart(orderCart, dispatch)}>
        <div className="App">
          {!isReady ? <div style={{ padding: 20 }}><Skeleton active /></div> :
            <MainLayout>
              <ErrorBoundary>
                <Routes>
                  [
                  <Route key="anon-index" path="/" element={<SelectRegionPage />} />,
                  <Route key="anon-login" path="/login" element={<LoginPage />} />,
                  <Route key="anon-signup" path="/signup" element={<SignUpPage />} />,
                  <Route key="anon-forgetpasswd" path="/forgot-password" element={<ForgotPasswordPage />} />,
                  <Route key="anon-resetpasswd" path="/reset-password" element={<ResetPasswordPage />} />,
                  <Route key="anon-search" path="/search/" element={<SelectDateAndVehiclePage />} />,
                  <Route key="anon-search-region" path="/search/:regionId" element={<SelectDateAndVehiclePage />} />,
                  <Route key="anon-add-confirm" path="/add-to-cart/:vehicleSpecInvId" element={<AddToCartPage />} />,
                  <Route key="anon-cart" path="/cart" element={<CartListPage />} />,
                  <Route key="anon-privacy" path="/privacy" element={<PrivacyPage />} />,
                  ]
                  {appLogined && roles.indexOf(PermissionManager.ROLE_RENTER) >= 0 && [
                    <Route key="user-profile" path="/profile" element={<UserProfilePage />} />,
                    <Route key="user-upload-cert" path="/upload-cert" element={<UploadCertPage />} />,
                    <Route key="user-camera" path="/camera" element={<CameraShotPage />} />,
                    <Route key="user-orders" path="/orders" element={<OrderListPage />} />,
                    <Route key="user-orders-id" path="/orders/:orderId" element={<OrderDetailPage />} />,
                    <Route key="user-signature" path="/signature" element={<SignaturePage />} />,
                    <Route key="user-credential" path="/credential" element={<UserCredentialPage />} />,
                  ]}
                  {appLogined && (roles.indexOf(PermissionManager.ROLE_STORE) >= 0 || roles.indexOf(PermissionManager.ROLE_STORE_MEMBER) >= 0) && [
                    <Route key="store-reserve" path="/store/reserve/" element={<ReservePage />} />,
                    <Route key="store-rent" path="/store/rent/" element={<RentPage />} />,
                    <Route key="store-pickup" path="/store/pickup" element={<PickUpPage />} />,
                    <Route key="store-return" path="/store/return" element={<ReturnPage />} />,
                    <Route key="store-profile" path="/store/profile/" element={<StoreProfilePage />} />,
                    <Route key="store-vehicles" path="/store/vehicles" element={<VehiclesPage />} />,
                    <Route key="store-vehicle-specs" path="/store/vehicle-specs" element={<VehiclesSpecPage />} />,
                    <Route key="store-datetypes" path="/store/datetypes" element={<DateTypePage />} />,
                    <Route key="store-dates" path="/store/dates" element={<DatePage />} />,
                    <Route key="store-pricing" path="/store/pricing" element={<PricingPage />} />,
                    <Route key="store-listing" path="/store/listing" element={<ListingPage />} />,
                    <Route key="store-orders" path="/store/orders" element={<StoreOrdersPage />} />,
                    <Route key="store-orders-edit" path="/store/orders/:orderId" element={<RentPage />} />,
                    <Route key="store-inv" path="/store/inv" element={<VehicleInvPage />} />,
                  ]}
                  {appLogined && roles.indexOf(PermissionManager.ROLE_ADMIN) >= 0 && [
                    <Route key="admin-companies" path="/admin/companies" element={<CompaniesPage />} />,
                    <Route key="admin-stores" path="/admin/stores" element={<StoresPage />} />,
                    <Route key="admin-orders" path="/admin/orders" element={<AdminOrdersPage />} />,
                    <Route key="admin-report-sales" path="/admin/report/sales" element={<SalesReportPage />} />,
                    <Route key="admin-report-bills" path="/admin/report/bills" element={<BillsReportPage />} />,
                    <Route key="other-admin" path="/admin" element={<AdminPage />} />,
                  ]}
                  <Route path="/verify/*" element={<VerifyPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </ErrorBoundary>
            </MainLayout>
          }
        </div>
      </OrderCartContext.Provider>
    </ConfigProvider>
  );
}

export default App;
