import { Button, Form, Input, notification } from 'antd'
import React, { useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI'
import ContentLayout from '../ContentLayout'

function ForgotPasswordPage() {
    const [loading, setLoading] = useState(false);
    const handleFinish = (values) => {
        setLoading(true);
        TourniiAPI.auth.forgot(values).then((response) => {
            notification.info({ message: '已寄送確認信件到您的信箱' });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <ContentLayout>
            <h3>忘記密碼</h3>
            <p>輸入您的電子信箱來取得連結重設密碼</p>
            <Form onFinish={handleFinish}>
                <Form.Item name="email" label="電子信箱" required>
                    <Input />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={loading}>送出</Button>
                </Form.Item>
            </Form>
        </ContentLayout>
    )
}

export default ForgotPasswordPage