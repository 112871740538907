import { Button, DatePicker, Form, Select, Spin, Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import TourniiAPI from '../../api/TourniiAPI';
import ContentLayout from '../ContentLayout'
import moment from 'moment';
import { createObjectUrlFromResp } from '../../utils/download';

function SalesReportPage() {
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const [period, setPeriod] = useState([moment().date(1), moment().date(1).add(2, 'months')])
    const [source, setSource] = useState(null);

    const refresh = useCallback(() => {
        console.log("refresh");
        if (!period) return;
        setLoading(true);
        TourniiAPI.admin.reports.storeSales({
            startdate: period[0].format('YYYY-MM-DD'),
            enddate: period[1].format('YYYY-MM-DD'),
            source: source,
        }).then((response) => {
            const result = response.data;
            setDetail(result.stat);
        }).finally(() => {
            setLoading(false);
        })
    }, [period, source]);

    const exportFile = () => {
        TourniiAPI.admin.reports.storeSalesExport({
            startdate: period[0].format('YYYY-MM-DD'),
            enddate: period[1].format('YYYY-MM-DD'),
            source: source,
        }).then((response) => {
            createObjectUrlFromResp(response, `tournii-sales-${moment().format('YYYY-MM-DD')}.xlsx`);
        })
    }

    useEffect(() => {
        refresh();
    }, [refresh]);

    const columns = [
        {
            title: '日期',
            dataIndex: 'date',
        },
        {
            title: '銷售總額',
            dataIndex: 'totalamount',
            align: 'right',
            render: (value) => Number.parseInt(value).toLocaleString()
        },
        {
            title: '分潤金額',
            dataIndex: 'revenueshareamount',
            align: 'right',
            render: (value) => Number.parseInt(value).toLocaleString()
        },
    ]
    return (
        <ContentLayout title="銷售報表">
            <Spin spinning={loading}>
                <Form>
                    <Form.Item label="期間">
                        <DatePicker.RangePicker picker="month" value={period} onChange={(period) => setPeriod(period)} />
                    </Form.Item>
                    <Form.Item label="訂單來源">
                        <Select value={source} onChange={(source) => setSource(source)}>
                            <Select.Option value={null}>全部</Select.Option>
                            <Select.Option value="store">店家</Select.Option>
                            <Select.Option value="platform">平台</Select.Option>
                            <Select.Option value="external-kkday">外部-KKDay</Select.Option>
                            <Select.Option value="external-klook">外部-KLook</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => exportFile()}>匯出</Button>
                    </Form.Item>
                </Form>
                <Table columns={columns} dataSource={detail} rowKey="date" />
            </Spin>
        </ContentLayout>
    )
}

export default SalesReportPage